<template>
  <Modal v-model="sellingCountrySettingModal" :title="sellingCountrySettingTitle" :mask-closable="false"
    @on-visible-change="sellingCountrySettingChange" width="600">
    <Form :label-width="150" @submit.native.prevent>
      <Row type="flex">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <FormItem :label="$t('key1000301')" class="required_style_label">
            <vueTreeSelect
              style="width: 350px"
              :clearable="true"
              :searchable="true"
              :multiple="true"
              :noResultsText="$t('key1000088')"
              :noOptionsText="$t('key1000302')"
              :placeholder="$t('key1000087')"
              :normalizer="setNormalizer"
              :options="sellingCountrySettingList"
              v-model="countryCodes"
              :defaultExpandLevel="0"/>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="sellingCountrySettingModal=false">{{ $t('key1000098') }}</Button>
      <Button type="primary" :disabled="countryCodes.length <=0" @click="sellingCountrySettingBtn">{{ $t('key1000097') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {handleCountryContinentData} from "@/utils/common";
import vueTreeSelect from "@riophae/vue-treeselect";

export default {
  name: 'sellingCountrySettingModal',
  mixins: [Mixin],
  props: {
    sellingCountrySettingData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let v = this;
    return {
      sellingCountrySettingModal: false,
      sellingCountrySettingTitle: '',
      sellingCountrySettingList: [],
      countryCodes: [],
      ymsProductId: null,
      sellingCountrySettingType: null,
      setNormalizer(node) {
        return {
          id: node.twoCode,
          label: node.title,
          children: node.children
        };
      },
      continentCodeList: [],
    }
  },
  methods: {
    // 初始化数据
    initSellingCountrySettingData(type, ymsProductId) {
      let obj = {
        setting: alias47916751af154eb5b47cd5de9d34633d.t('key1000303'),
        edit: alias47916751af154eb5b47cd5de9d34633d.t('key1000303')
      }
      this.sellingCountrySettingType = type;
      this.sellingCountrySettingTitle = obj[type];
      this.ymsProductId = ymsProductId;
      if (this.sellingCountrySettingData.length > 0) {
        this.sellingCountrySettingList = handleCountryContinentData(this.sellingCountrySettingData);
      } else {
        this.getCountrysData('cn', true).then((data) => {
          this.sellingCountrySettingList = handleCountryContinentData(data);
        });
      }
      this.continentCodeList = this.sellingCountrySettingList.map((item) => {
        return item.twoCode;
      });
      if (type === 'edit') {
        this.axios.get(api.get_ymsProductSaleCountry_queryYmsProductSaleCountry + ymsProductId).then((response) => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let list = data.map((item) => item.countryCode);
              this.handleContinentData(list);
            }
          }
        });
      }
      this.sellingCountrySettingModal = true;
    },
    // 处理数据
    handleContinentData(list) {
      let newList = [];

      //接收选择的国家和国家设置
      function classifyCountries(selectedCountryCodes, sellingCountrySettingList) {
        // 生成国家映射
        const countriesMap = buildCountriesMap(sellingCountrySettingList);
        const classifiedCountries = {};

        selectedCountryCodes.forEach(countryCode => {
          const countryInfo = countriesMap[countryCode];
          if (countryInfo) {
            const continent = countryInfo.continent;
            // 如果是顶级国家
            if (!countryInfo.isChild) {
              classifiedCountries[continent] = classifiedCountries[continent] || [];
              classifiedCountries[continent].push(countryCode);
            }
            // 如果是子项，找到它的父级国家
            else {
              for (const item of Object.values(countriesMap)) {
                if (item.children && item.children.includes(countryCode)) {
                  classifiedCountries[item.continent] = classifiedCountries[item.continent] || [];
                  classifiedCountries[item.continent].push(countryCode);
                  break; // 找到后退出循环
                }
              }
            }
          }
        });

        return classifiedCountries; // 返回分类的结果
      }

      // 生成国家地图
      function buildCountriesMap(countries) {
        const map = {};
        countries.forEach(country => {
          map[country.twoCode] = {
            children: country.children ? country.children.map(child => child.twoCode) : [],
            continent: country.continent, // 假设每个国家都有 continent 属性
            isChild: false
          };
          // 添加子项
          (country.children || []).forEach(child => {
            map[child.twoCode] = {
              isChild: true,
              continent: country.continent
            };
          });
        });
        return map;
      }

      const classifiedResults = classifyCountries(list, this.sellingCountrySettingList);
      if (Object.keys(classifiedResults).length > 0) {
        for (let key in classifiedResults) {
          this.sellingCountrySettingList.map((item) => {
            if (item.twoCode === key) {
              if (item.children.length === classifiedResults[key].length) {
                newList.push(item.twoCode);
              } else {
                newList.push(...classifiedResults[key]);
              }
            }
          });
        }
        this.countryCodes = this.uniqueFunc(newList);
      } else {
        this.countryCodes = list;
      }
    },
    // 确定
    sellingCountrySettingBtn() {
      let countryCodes = [];
      this.countryCodes.map((value) => {
        this.sellingCountrySettingList.map((ele) => {
          if (value === ele.twoCode) {
            let list = ele.children.map((k) => {
              return k.twoCode;
            });
            countryCodes.push(...list);
          } else {
            if (!this.continentCodeList.includes(value)) {
              countryCodes.push(value);
            }
          }
        })
      });
      let countryCodesList = this.uniqueFunc(countryCodes);
      let query = {
        countryCodes: countryCodesList,
        ymsProductIds: Array.isArray(this.ymsProductId) ? this.ymsProductId : [this.ymsProductId]
      }
      this.axios.post(api.post_ymsProductSaleCountry_batchSetSaleCountry, query,
        {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
        if (response.data.code === 0) {
          this.sellingCountrySettingModal = false;
          if (this.sellingCountrySettingType !== 'edit') {
            this.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000304'));
          } else {
            this.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000305'));
          }
          this.$emit('updateData', true);
        }
      });
    },
    // 监听弹窗
    sellingCountrySettingChange(value) {
      if (!value) {
        this.countryCodes = [];
        this.ymsProductId = null;
        this.sellingCountrySettingList = [];
      }
    }
  },
  components: {
    vueTreeSelect
  }
};
</script>

<style lang="less">

</style>
