<template>
  <div>
    <Modal v-model="uploadNetworkImagesModal" width="550" :title="$t('key1000985')" @on-visible-change="uploadNetworkImagesChange">
      <Form ref="uploadNetworkImagesForm" :model="uploadNetworkImagesForm" :rules="ruleValidate" :label-width="90" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem" style="width: 90%;">
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000986')" prop="uploadAddress">
              <Input v-model="uploadNetworkImagesForm.uploadAddress" clearable placeholder="https://"></Input>
            </Form-item>
          </Col>
        </Row>
      </Form>
      <template #footer>
        <Button @click="uploadNetworkImagesModal = false">{{ $t('key1000098') }}</Button>
        <Button type="primary" :disabled="!uploadNetworkImagesForm.uploadAddress" @click="uploadNetworkImagesBtn">{{ $t('key1000097') }}</Button>
      </template>
    </Modal>
    <!--裁剪图片的弹窗-->
    <kjnovaClipperModal :multiple="false" ref="kjnovaClipper" @updateUploadImg="updateUploadImg"></kjnovaClipperModal>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import kjnovaClipperModal from "@/components/common/kjnovaClipperModal.vue";
import {handleUploadImageAddress} from "@/utils/common";

export default {
  name: 'uploadNetworkImagesModal',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      uploadNetworkImagesModal: false,
      uploadNetworkImagesForm: {
        uploadAddress: ''
      },
      ruleValidate: {
        uploadAddress: [
          {required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1000987'), trigger: 'blur'},
          {pattern: v.$regular.linkAddress, message: alias47916751af154eb5b47cd5de9d34633d.t('key1000988'), trigger: 'blur'},
        ],
      }
    };
  },
  methods: {
    // 确定
    uploadNetworkImagesBtn() {
      let v = this;
      let url = v.systemTalg ? '?basePath=yms-supplier-service' : '?basePath=yms-core-service';
      let query = [v.uploadNetworkImagesForm.uploadAddress];
      v.$refs['uploadNetworkImagesForm'].validate((valid) => {
        if (valid) {
          v.axios.post(api.post_uploadNetworkFiles + url, query, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas || [];
              if (data.length > 0) {
                let tempUrl = data[0];
                let link = handleUploadImageAddress(tempUrl)
                v.$refs['kjnovaClipper'].initKjnovaClipperData(link);
              }
              v.uploadNetworkImagesModal = false;
            } else {
              v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000989'));
              return false;
            }
          });
        }
      });
    },
    // 监听弹窗
    uploadNetworkImagesChange(value) {
      if (!value) {
        this.uploadNetworkImagesForm.uploadAddress = '';
      }
    },
    // 裁剪图片
    updateUploadImg(data) {
      this.$emit('updateUploadImg', data);
    }
  },
  components: {
    kjnovaClipperModal
  },
};
</script>

<style scoped>

</style>
