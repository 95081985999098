<template>
  <div class="container-box">
    <Form @submit.native.prevent ref="productInfo" :model="productInfo" :rules="rules" :label-width="160"
      :disabled="operationType === 'look'" label-position="right">
      <!--基本信息-->
      <Card :bordered="false" :title="$t('key1000645')" dis-hover class="title_styles" v-viewer="viewerOptions">
        <FormItem :label="$t('key1000646')" prop="productType" class="radio_styles">
          <RadioGroup v-model="productInfo.productType">
            <Radio :disabled="!systemTalg || operationType === 'look'" :label="1">{{ $t('key1000669') }}</Radio>
            <Radio :disabled="!systemTalg || operationType === 'look'" :label="3">{{ $t('key1000670') }}</Radio>
            <Radio :disabled="!systemTalg || operationType === 'look'" :label="2">{{ $t('key1000671') }}</Radio>
            <Radio v-if="(merchantConfigurationFactory && systemTalg) || !systemTalg"
              :disabled="!systemTalg || operationType === 'look'" :label="4">POD</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem prop="ymsProductCategoryId" :label="$t('key1000647')">
          <Button @click="openChoseCategory" v-if="!(choseCategoryObj && choseCategoryObj.productCategoryNavigation)">{{ $t('key1000672') }}</Button>
          <p v-else>
            {{ choseCategoryObj.productCategoryNavigation }}
            <span class="edit-category" @click="openChoseCategory('modify')" v-if="operationType !== 'look' && systemTalg">{{ $t('key1000673') }}</span>
          </p>
          <p class="tip-p">{{ $t('key1000674') }}</p>
          <input type="hidden" v-model="productInfo.ymsProductCategoryId"/>
        </FormItem>
        <FormItem prop="spu" :label="$t('key1000648')">
          <Input v-model.trim="productInfo.spu" :disabled="!systemTalg" :placeholder="$t('key1000649')" class="ipt" :maxlength="200" clearable/>
          <p class="tip-p">{{ $t('key1000675') }}</p>
        </FormItem>
        <FormItem :label="configCharacters + 'SPU：'" v-if="operationType !== 'add' && productInfo.ymsSpu">
          <span>{{ productInfo.ymsSpu }}</span>
        </FormItem>
        <FormItem prop="ingredientsId" :label="$t('key1000650')" v-if="productInfo.productType === 4 && systemTalg">
          <Button @click="openChoseIngredients('spu')" v-if="!(choseIngredientObj && choseIngredientObj.cnName) && systemTalg">{{ $t('key1000676') }}</Button>
          <p v-else>
            {{ choseIngredientObj.cnName }}
            <span class="edit-category" @click="openChoseIngredients('spu')" v-if="operationType !== 'look' && systemTalg">{{ $t('key1000677') }}</span>
          </p>
          <input type="hidden" v-model="productInfo.ingredientsId"/>
        </FormItem>
        <FormItem prop="productImages" :label="$t('key1000651')">
          <draggable
            v-model="productInfo.productImages"
            chosenClass="chosen"
            forceFallback="true"
            class="product-upload-img-list"
            group="img"
            :animation="drag.option.animation"
            :draggable="operationType === 'look' || !systemTalg ? '.xxxxxxx' : '.product-upload-img-item'"
            @start="startDragImg($event, { specValueIndex: null, specIndex: null })"
            @end="endDragImg({ specValueIndex: null, specIndex: null },'carouselChart')">
            <div
              class="product-upload-img-item"
              :class="{'no-move': operationType === 'look' || !systemTalg}"
              v-for="(path, index) in productInfo.productImages"
              :key="index">
              <img
                v-if="drag.isMove"
                class="img-styles"
                :src="setImgPath(path, null, null, null, false)"
                @error="setErrorImg($event)"
                alt=""/>
              <img
                v-else
                class="img-styles"
                :src="setImgPath(path, null, null, null, false)"
                @error="setErrorImg($event)"
                alt=""/>
              <Icon
                type="ios-close-circle"
                class="del-icon"
                :data-obj="JSON.stringify({ index: index, type: 'delProduct' })"
                v-if="operationType !== 'look' && systemTalg"
                @click.stop="delProductImages({ index: index })"/>
              <span class="first-img" v-if="index === 0">{{ $t('key1000678') }}</span>
            </div>
            <Upload
              v-if="operationType !== 'look' && systemTalg"
              name="files"
              :headers="headObj"
              :show-upload-list="false"
              :on-success="uploadFilesProductImgSuccess"
              :on-error="uploadFilesError"
              :before-upload="(file) => beforeHandleUpload(file, 'carouselChart')"
              multiple
              :action="uploadFilesUrl"
              type="drag"
              style="display: inline-block; width: 100px">
              <div class="upload-box">
                <i class="iconfont upload-icon">&#xe68d;</i>
                <p>{{ $t('key1000402') }}</p>
              </div>
            </Upload>
            <span class="upload-url-img" @click="$refs.uploadNetworkImages.uploadNetworkImagesModal = true"
              v-if="operationType !== 'look' && systemTalg">{{ $t('key1000679') }}</span>
          </draggable>
          <p class="tip-p mti10">
            <span style="color: #ff7700">{{ $t('key1000680') }}</span>
            <span class="attr-example" @click="$refs.productDetailUploadStandard.open()">{{ $t('key1000681') }}</span>
          </p>
          <p class="tip-p">{{ $t('key1000682') }}</p>
          <p class="tip-p">1. {{ $t('key1000683') }}</p>
          <p class="tip-p">2. {{ $t('key1000417') }}</p>
          <p class="tip-p">3. {{ $t('key1000418') }}</p>
          <p class="tip-p">4. {{ $t('key1000684') }}</p>
          <input type="hidden" v-model="productInfo.productImages"/>
        </FormItem>
        <FormItem :label="$t('key1000652')" v-if="productInfo.productType === 3 && systemTalg && operationType !== 'look'">
          <span class="cursor themeColor" @click="$refs['batchEditAssemblyInfo'].initBatchEditAssemblyInfoData()">{{ $t('key1000685') }}</span>
        </FormItem>
        <FormItem
          :label="$t('key1000653')"
          class="image-type"
          :class="resetRequiredTalg ? 'resetRequiredStyles' : ''"
          prop="imageTypeList">
          <CheckboxGroup v-model="productInfo.imageTypeList">
            <Checkbox :label="0">{{ $t('key1000686') }}</Checkbox>
            <Checkbox :label="1">{{ $t('key1000687') }}</Checkbox>
            <Checkbox :label="2">{{ $t('key1000688') }}</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <languagesTabs ref="languagesTab" :tabsList="multilingualNamesList">
          <template v-for="item in multilingualNamesList">
            <template :slot="item.name">
              <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
                <Form-item :label="labelRander(item)" :label-width="140">
                  <div class="flex align-items-center">
                    <Input
                      v-model.trim="productInfo['name_' + item.code]"
                      style="width: 80%"
                      :placeholder="$t('key1000654')"
                      :maxlength="200"
                      clearable/>
                    <Checkbox
                      class="ml20"
                      v-if="item.code !== 'CN'"
                      v-model="automaticallyTitle"
                      @on-change="changeAutomaticallyTitle">
                      {{ $t('key1000689') }}
                    </Checkbox>
                  </div>
                </Form-item>
              </Col>
            </template>
          </template>
        </languagesTabs>
      </Card>
      <!--商品属性-->
      <Card :bordered="false" :title="$t('key1000655')" dis-hover class="mt20 title_styles">
        <!--Listing商品属性信息-->
        <div class="mb20 listingProductAttribute_box" v-if="productInfo.source === 'listing' && listingProductAttributeList.length > 0">
          <h2 class="mb10 font-weight-bold font-size-15">{{ $t('key1000690') }}</h2>
          <Row>
            <Col span="6" v-for="item in listingProductAttributeList" class="flex flex-wrap mb12">
              <span>{{ item.platformAttributeName + "：" }}</span>
              <span>{{ item.values.join("、") }}</span>
            </Col>
          </Row>
        </div>
        <div class="attr-box" v-if=" ymsCategoryProductAttributeList && ymsCategoryProductAttributeList.length">
          <Row>
            <template v-for="(attr, attrIndex) in ymsCategoryProductAttributeList">
              <Col :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-if="!attr._hide">
                <FormItem :label="attr.ymsProductAttributeTemplate.cnName" :class="{'ivu-form-item-error':!attr.attrRequireValid && !resetRequiredTalg}">
                  <template #label>
                    <label :class="{'red-dot':attr.ymsProductAttributeTemplate.attributeRequire === 0 && !resetRequiredTalg}">
                      {{ attr.ymsProductAttributeTemplate.cnName }}</label>
                  </template>
                  <!--多选自定义-->
                  <!--attributeRequire 属性填写要求，0：必填，1：非必填，2：推荐填写 默认 0-->
                  <!--type 属性值类型(0:自定义 1:可选值 默认 0)-->
                  <!--chooseType 可选值类型(0:多选 1:单选 默认 0)-->
                  <!--isAllowCustomValue 是否允许自定义属性值(0:不允许 1:允许) 注意 String-->
                  <!--可选值-->
                  <template v-if="attr.ymsProductAttributeTemplate.type === 1">
                    <!--多选-->
                    <Select
                      v-if="attr.ymsProductAttributeTemplate.chooseType === 0"
                      v-model="attr.propertyValues"
                      class="ipt"
                      filterable
                      :placeholder="attrPlaceholder(attr.ymsProductAttributeTemplate)"
                      :allow-create="attr.ymsProductAttributeTemplate.isAllowCustomValue === '1'"
                      @on-create="allowCreateAttrHandel($event, attrIndex)"
                      multiple
                      :ref="'attrSelect' + attrIndex"
                      @mousedown.native="onSelectClickErrorHandel('attrSelect' + attrIndex)"
                      @on-change="productAttributeChange($event, attrIndex)">
                      <Option v-for="item in attr.ymsProductAttributeTemplate.productAttributeDictionaryBos" :value="item.ymsProductAttributeDictionaryId">{{ item.cnValue }}</Option>
                    </Select>
                    <!--单选-->
                    <Select
                      v-else
                      v-model="attr.propertyValues"
                      class="ipt"
                      :ref="'attrSelect' + attrIndex"
                      @mousedown.native="onSelectClickErrorHandel('attrSelect' + attrIndex)"
                      :placeholder="attrPlaceholder(attr.ymsProductAttributeTemplate)"
                      filterable
                      :allow-create="attr.ymsProductAttributeTemplate.isAllowCustomValue === '1'"
                      @on-change="productAttributeChange($event, attrIndex)">
                      <Option v-for="item in attr.ymsProductAttributeTemplate.productAttributeDictionaryBos" :value="item.ymsProductAttributeDictionaryId">{{ item.cnValue }}</Option>
                    </Select>
                  </template>
                  <!--自定义-->
                  <template v-else>
                    <Input class="ipt" :placeholder="$t('key1000656')"
                      @on-change="productAttributeChange(attr.propertyValues, attrIndex, attr)" v-model="attr.propertyValues">
                      <Select slot="append" style="width: 90px" v-model="attr.unit" class="ipt">
                        <Option v-for="item in attr.ymsProductAttributeTemplate.productAttributeDictionaryBos"
                          :value="item.ymsProductAttributeDictionaryId">{{ item.cnValue }}</Option>
                      </Select>
                    </Input>
                  </template>
                  <div class="ivu-form-item-error-tip" v-if="attr.propertyValues && !isNumberOrDecimal(attr.propertyValues)
                    && attr.ymsProductAttributeTemplate.type === 0">
                    {{ $t('key1000656') }}
                  </div>
                  <div class="ivu-form-item-error-tip" v-if="!attr.attrRequireValid && !resetRequiredTalg">
                    {{ attr.ymsProductAttributeTemplate.cnName }}{{ $t('key1000569') }}
                  </div>
                  <p v-if="attr.disableText" class="redColor">
                    {{ attr.disableText }}
                  </p>
                </FormItem>
              </Col>
            </template>
          </Row>
          <div class="flex justify-content-center" v-if="ymsCategoryProductAttributeList.length > 15">
            <p class="spec-show-more" v-if="isShowAttrMore" @click="visibleAttribute(true)">
              <span>{{ $t('key1000691') }}</span>
              <Icon class="ml3" size="19" type="ios-arrow-down"/>
            </p>
            <p class="spec-show-more" v-else-if="isShowAttrMore === false" @click="visibleAttribute(false)">
              <span>{{ $t('key1000116') }}</span>
              <Icon class="ml3" size="19" type="ios-arrow-up"/>
            </p>
          </div>
          <!--以下属性需填写属性值比例-->
          <div class="proportion_box" v-if="proportionTalg">
            <h2 class="mt15 mb8 font-weight-bold font-size-13">
              {{ $t('key1000692') }}
            </h2>
            <template v-for="item in proportionList">
              <div class="proportion_box_item" v-if="item.proportionValueList.length >= 2">
                <span class="font-weight-bold">{{ item.title + "：" }}</span>
                <div class="flex align-items-center" v-for="ele in item.proportionValueList">
                  <span class="mr6">{{ ele.name }}</span>
                  <Input v-model="ele.value" class="width_110 mr35">
                    <span slot="append">%</span>
                  </Input>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="pi-placeholder" v-else>{{ $t('key1000693') }}</div>
        <p class="tip-p ml20" style="margin-top: 20px">
          {{ $t('key1000694') }}
          <span class="attr-example" @click="model = true">{{ $t('key1000662') }}</span>
        </p>
      </Card>
      <!--价格库存-->
      <Card :bordered="false" :title="$t('key1000657')" dis-hover class="mt20 title_styles">
        <div class="price-inventory-box">
          <!--商品规格-->
          <div class="pi-box">
            <div class="pi-title flex-between">
              <div>
                <span class="font-weight-bold">{{ $t('key1000695') }}</span>
                <span class="ml10">{{ $t('key1000696') }}</span>
              </div>
              <div>
                <span v-if="operationType === 'look' || !systemTalg" class="cursor-disabled">{{ $t('key1000697') }}</span>
                <span class="cursor-primary" v-else @click="openAttrSort">{{ $t('key1000697') }}</span>
              </div>
            </div>
            <template v-if="specification && specification.length">
              <FormItem v-for="(specItem, specIndex) in specification" :label="specItem.label"
                :class="{'ivu-form-item-error': specItem.specRequire && !specItem.specRequireValid}" :label-width="140">
                <template #label>
                  <label :class="{'red-dot': specItem.specRequire}">{{ specItem.label }}</label>
                </template>
                <Row>
                  <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol"
                    v-for="(specValueItem, specValueIndex) in specItem.valueList">
                    <div class="color-item" :class="specValueItem.disableText ? '' : 'mb10'">
                      <Select
                        class="select"
                        v-model="specValueItem.value"
                        transfer
                        filterable
                        @on-change="changeProductSpecAttr(specIndex,specValueItem.value, specValueIndex,'replace')"
                        @keydown.native.enter="changeHandleEnterKey(specIndex,specValueItem.value,specValueIndex,'replace',specItem)"
                        :disabled="!systemTalg || specValueItem.disabled">
                        <template v-for="item in uniqueFunc([{ value: specValueItem.value, _show: true },...specItem.list ],'value')">
                          <Option v-if="item._show" :value="item.value" :disabled="item.disabled">{{ item.value }}</Option>
                        </template>
                      </Select>
                      <span class="del" @click="delSpec(specIndex,specValueIndex,specValueItem.value)" v-if="operationType !== 'look' && systemTalg">{{ $t('key1000092') }}</span>
                    </div>
                    <p v-if="specValueItem.disableText" class="redColor">{{ specValueItem.disableText }}</p>
                    <draggable
                      v-if="specItem.isMainAttribute"
                      v-model="specValueItem.imgList"
                      chosenClass="chosen"
                      forceFallback="true"
                      class="product-color-img-list"
                      :group="(specValueItem.imgList && specValueItem.imgList.length < 5) || specValueItem.isMove  ? 'img': 'img-no' "
                      :animation="drag.option.animation"
                      :draggable=" operationType === 'look' || !systemTalg ? '.xxxxxxx': '.product-color-img-item'"
                      @start="startDragImg($event, {specValueIndex: specValueIndex,specIndex: specIndex}) "
                      @end="endDragImg({specValueIndex: specValueIndex, specIndex: specIndex},'specificationImage')">
                      <div class="product-color-img-item" :class="{'no-move': operationType === 'look' || !systemTalg}"
                        v-for="(path, index) in specValueItem.imgList" :key="index" @click.stop.prevent>
                        <img v-if="drag.isMove" class="img-styles" :src="setImgPath(path)" @error="setErrorImg($event)" alt=""/>
                        <Tooltip v-else placement="right" theme="light" :transfer="true">
                          <img
                            class="img-styles"
                            :src="setImgPath(path, null, null, null, false)"
                            @error="setErrorImg($event)"
                            alt=""/>
                          <template #content>
                            <img
                              :ref="'thumbnailImg2_' + index"
                              @load="handlerOnloadImg('thumbnailImg2_' + index)"
                              :src="setImgPath(path, null, null, null, false)"
                              @error="setErrorImg($event)"
                              alt=""
                            />
                          </template>
                        </Tooltip>
                        <div class="delete_specifications_img" :data-obj="JSON.stringify({specIndex: specIndex,specValueIndex: specValueIndex,index: index,type: 'delSpec'})"
                          v-if="operationType !== 'look' && systemTalg"
                          @click.stop="delSpecImage({specIndex: specIndex,specValueIndex: specValueIndex,index: index})">
                          {{ $t('key1000092') }}
                        </div>
                      </div>
                      <Upload
                        name="files"
                        :ref="'spUpload' + specValueIndex"
                        v-if=" operationType !== 'look' && specValueItem.imgList.length < 5 && systemTalg"
                        :headers="headObj"
                        :show-upload-list="false"
                        :on-success="(arg) => uploadSpecImg(specIndex, specValueIndex, arg)"
                        :on-error="uploadFilesError"
                        :before-upload="(file) =>beforeHandleUpload(file, 'specificationImage')"
                        multiple
                        :action="uploadFilesUrl"
                        type="drag"
                        style="display: inline-block; width: 50px">
                        <div class="upload-color-box">
                          <i class="iconfont font-size-26">&#xe68d;</i>
                        </div>
                      </Upload>
                    </draggable>
                  </Col>
                  <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-if="operationType !== 'look' && systemTalg">
                    <Select
                      transfer
                      filterable
                      v-model="valueIsNull"
                      :allow-create="specItem.isAllowCustomValue"
                      :placeholder="specItem.isAllowCustomValue? $t('key1000643'): $t('key1000644')"
                      @keydown.native.enter="selectHandleEnterKey($event, specIndex, specItem)"
                      @on-create="allowCreateSpecHandel($event, specIndex)">
                      <template v-for="item in specItem.list">
                        <Option
                          v-if="item._show"
                          :value="item.value"
                          :disabled="item.disabled"
                          :key="item.ymsProductAttributeDictionaryId"
                          @click.native=" addSpecToList(specIndex,item,specItem.isMainAttribute)">
                          {{ item.value }}
                        </Option>
                      </template>
                    </Select>
                  </Col>
                </Row>
                <div class="ivu-form-item-error-tip" v-if="specItem.specRequire && !specItem.specRequireValid">
                  {{ specItem.label }}{{ $t('key1000569') }}{{ specItem.isMainAttribute ? $t('key1000664') : "" }}
                </div>
              </FormItem>
            </template>
            <div class="pi-placeholder" v-else>{{ $t('key1000693') }}</div>
          </div>
          <!--规格明细-->
          <div class="pi-box">
            <div class="pi-title flex align-items-center justify-content-between">
              <span class="font-weight-bold">{{ $t('key1000698') }}</span>
              <Button v-if="productDataSpan && productDataSpan.length && resetRequiredTalg" class="mb10" @click="customColumnsBtn">{{ $t('key1000699') }}</Button>
            </div>
            <ve-table
              v-if="productDataSpan && productDataSpan.length"
              ref="skuTableDom"
              class="sku_table"
              :max-height="400"
              :borderY="true"
              :virtualScrollOption="virtualScrollOption"
              :cellSelectionOption="{ enable: false }"
              :rowStyleOption="{ clickHighlight: false }"
              :columns="productColumns"
              :table-data="productDataSpan"
              :cell-span-option="cellSpanOption"
              rowKeyFieldName="rowKey"
              :scroll-width="1600"
            />
            <!--            <Table
              border
              class="sku_table"
              v-if="productDataSpan && productDataSpan.length"
              max-height="400"
              :span-method="tableSpanProductGood"
              :columns="productColumns"
              :data="productDataSpan">
              <template #isAssemble="{index}">
                <i-switch
                  :disabled="isDisabled || productInfo.productType !== 3"
                  v-model="productInfo.productGoodsList[index].isAssemble"
                  :true-value="1"
                  :false-value="0"
                />
              </template>
              <template #assembleInfo="{index}">
                <div v-if="productInfo.productGoodsList[index].isAssemble === 1" class="flex align-items-center">
                  <Icon type="md-checkmark" v-if="(productInfo.productGoodsList[index].productGoodsAssembles &&
                  productInfo.productGoodsList[index].productGoodsAssembles.length > 0) || assemblyInfoTalg"/>
                  <Icon type="md-close" v-else/>
                  <Button :disabled="productInfo.productType !== 3" type="text" class="themeColor ml5 resetBtn font-size-14"
                    @click="showAddProductModal(productInfo.productGoodsList[index], index)">编辑</Button>
                </div>
                <template v-else>-</template>
              </template>
            </Table>
                        <div class="pi-placeholder" v-else>请先添加商品规格</div>-->
          </div>
          <!--尺码表-->
          <div class="pi-box" v-if="showSizeChart">
            <div class="pi-title font-weight-bold">{{ $t('key1000700') }}</div>
            <Table
              v-if="sizeChartData && sizeChartData.length"
              class="sizeChartTable"
              max-height="400"
              border
              :columns="sizeChartColumns"
              :data="sizeChartData">
            </Table>
            <div class="pi-placeholder" v-else>{{ $t('key1000701') }}</div>
          </div>
        </div>
      </Card>
      <!--服务与承诺-->
      <Card :bordered="false" :title="$t('key1000658')" dis-hover class="mt20 title_styles">
        <div class="attr-box">
          <Row>
            <Col :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1000659')">
                <Select v-model="productInfo.productService" style="width: 260px" :disabled="!systemTalg" filterable transfer>
                  <Option v-for="(item, index) in productServiceList" :value="item.value" :key="index">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1000660')">
                <Select v-model="productInfo.deliveryInterval" filterable style="width: 260px" :disabled="!systemTalg">
                  <Option v-for="(item, index) in deliveryIntervalList" :value="item.value" :key="index">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </div>
      </Card>
      <!--商品详情-->
      <Card :bordered="false" :title="$t('key1000661')" dis-hover class="mt20 title_styles">
        <Input type="textarea" v-model="productInfo.description" :rows="10" :maxlength="1000"/>
      </Card>
      <!--云卖供货价格-->
      <Card :bordered="false" class="mt20 title_styles">
        <template #title>
          <span>{{ configCharacters + $t('key1000665') }}</span>
          <Tooltip max-width="200" class="ml5">
            <Icon size="18" type="md-help-circle"/>
            <template #content>
              {{
                $t('key1000666') +
                configCharacters +
                $t('key1000667')
              }}
            </template>
          </Tooltip>
          <RadioGroup v-model="discountType" @on-change="discountChange" class="ml30">
            <Radio label="0" :disabled="!systemTalg">
              <Icon type="social-apple"></Icon>
              <span>{{ $t('key1000702') }}</span>
            </Radio>
            <Radio label="1" :disabled="!systemTalg">
              <Icon type="social-android"></Icon>
              <span>{{ $t('key1000703') }}</span>
            </Radio>
          </RadioGroup>
        </template>
        <Table
          max-height="400"
          border
          class="discount-table"
          :columns="discountColumns"
          :data="discountData">
        </Table>
        <span class="add-discount" @click="addDiscount" v-if="!isDisabled && discountData.length < 3">{{ $t('key1000704') }}</span>
      </Card>
    </Form>
    <!--底部按钮操作栏-->
    <div class="footer-btn flex align-items-center">
      <Checkbox v-if="productInfo.shelveStatus === 2 && pageType === 'supplier'" :true-value="0"
        :false-value="1" v-model="auditIsShelve" class="mr30">{{ $t('key1000705') }}
      </Checkbox>
      <Button size="large" @click="pageCancel">{{ $t('key1000098') }}</Button>
      <Button type="primary" v-if="operationType !== 'look' &&getPermission('productInfo_update') &&
      systemTalg" class="ml30" size="large" @click="save(0)">{{ $t('key1000495') }}</Button>
      <Button type="primary" v-if="operationType !== 'look' && getPermission('productInfo_update')" class="ml30"
        size="large" @click="save(1)">{{ systemTalg ? $t('key1000668') : $t('key1000462') }}</Button>
      <Button class="ml30" size="large" type="primary" v-if="getPermission('productInfo_update') && operationType === 'look'"
        @click="editProduct()">{{ $t('key1000685') }}</Button>
      <Button v-if="ymsProductInfoTemplateId &&getPermission('ymsProductInfoTemplate_approved')" class="ml30"
        size="large" type="primary" @click="approvedByReviewBtn">{{ $t('key1000706') }}</Button>
      <Button v-if="ymsProductInfoTemplateId && getPermission('ymsProductInfoTemplate_batchRejected')"
        @click="$refs['reasonForRejection'].initReasonForRejectionData([ymsProductInfoTemplateId])"
        class="ml30" size="large">{{ $t('key1000707') }}</Button>
    </div>
    <!--修改类目弹窗-->
    <productCategory ref="productCategory" :categoryList="categoryList" @saveCategory="emitSaveCategory"></productCategory>
    <!--上传网络图片弹窗-->
    <uploadNetworkImagesModal ref="uploadNetworkImages" @updateUploadImg="updateUploadImg"></uploadNetworkImagesModal>
    <!--查看示例弹窗-->
    <Modal v-model="model" width="1000" :title="$t('key1000662')">
      <img :src="exampleSrc" style="width: 100%" alt=""/>
      <template #footer>
        <Button type="primary" @click="model = false">{{ $t('key1000642') }}</Button>
      </template>
    </Modal>
    <!--驳回弹窗-->
    <reasonForRejectionModal ref="reasonForRejection"></reasonForRejectionModal>
    <!--图片上传规范弹窗-->
    <productDetailUploadStandard ref="productDetailUploadStandard"></productDetailUploadStandard>
    <attrSort ref="attrSortRef" :specification="specification" @success="attrSortSave"></attrSort>
    <!--组装信息-->
    <addCommonProductModal
      ref="addCommonProduct"
      modalWidth="1000"
      :title="$t('key1000663')"
      :productTypes="[1, 2]"
      :showTree="false"
      :apiUrl="apiUrl"
      :showSelectSku="true"
      showQuantity
      goodsInfoKey="reset"
      :showMainMaterials="systemTalg"
      :ymsNoTable="!systemTalg"
      :supplierShowFieldKeyList="['selection', 'goodsInfo', 'availableNumber']"
      systemType="supplier"
      @updateGoodsData="updateData">
    </addCommonProductModal>
    <!--批量组装信息-->
    <batchEditAssemblyInfoModal ref="batchEditAssemblyInfo" @updateBatchEditAssemblyInfo="updateBatchEditAssemblyInfo"></batchEditAssemblyInfoModal>
    <!--自定义列组件-->
    <customColumnsDrawer
      ref="customColumns"
      :customSorting="true"
      :isDelay="true"
      customColumnsKey="productDetailsColumns"
      :originalColumns="originalColumns"
      :fixedColumnkeyList="fixedColumnkeyList"
      :defaultSelectKeyList="defaultSelectKeyList"
      @customColumnsData="customColumnsData">
    </customColumnsDrawer>
    <!--裁剪图片的弹窗-->
    <kjnovaClipperModal
      :productType="productInfo.productType"
      :multiple="kjnovaClipperMultiple"
      ref="kjnovaClipper"
      @updateUploadImg="updateUploadImg">
    </kjnovaClipperModal>
    <!--添加多张图片弹窗-->
    <addMultipleImagesModal :productType="productInfo.productType" ref="addMultipleImages" @updateUploadImgList="updateUploadImgList"></addMultipleImagesModal>
    <!--选择主料-->
    <selectIngredientsModal
      ref="selectIngredients"
      :selectType="selectType"
      @bindingAuthorization="bindingAuthorization"
      @selectedIngredient="selectedIngredient">
    </selectIngredientsModal>
    <!--选择部位&素材-->
    <selectPartAndSourceModal ref="selectPartAndSource" :operationType="operationType" @selectedPartAndMeat="selectedPartAndMeat"></selectPartAndSourceModal>
    <!--商品售卖国弹窗-->
    <sellingCountrySettingModal ref="sellingCountrySetting" @updateData="updateDataFun"></sellingCountrySettingModal>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import productCategory from "@/components/common/productCategory";
import selectIngredientsModal from "@/components/common/selectIngredients";
import selectPartAndSourceModal from "@/components/common/partAndMeatSetting";
import api from "@/api/";
import productMixin from "@/components/mixin/product_mixin";
import draggable from "vuedraggable";
import uploadNetworkImagesModal from "@/components/common/uploadNetworkImagesModal.vue";
import productDetailUploadStandard from "@/components/common/productDetailUploadStandard";
import attrSort from "@/components/common/attrSort";
import FileType from "file-type";
import languagesTabs from "@/components/common/languagesTabs.vue";
import {
  amountHandel,
  commonSessionStorage,
  formatterNumber, getCommonParamKeysData, getMerchantConfigurationFactory,
  getYmsSiteData,
  handerDataSorting,
  isJSON,
  isStringValueEmpty,
  isValueEmpty
} from "@/utils/common";
import addCommonProductModal from "@/components/common/addCommonProductModal.vue";
import batchEditAssemblyInfoModal from "@/components/common/batchEditAssemblyInfoModal.vue";
import reasonForRejectionModal from "@/components/common/reasonForRejectionModal.vue";
import customColumnsDrawer from "@/components/common/customColumnsDrawer";
import {endLoading, startLoading} from "@/utils/loading";
import kjnovaClipperModal from "@/components/common/kjnovaClipperModal.vue";
import addMultipleImagesModal from "@/components/common/addMultipleImagesModal.vue";
import sellingCountrySettingModal from "@/components/common/sellingCountrySettingModal.vue";

// 滚动到右侧
function _setSizeTableScrollLeft() {
  let dom = document.querySelector(".sizeChartTable .ivu-table-overflowX");
  if (dom) {
    dom.scrollLeft = 99999;
  }
}

export default {
  name: "productDetails",
  mixins: [productMixin, Mixin],
  data() {
    let v = this;
    return {
      selectType: "spu", //模式为pod的时候，选择主料是 spu 或者 sku
      ingredientCurrentIndex: null, //选择主料Sku时记录的index，用于选择后的插入数据
      partAndMeatSettingIndex: null, //选择部位与素材，用于选择后的插入数据
      apiUrl: api.post_productGoods_query,
      productData: [], // 商品
      specification: [], // 规格
      ymsProductInfoTemplateId: "",
      valueIsNull: "",
      platformProductId: "", //  2.0的商品id
      d_size: [],
      productColorImagesValue: [],
      choseSize: [],
      productInfo: {
        ymsProductCategoryId: null, // 云卖分类
        spu: "", // 商品货号/SPU
        imageTypeList: [],
        productService: 1, // 商品服务
        productType: 1, // 1 商品 2 原材料 3 组装商品
        deliveryInterval: 1, // 发货时效
        productGoodsList: [
          {
            productId: null,
            sku: null,
            width: 0,
            height: 0,
            length: 0,
            weight: 0,
            suggestPrice: 0,
            supplyPrice: null,
            availableNumber: null,
            productGoodsSpecifications: [],
            originalSuggestPrice: 0
          }
        ], // 尺码、颜色的列表
        cnName: "", // 商品标题描述
        productImages: [], // 商品图片列表
        description: "" // 供应商建议
      },
      nameTalg: "",
      productPriceSchemeBos: [],
      rules: {
        ymsProductCategoryId: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1000710'), trigger: "change"}],
        spu: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1000711')}],
        productImages: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1000712')}],
        imageTypeList: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1000713')}]
      },
      uploadFilesUrl: "", // 上传url
      ymsSpu: "",
      model: false,
      exampleSrc: require("@/assets/images/example.png"),
      operationType: "add",
      drag: {
        isMove: false,
        option: {
          animation: 180
        }
      },
      sizeChartColumns: [],
      sizeChartData: [],
      sizeChartDefaultData: [],
      templateObj: {},
      sizeValueData: [],
      ymsCategoryProductAttributeList: [],
      sizeChartTalg: false, // 默认隐藏尺码表
      productColumns: [],
      tableSpanRowIndexSign: {},
      choseCategoryObj: {},
      choseIngredientObj: {},
      ingredientsObj: {},
      editObj: {},
      isShowAttrMore: true,
      categoryList: [],
      sizeJsonData: {},
      specificationValueList: [],
      showSizeChart: true, // 默认展示尺码表
      pageType: "yms", // 判断是yms 还是供应商
      currency: null,
      switchClassificationTalg: false, // 在编辑的状态下，切换云卖分类时，要重置尺码表、尺码、颜色的相关数据
      discountType: "0",
      discountData: [],
      discountColumns: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000714'),
          render(h, params) {
            return h(
              "div",
              {
                class: "t-ipts-box"
              },
              [
                h("Input", {
                  class: "t-ipt",
                  style: {
                    border: (() => {
                      return v.productPriceSchemeBos[params.index]._isErrByStart ? "1px solid red" : "";
                    })()
                  },
                  props: {
                    disabled:
                      v.isDisabled ||
                      (v.productPriceSchemeBos.length - 1 === params.index &&
                        params.index !== 0) ||
                      params.index !== 0,
                    value: v.productPriceSchemeBos[params.index].startSalesVolume || ""
                  },
                  on: {
                    "on-change": (event) => {
                      if (isNaN(Number(event.target.value))) {
                        v.$nextTick(() => {
                          let value = isNaN(parseInt(event.target.value)) ? "" : parseInt(event.target.value);
                          event.target.value = value;
                          v.productPriceSchemeBos[params.index].startSalesVolume = value;
                        });
                      } else {
                        v.productPriceSchemeBos[params.index].startSalesVolume = Number(event.target.value);
                      }
                    },
                    "on-blur": () => {
                      let _hasValue = !!v.productPriceSchemeBos[params.index].startSalesVolume;
                      v.$set(v.productPriceSchemeBos[params.index], "_isErrByStart", !_hasValue);
                    }
                  }
                }),
                h("span", {class: "mlr5"}, alias47916751af154eb5b47cd5de9d34633d.t('key1000715')),
                h("div", [
                  h("Input", {
                    class: "t-ipt",
                    style: {
                      border: (() => {
                        return v.productPriceSchemeBos[params.index]._isErr ? "1px solid red" : "";
                      })(),
                      "margin-top": (() => {
                        return v.productPriceSchemeBos[params.index]._isErr ? "21px" : "";
                      })()
                    },
                    props: {
                      disabled: v.isDisabled || v.productPriceSchemeBos.length - 1 === params.index,
                      value: v.productPriceSchemeBos[params.index].endSalesVolume || ""
                    },
                    on: {
                      "on-change": (event) => {
                        if (isNaN(Number(event.target.value))) {
                          v.$nextTick(() => {
                            let value = isNaN(parseInt(event.target.value)) ? "" : parseInt(event.target.value);
                            event.target.value = value;
                            v.productPriceSchemeBos[params.index].endSalesVolume = value;
                            if (v.productPriceSchemeBos[params.index + 1]) {
                              v.productPriceSchemeBos[params.index + 1].startSalesVolume = value + 1;
                            }
                          });
                        } else {
                          v.productPriceSchemeBos[params.index].endSalesVolume = Number(event.target.value);
                          v.$nextTick(() => {
                            if (v.productPriceSchemeBos[params.index + 1]) {
                              v.productPriceSchemeBos[params.index + 1].startSalesVolume = Number(event.target.value) + 1;
                            }
                          });
                        }
                      },
                      "on-blur": () => {
                        let _isErr = false;
                        if (params.index < v.productPriceSchemeBos.length - 1) {
                          _isErr = v.productPriceSchemeBos[params.index].endSalesVolume <= v.productPriceSchemeBos[params.index].startSalesVolume;
                          v.$set(v.productPriceSchemeBos[params.index], "_isErr", _isErr);
                        }
                      }
                    }
                  }),
                  v.productPriceSchemeBos[params.index]._isErr &&
                  h("p", {style: {color: "red"}}, alias47916751af154eb5b47cd5de9d34633d.t('key1000716'))
                ])
              ]
            );
          }
        },
        {
          renderHeader(h) {
            return h("div", v.discountType === "0" ? alias47916751af154eb5b47cd5de9d34633d.t('key1000717') : alias47916751af154eb5b47cd5de9d34633d.t('key1000718'));
          },
          render(h, params) {
            return h("div", [
              h("Input", {
                class: "t-ipt",
                style: {
                  border: (() => {
                    return v.productPriceSchemeBos[params.index]._isErrByPrice ? "1px solid red" : "";
                  })()
                },
                props: {
                  disabled: v.isDisabled,
                  value: v.productPriceSchemeBos[params.index].schemeValue || "",
                  placeholder: v.discountType === "0" ? alias47916751af154eb5b47cd5de9d34633d.t('key1000719') : alias47916751af154eb5b47cd5de9d34633d.t('key1000720')
                },
                on: {
                  "on-change": (event) => {
                    if (isNaN(Number(event.target.value))) {
                      let value = isNaN(parseInt(event.target.value)) ? "" : parseInt(event.target.value);
                      if (v.discountType === "1" && (value > 1 || value < 0)) {
                        value = 1;
                      } else if (v.discountType === "0" && value < 0) {
                        value = 0;
                      }
                      v.$nextTick(() => {
                        event.target.value = value;
                        v.productPriceSchemeBos[params.index].schemeValue = value;
                        v.setDiscountPrice();
                      });
                    } else {
                      if (v.discountType === "1" && (event.target.value > 1 || event.target.value < 0)) {
                        v.$nextTick(() => {
                          event.target.value = 1;
                          v.productPriceSchemeBos[params.index].schemeValue = 1;
                          v.setDiscountPrice();
                        });
                      } else if (v.discountType === "0" && event.target.value < 0) {
                        v.$nextTick(() => {
                          event.target.value = 0;
                          v.productPriceSchemeBos[params.index].schemeValue = 0;
                          v.setDiscountPrice();
                        });
                      } else {
                        v.productPriceSchemeBos[params.index].schemeValue = Number(event.target.value);
                      }
                    }
                    v.setDiscountPrice();
                  },
                  "on-blur": () => {
                    v.$set(v.productPriceSchemeBos[params.index], "_isErrByPrice", !v.productPriceSchemeBos[params.index].schemeValue);
                  }
                }
              })
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000721'),
          render(h, params) {
            if (v.productPriceSchemeBos[params.index].price) {
              let price = v.productPriceSchemeBos[params.index].preferentialPrice || 0;
              if (price !== 0) {
                price = Math.round(price * 100) / 100;
              }
              return h("div", [
                h("span", alias47916751af154eb5b47cd5de9d34633d.t('key1000242') + params.row.price),
                h("span", {class: "ml20"}, alias47916751af154eb5b47cd5de9d34633d.t('key1000722') + price)
              ]);
            } else {
              return h("div", "-");
            }
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000283'),
          render(h, params) {
            return !v.isDisabled ? h("span", {
                class: "t-del",
                on: {
                  click() {
                    if (params.index !== 0) {
                      if (params.index === v.productPriceSchemeBos.length) {
                        v.productPriceSchemeBos[params.index - 1].endSalesVolume = alias47916751af154eb5b47cd5de9d34633d.t('key1000723');
                        v.productPriceSchemeBos[params.index - 1].startSalesVolume = v.productPriceSchemeBos[params.index - 2].endSalesVolume + 1;
                      } else {
                        v.productPriceSchemeBos[params.index].startSalesVolume = v.productPriceSchemeBos[params.index - 1].endSalesVolume + 1;
                      }
                    }
                    v.discountData.splice(params.index, 1);
                    v.productPriceSchemeBos.splice(params.index, 1);
                  }
                }
              },
              alias47916751af154eb5b47cd5de9d34633d.t('key1000724')) : "";
          }
        }
      ],
      adoptTalg: false,
      auditIsShelve: 1,
      labelRander: (params) => {
        return alias47916751af154eb5b47cd5de9d34633d.t('key1000725') + params.title + alias47916751af154eb5b47cd5de9d34633d.t('key1000001');
      },
      automaticallyTitle: false, // 默认自动生成多语种的商品标题
      classificationName: "",
      salesPriceConfigTalg: true,
      activeIndex: null,
      assemblyInfoTalg: false,
      assemblyInfoData: [],
      assembleImgTalg: false,
      assemblyProductColorImageList: [],
      listingProductAttributeList: [], // listing 商品属性信息
      proportionList: [], // 属性值比例
      resetRequiredTalg: false, // 重置必填项
      defaultSelectKeyList: [],
      fixedColumnkeyList: [],
      setColumnsList: [],
      originalColumns: [],
      cellSpanOption: {
        bodyCellSpan: v.tableSpanProductGood
      },
      virtualScrollOption: {
        enable: false
      },
      platformId: null,
      productDataSpan: [],
      recordAndUploadImagesList: [],
      beforeHandleUploadImagesList: [],
      multilingualSizeValue: "usSize",
      kjnovaClipperMultiple: false,
      selectValue1: "",
      ingredientProductGoodsList: [], // 选择主料，能匹配上规格属性的sku
      merchantConfigurationFactory: false,
      showProductSaleCountrySet: false,
      associationPropertyData: [], // 所有关联属性的数据
      insertedListIds: null,
      changeInAttributeValues: false,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  computed: {
    isDisabled() {
      let system = localStorage.getItem("system") || this.$store.state.system;
      if (this.operationType === "look") {
        return true;
      } else {
        return system.includes("yms");
      }
    },
    // 判断当前选中的属性值是否大于一个
    proportionTalg() {
      let talg = false;
      if (this.proportionList.length > 0) {
        talg = this.proportionList.some((item) => {
          return item.proportionValueList.length > 1;
        });
      } else {
        talg = false;
      }
      return talg;
    },
    isIframe() {
      return this.$route.path === "/productDetailsByExternal";
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  created() {
    if (this.systemTalg) {
      getMerchantConfigurationFactory(this.userInfo).then((value) => {
        this.merchantConfigurationFactory = value === 'Y';
      });
    }
    this.initProductDetailsData();
  },
  methods: {
    // 初始化数据
    async initProductDetailsData() {
      await this.initMultilingualFields("productInfo", this.productInfo, ["name_"]);
      this.queryInit();
      window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
      this.init();
    },
    // query 初始化
    queryInit() {
      let query = this.$route.query;
      if (query) {
        const {productId, title, type, platformId} = query;
        this.operationType = type;
        if (this.isIframe) {
          this.operationType = productId ? "edit" : "add";
          this.platformProductId = platformId;
          if (this.operationType === "add" && title) {
            this.productInfo["name_CN"] = title;
          }
        }
        this.productId = productId;
      }
    },
    // 发送消息
    postMessage(obj) {
      window.parent.postMessage(obj, "*");
    },
    onSelectClickErrorHandel(refName) {
      let v = this;
      if (v.operationType !== "look") {
        let _show = [];
        _show.push(this.$refs[refName][0].visible);
        setTimeout(() => {
          _show.push(this.$refs[refName][0].visible);
          if (!_show[0] && !_show[1]) {
            this.$refs[refName][0].visible = true;
          }
        }, 256);
      }
    },
    // 获取所有分类
    _getCategoryList() {
      let v = this;
      let obj = commonSessionStorage.getItem("erpConfig");
      let ymsSiteId = null;
      let list = [];
      return new Promise((resolve) => {
        if (v.system === "yms") {
          getYmsSiteData().then((data) => {
            if (data.length > 0) {
              let query = v.$route.query;
              if (query.platformId === "alibabagj") {
                v.platformId = query.platformId;
                list = data.filter((item) => {
                  return item.merchantType === "ALIGJ";
                });
              } else {
                list = data.filter((item) => {
                  return item.merchantType === "YMS";
                });
              }
              ymsSiteId = list[0].ymsSiteId;
              handleData(ymsSiteId);
            }
          });
        } else {
          ymsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
          handleData(ymsSiteId);
        }

        function handleData(ymsSiteId) {
          let url =
            v.system === "yms" ? api.post_ymsProductCategory_queryAll : api.post_ymsProductCategory_api_queryAll;
          let query = {
            ymsSiteId: ymsSiteId
          };
          if (ymsSiteId) {
            startLoading();
            v.axios.post(url, query).then((response) => {
              let data = response.data.datas || [];
              v.$nextTick(() => {
                endLoading();
              });
              resolve(data);
            }).catch(() => {
              endLoading();
            });
          }
        }
      });
    },

    // 获取详情
    _getDetails(productId) {
      return new Promise((resolve) => {
        this.axios.get(api.get_productInfo + productId).then((response) => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            resolve(data);
          }
        });
      });
    },
    // 初始化
    init() {
      let query = this.$route.query;
      let v = this;
      let paramsKeyList = ['enableProductSaleCountrySet', 'enableProductSpecifications'];
      if (query) {
        v.ymsProductInfoTemplateId = query.ymsProductInfoTemplateId;
        if (v.productId && v.operationType !== "add") {
          if (!v.getPermission("productInfo_detail")) {
            v.gotoError();
          } else {
            Promise.all([
              v._getCategoryList(),
              v._getDetails(query.productId),
              getCommonParamKeysData(v.userInfo.merchantId, paramsKeyList, false)]).then((result) => {
              v.categoryList = result[0];
              v.toView(result[1]);
              // 获取商品设置
              let productSettingsData = result[2] || [];
              const keyToPropertyMap = {
                enableProductSaleCountrySet: 'showProductSaleCountrySet',
              };
              productSettingsData.forEach(({paramKey, paramValue}) => {
                if (keyToPropertyMap[paramKey]) {
                  v[keyToPropertyMap[paramKey]] = paramValue ? Number(paramValue) === 1 : false;
                }
              });
            });
          }
        }
        v.currency = v.currencyState;
      }
      v.pageType = v.setPageType();
      v.setUploadActionUrl(v.systemTalg);
    },
    // 处理商品标题数据
    handleProductTitle(data) {
      let v = this;
      let names = data.name || "";
      if (names) {
        if (isJSON(names)) {
          let obj = JSON.parse(names);
          for (let key in obj) {
            v.productInfo["name_" + key] = obj[key];
          }
        } else {
          v.productInfo["name_CN"] = names;
        }
      }
    },
    // 输入参数到页面
    toView(data) {
      let v = this;
      if (data) {
        v.productInfo = data;
        if (data.productType === 4) {
          v.choseIngredientObj.cnName = data.masterName;
        }
        _getInfoByCategoryId(data.ymsProductCategoryId, data); // 基于云卖分类获取相关数据
        _toViewBasicHandle(data); // 基础信息赋值需要处理数据
        _handlerScheme(data); // 云卖供货价格
        _handleListingProductAttribute(data);
        setTimeout(() => {
          _setSizeTableScrollLeft();
        }, 2000);
      }

      // 处理listing商品属性信息
      function _handleListingProductAttribute(data) {
        v.listingProductAttributeList = [];
        let platformJson = data.platformJson || "";
        if (platformJson) {
          let obj = JSON.parse(platformJson);
          if (obj && Object.keys(obj).length > 0) {
            v.listingProductAttributeList = obj.listingPushPlatformInsertProductInfoBo ? obj.listingPushPlatformInsertProductInfoBo.productAttributes : [];
          }
        }
      }

      // 基础信息赋值需要处理数据
      function _toViewBasicHandle(data) {
        if (!v.productInfo.productService) {
          v.productInfo.productService = 1;
        }
        v.productInfo.imageTypeList =
          v.typeOf(data.imageTypeList) === "array" ? data.imageTypeList : [];
        if (v.systemTalg) {
          // 供应商系统
          v.productInfo.spu = data.spu;
        } else {
          // 云卖系统
          v.productInfo.spu = data.supplierSpu;
        }
        // 商品标题多语种
        v.handleProductTitle(data);
        let arr = v.categoryList.filter((i) => i.ymsProductCategoryId === v.productInfo.ymsProductCategoryId);
        v.choseCategoryObj = arr && arr.length ? arr[0] : null;
        v.productInfo.productImages = data.productImageList.map((i) => i.path);
      }

      // 基于云卖分类获取相关数据
      function _getInfoByCategoryId(_categoryId, _productInfo) {
        Promise.all([v.getItemAttributesData(_categoryId), v.getMainMaterialsData(_productInfo)]).then((result) => {
          v.attributeHandel(result[0], _productInfo.productAttributeResultList); // 基础属性处理
          v.specificationHandel(result[0], _productInfo); // 将云卖数据组装成规格属性
          if (_productInfo.productType === 4) {
            handleData(result[1]);
          } else {
            handleData(_productInfo.productGoodsList);
          }

          function handleData(newProductGoodsList) {
            if (newProductGoodsList) {
              newProductGoodsList.map((item) => {
                item.originalSuggestPrice = item.supplyPrice || null;
              });
              v.productInfo.productGoodsList = newProductGoodsList;
              _coreSetCurrency(newProductGoodsList); // 运营系统设置币种
              let _productColorImageList = _productInfo.productColorImageList || [];
              _specificationToView(newProductGoodsList, _productColorImageList); //商品规格
              _productGoodsList(newProductGoodsList); // 商品
            }
            v.handlerTemplate(result[0]); // 处理尺码表的数据
          }
        });
      }

      // 商品
      function _productGoodsList(_productGoodsList) {
        let _mainAttr = v.specification.filter((i) => i.isMainAttribute);
        if (_mainAttr && _mainAttr.length) {
          _mainAttr = _mainAttr[0].label;
        }
        let sortData = _sort(_productGoodsList); // 进行排序
        let colspanObj = {};
        v.productData = sortData.map((i) => {
          let obj = {
            ingredientsObj: i.ingredientsObj,
            height: i.height,
            length: i.length,
            productId: i.productId,
            sku: i.sku,
            specifications: i.productGoodsSpecifications.map((j) => {
              let obj = {
                label: j.name,
                value: j.value,
                type: j.type
              };
              if (j.name === _mainAttr) {
                obj._isMainAttribute = true;
              }
              return obj;
            }),
            path: i.productGoodsImages ? i.productGoodsImages[0].path : null,
            weight: i.weight,
            width: i.width,
            availableNumber: i.availableNumber,
            suggestPrice: i.suggestPrice,
            floatNum: i.floatNum || 0,
            floatType: i.floatType || 1,
            salePriceType: i.salePriceType || 1,
            originalSuggestPrice: i.supplyPrice,
            isAssemble: i.isAssemble || 1,
            productGoodsAssembles: (i.productGoodsAssembles || []).map((i) => {
              i.productGoodsId = i.materialProductGoodsId || i.productGoodsId;
              return i;
            })
          };
          if (v.systemTalg) {
            obj.supplyPrice = i.suggestPrice;
          } else {
            obj.supplyPrice = i.supplyPrice;
          }
          _setTableColSpan(obj.specifications);
          return obj;
        });
        // 初始化 specifications
        v.productInfo.productGoodsList = sortData.map((i) => {
          if (!v.systemTalg) {
            i.sku = i.supplierSku;
          }
          i.path = i.productGoodsImages ? i.productGoodsImages[0].path : null;
          if (i.productGoodsSpecifications) {
            i.specifications = i.productGoodsSpecifications.map((j) => {
              let obj = {label: j.name, value: j.value, type: j.type};
              if (j.name === _mainAttr) {
                obj._isMainAttribute = true;
              }
              return obj;
            });
          }

          i.originalSuggestPrice = i.supplyPrice;
          i.floatNum = i.floatNum || 0;
          i.floatType = i.floatType || 1;
          i.salePriceType = i.salePriceType || 1;
          if (v.systemTalg) {
            i.supplyPrice = i.suggestPrice;
          } else {
            i.supplyPrice = i.supplyPrice;
          }
          return i;
        });

        // 设置列表span
        function _setTableColSpan(arr) {
          arr.forEach((k) => {
            if (k._isMainAttribute) {
              if (!colspanObj[k.value]) {
                colspanObj[k.value] = {
                  rowspan: 1
                };
                k.rowspan = colspanObj;
              } else {
                colspanObj[k.value].rowspan++;
              }
            }
          });
        }

        // 排序 将相同的值的放一起
        function _sort(_productGoodsList) {
          let val = [];
          let data = []; // 排序后数据

          _productGoodsList.forEach((i) => {
            if (i.productGoodsSpecifications) {
              i.productGoodsSpecifications.forEach((j) => {
                if (!val.includes(j.value) && j.name === _mainAttr) {
                  val.push(j.value);
                }
              });
            }
          });
          // 排序
          val.forEach((a) => {
            _productGoodsList.forEach((goods) => {
              let isUnshift = false;
              goods.productGoodsSpecifications.forEach((sp) => {
                if (a === sp.value && sp.name === _mainAttr) {
                  isUnshift = true;
                }
              });
              if (isUnshift) {
                data.push(goods);
              }
            });
          });
          return data;
        }

        // 初始化规格明细列表(当前商品资料导入的sku没有一对多的关系时，前端需要重新组装数据，默认填充后台返回也有的数据，缺失的数据进行异常提示即可)
        if (_productGoodsList && _productGoodsList.length > 0) {
          let arr = []; // 选中数据
          v.specification.forEach((i) => {
            if (i.valueList.length) {
              arr.push(i.valueList);
            }
          });
          v.handleProductGoodData(arr);
        }
      }

      // 商品规格 规格数据从商品列表里面拿
      /**
       * 商品审批通过后,存在属性值停用或属性值异常时，允许修改停用/异常的属性值，其他情况下不允许修改规格属性值
       */
      function _specificationToView(_productGoodsList, _productColorImageList) {
        _productGoodsList.forEach((a) => {
          let productGoodsSpecifications = a.productGoodsSpecifications || [];
          productGoodsSpecifications.forEach((b) => {
            v.specification.forEach((i) => {
              if (b.name === i.label) {
                let disabledTalg = false;
                let disableText = "";
                let attributeTalg = i.attributeValueDeactivationData.includes(b.value) && b.type !== 0;
                if (attributeTalg) {
                  disableText = alias47916751af154eb5b47cd5de9d34633d.t('key1000726');
                }
                let attributeValueExceptionTalg = !i.attributeValueData.includes(b.value) && !i.isAllowCustomValue;
                if (attributeValueExceptionTalg) {
                  disableText = alias47916751af154eb5b47cd5de9d34633d.t('key1000727');
                }
                if (i.valueList.every((i) => i.value !== b.value)) {
                  i.list.forEach((item) => {
                    if (item.value === b.value) {
                      item._show = false;
                    }
                  });
                  // 审批通过
                  if (v.productInfo.auditStatus === 3) {
                    disabledTalg = !(
                      attributeTalg || attributeValueExceptionTalg
                    );
                  } else {
                    disabledTalg = false;
                  }
                  i.valueList.push({
                    value: b.value,
                    label: i.label,
                    imgList: [],
                    attributeTalg: attributeTalg, // 属性值停用状态
                    disabled: disabledTalg, // 禁用状态
                    disableText: disableText, // 异常提示文本
                    attributeValueExceptionTalg: attributeValueExceptionTalg // 属性值异常状态
                  });
                  i.valueList = v.uniqueFunc(i.valueList, "value");
                  // 属于自定义属性
                  if (!i.attributeValueData.includes(b.value) && i.isAllowCustomValue) {
                    i.list.push({
                      value: b.value,
                      _show: true,
                      attributeTalg: attributeTalg, // 属性值停用状态
                      disabled: disabledTalg,
                      disableText: disableText,
                      attributeValueExceptionTalg: attributeValueExceptionTalg
                    });
                    i.list = v.uniqueFunc(i.list, "value");
                  }
                }
              }
            });
          });
        });
        let colorImgList = v.handerGrouping(_productColorImageList, function (item) {
          return [item.color];
        }, "color");
        if (colorImgList.length > 0) {
          v.specification.forEach((a) => {
            if (a.isMainAttribute) {
              a.valueList.forEach((b) => {
                colorImgList.forEach((img) => {
                  if (b.value === img.color) {
                    b.imgList = img.data ? img.data.slice(0, 5).map((i) => i.path) : [];
                  }
                });
              });
            }
          });
        }
      }

      // 云卖供货价格
      function _handlerScheme({productPriceSchemeResultBos, productGoodsList}) {
        if (productPriceSchemeResultBos && productPriceSchemeResultBos.length > 0) {
          v.discountType = productPriceSchemeResultBos[0].schemeType + "";
          let price = "";
          if (productGoodsList) {
            price = Math.min(...productGoodsList.map((i) => i.supplyPrice));
          }
          v.productPriceSchemeBos = productPriceSchemeResultBos.map((i) => {
            i.endSalesVolume = i.endSalesVolume === null ? alias47916751af154eb5b47cd5de9d34633d.t('key1000723') : i.endSalesVolume;
            i.price = price;
            let _schemeValue = i.schemeValue || 0;
            if (i.schemeType === 0) {
              i.preferentialPrice = i.price - _schemeValue;
            } else if (i.schemeType === 1) {
              i.preferentialPrice = i.price * _schemeValue;
            }
            return i;
          });
          v.discountData = JSON.parse(
            JSON.stringify(v.productPriceSchemeBos)
          );
        }
      }

      //  运营系统设置币种
      function _coreSetCurrency(_productGoodsList) {
        if (v.pageType === "yms") {
          v.currency = _productGoodsList[0].currency;
        }
      }
    },
    // 获取主料的数据
    getMainMaterialsData(_productInfo) {
      let v = this;
      let newList = _productInfo.productGoodsList || [];
      let productGoodsAssemblesList = [];
      let url = v.systemTalg ? api.post_productGoods_queryPodProductList : api.post_productGoods_api_queryPodProductList;
      let goodList = [];
      let requests = []; // 用于存储所有请求的 Promise
      return new Promise((resolve, reject) => {
        if (_productInfo.productType === 4) {
          if (newList.length > 0) {
            newList.map((item) => {
              let productGoodsAssembles = item.productGoodsAssembles || [];
              productGoodsAssemblesList.push(...productGoodsAssembles);
            });

            if (productGoodsAssemblesList.length > 0) {
              let newlist = v.uniqueFunc(productGoodsAssemblesList, 'factorySku');
              // 根据 ymsPlatformAccountId 分组
              let newData = v.handerGrouping(newlist, function (item) {
                return [item.ymsPlatformAccountId];
              }, 'ymsPlatformAccountId', 'ymsPlatformAccountList');

              if (newData.length > 0) {
                newData.map((item) => {
                  let query = {
                    ymsPlatformAccountId: item.ymsPlatformAccountId,
                    skuList: item.ymsPlatformAccountList.map((ele) => ele.factorySku),
                    pageSize: 500
                  }
                  if (!v.systemTalg) {
                    query.supplierMerchantId = v.productInfo.supplierMerchantId;
                  }

                  // 将每个请求的 Promise 添加到 requests 数组中
                  requests.push(
                    v.axios.post(url, query).then((res) => {
                      if (res.data.code === 0) {
                        let data = res.data.datas;
                        if (data) {
                          let productApiResultBos = data.productApiResultBos || [];
                          productApiResultBos.map((ele) => {
                            let productGoodsList = ele.productGoodsList || [];
                            if (productGoodsList.length > 0) {
                              let skuItem = productGoodsList[0];
                              productGoodsList.map((talg) => {
                                let obj = {
                                  ...ele,
                                  ...talg,
                                  ymsPlatformAccountId: query.ymsPlatformAccountId
                                }
                                obj.imagePath = skuItem.goodsImagePathList ? skuItem.goodsImagePathList[0] : "";
                                goodList.push(obj);
                              });
                            }
                          });
                        }
                      }
                    })
                  );
                });

                // 等待所有请求完成
                Promise.all(requests).then(() => {
                  // 所有请求完成后处理 goodList
                  if (goodList.length > 0) {
                    newList.map((talg) => {
                      let productGoodsAssembles = talg.productGoodsAssembles || [];
                      productGoodsAssembles.map((k) => {
                        goodList.map((ele) => {
                          if (k.factorySku === ele.sku) {
                            talg['ingredientsObj'] = ele;
                          }
                        });
                      });
                      talg.partAndMeatSetting = talg.productGoodsPositions;
                      talg.materialProcess = 1;
                    });
                    resolve(newList);
                  } else {
                    resolve(newList);
                  }
                }).catch(reject);
              }
            }
          }
        } else {
          resolve(newList);
        }
      });
    },
    // 处理数据
    handleProductGoodData(data, specIndex, selectValue) {
      // 设置表头
      this.setProductGoodColumn().then(() => {
        // 笛卡尔乘积
        this.calcDescartes(data).then((cartesian) => {
          this.setProductGoodData(cartesian, specIndex, selectValue);
        });
      });
    },
    // 商品属性change事件
    productAttributeChange(value, attrIndex, obj) {
      if (this.changeInAttributeValues) {
        if (value && value.length > 0) {
          if (this.typeOf(value) === "array" && value.length > 5) {
            this.$Message.info(alias47916751af154eb5b47cd5de9d34633d.t('key1000728'));
            let len = this.ymsCategoryProductAttributeList[attrIndex].propertyValues.length;
            this.ymsCategoryProductAttributeList[attrIndex].propertyValues.splice(5, len - 5);
          }
          this.$set(this.ymsCategoryProductAttributeList[attrIndex], "attrRequireValid", true);
        } else {
          if (this.ymsCategoryProductAttributeList[attrIndex].ymsProductAttributeTemplate.attributeRequire === 0) {
            this.$set(this.ymsCategoryProductAttributeList[attrIndex], "attrRequireValid", false);
          } else {
            this.$set(this.ymsCategoryProductAttributeList[attrIndex], "attrRequireValid", true);
          }
        }
        // 切换属性值，自动去掉异常的属性值
        if (value) {
          let obj = this.ymsCategoryProductAttributeList[attrIndex];
          if (obj.disableText) {
            if (this.typeOf(value) === "array") {
              let attributeValueData = obj.ymsProductAttributeTemplate.productAttributeDictionaryBos;
              if (attributeValueData.length > 0) {
                let newList = attributeValueData.filter((ele) => {
                  return ele.ymsProductAttributeDictionaryId === ele.cnValue;
                });
                if (obj.propertyValues.length > 0) {
                  if (newList.length > 0) {
                    newList.map((item) => {
                      if (!obj.propertyValues.includes(item.ymsProductAttributeDictionaryId)) {
                        if (item.ymsProductAttributeDictionaryId == item.cnValue) {
                          attributeValueData.map((ele, idx) => {
                            if (ele.ymsProductAttributeDictionaryId === item.cnValue) {
                              attributeValueData.splice(idx, 1);
                            }
                          });
                          obj.disableText = "";
                        }
                      }
                    });
                  }
                } else {
                  this.ymsCategoryProductAttributeList.map((item) => {
                    if (item.ymsProductAttributeTemplate.productAttributeDictionaryBos.length > 0) {
                      item.ymsProductAttributeTemplate.productAttributeDictionaryBos = item.ymsProductAttributeTemplate.productAttributeDictionaryBos.filter((ele) => {
                        return (ele.ymsProductAttributeDictionaryId !== ele.cnValue);
                      });
                    }
                  });
                  this.$set(
                    this.ymsCategoryProductAttributeList[attrIndex],
                    "disableText",
                    ""
                  );
                }
              }
            } else {
              let attributeValueData =
                obj.ymsProductAttributeTemplate.productAttributeDictionaryBos;
              if (attributeValueData.length > 0) {
                let newList = attributeValueData.filter((ele) => {
                  return ele.ymsProductAttributeDictionaryId === ele.cnValue;
                });
                if (obj.propertyValues) {
                  if (newList.length > 0) {
                    newList.map((item) => {
                      if (obj.propertyValues !== item.ymsProductAttributeDictionaryId) {
                        if (item.ymsProductAttributeDictionaryId == item.cnValue) {
                          attributeValueData.map((ele, idx) => {
                            if (ele.ymsProductAttributeDictionaryId === item.cnValue) {
                              attributeValueData.splice(idx, 1);
                            }
                          });
                          obj.disableText = "";
                        }
                      }
                    });
                  }
                } else {
                  this.ymsCategoryProductAttributeList.map((item) => {
                    if (item.ymsProductAttributeTemplate.productAttributeDictionaryBos.length > 0) {
                      item.ymsProductAttributeTemplate.productAttributeDictionaryBos = item.ymsProductAttributeTemplate.productAttributeDictionaryBos.filter((ele) => {
                          return (ele.ymsProductAttributeDictionaryId !== ele.cnValue);
                        }
                      );
                    }
                  });
                  this.$set(this.ymsCategoryProductAttributeList[attrIndex], "disableText", "");
                }
              }
            }
            this.$set(this.ymsCategoryProductAttributeList[attrIndex], "disableText", "");
          }
          this.$forceUpdate();
        }
        if (this.automaticallyTitle) {
          this.automaticallyTitleChange(true, "US");
        }
        // 处理联动属性
        let list = [];
        let associationProperty = this.ymsCategoryProductAttributeList[attrIndex].associationProperty;
        let ymsProductAttributeDictionaryIdList = this.ymsCategoryProductAttributeList[attrIndex].ymsProductAttributeDictionaryIdList || [];
        this.ymsCategoryProductAttributeList = this.ymsCategoryProductAttributeList.filter((item) => {
          return !ymsProductAttributeDictionaryIdList.includes(item.ymsProductAttributeTemplate.ymsProductAttributeDictionaryId);
        });
        if (associationProperty) {
          if (Array.isArray(value)) {
            list = this.associationPropertyData.filter((item) => value.includes(item.ymsProductAttributeTemplate.ymsProductAttributeDictionaryId));
          } else {
            list = this.associationPropertyData.filter((item) => item.ymsProductAttributeTemplate.ymsProductAttributeDictionaryId === value);
          }
          if (list.length > 0) {
            // 记录插入元素的唯一标识
            this.insertedListIds = list.map(item => item.ymsProductAttributeTemplate.ymsProductAttributeTemplateId);
            this.ymsCategoryProductAttributeList.splice(attrIndex + 1, 0, ...list);
            this.ymsCategoryProductAttributeList = this.uniqueFunc(this.ymsCategoryProductAttributeList, 'ymsProductAttributeTemplateId');
          } else {
            // 如果 list 为空，移除之前插入的数据
            if (this.insertedListIds && this.insertedListIds.length > 0) {
              this.ymsCategoryProductAttributeList = this.ymsCategoryProductAttributeList.filter(item => {
                return !this.insertedListIds.includes(item.ymsProductAttributeTemplate.ymsProductAttributeTemplateId);
              });
              this.insertedListIds = [];
            }
          }
        }
        this.getProportionData(this.ymsCategoryProductAttributeList);
      }
    },
    // 关联属性排序

    allowCreateAttrHandel(value, attrIndex) {
      if (this.typeOf(value) === "array" && value.length > 5) {
        this.$Message.info(alias47916751af154eb5b47cd5de9d34633d.t('key1000728'));
        let len = this.ymsCategoryProductAttributeList[attrIndex].propertyValues.length;
        this.ymsCategoryProductAttributeList[attrIndex].propertyValues.splice(5, len - 5);
        return;
      }
      this.ymsCategoryProductAttributeList[attrIndex].ymsProductAttributeTemplate.productAttributeDictionaryBos.push({
        cnValue: value,
        ymsProductAttributeDictionaryId: value,
        type: 0
      });
      this.getProportionData(this.ymsCategoryProductAttributeList);
    },
    // 回车键选中属性规格
    selectHandleEnterKey(event, attrIndex, specItem) {
      if (specItem.isAllowCustomValue) {
        this.allowCreateSpecHandel(event.target.value, attrIndex, "Enter");
      }
    },

    // 插入自定义属性值时，先校验插入的自定义值不能与可选值重复
    allowCreateSpecHandel(value, attrIndex, type) {
      let v = this;
      let list = v.specification[attrIndex].list.map((item) => {
        return item.value;
      });
      if (value) {
        let val = value.replace(/\s*/g, "");
        if (type === "Enter") {
          handleData(val);
        } else {
          if (!list.includes(val)) {
            handleData(val);
          } else {
            v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000729'));
            return false;
          }
        }
      }

      function handleData(val) {
        v.specification[attrIndex].list.push({value: val, _show: true});
        v.addSpecToList(attrIndex, {value: val, type: 0}, v.specification[attrIndex].isMainAttribute);
      }
    },
    // 变更类目  // isSelectIngredient 是否是从选择主料变更类目的
    emitSaveCategory(data, isSelectIngredient = false, ingredientItem = null) {
      this.choseCategoryObj = data;
      this.productInfo.ymsProductCategoryId = data.ymsProductCategoryId;
      // this.$refs.productInfo.validateField('ymsProductCategoryId');
      this.resetDataByModifyCategory();
      let _categoryId = data.ymsProductCategoryId;
      // 变更类目 获取基础属性和尺码模板
      Promise.all([this.getItemAttributesData(_categoryId)]).then(
        async (result) => {
          let productSpecificationList = ingredientItem?.productSpecificationList || [];
          let productGoodsList = ingredientItem?.productGoodsList || [];
          const list = await this.compareAndSetValues(result[0].ymsCategoryProductAttributeList, productSpecificationList);
          isSelectIngredient ? this.attributeHandel(result[0], list) : this.attributeHandel(result[0]); // 基础属性处理
          this.specificationHandel(result[0]); // 将云卖数据组装成规格属性
          if (isSelectIngredient) {
            this.compareSpecificationAndSetValues(productGoodsList, ingredientItem); //选择主料后的规格属性校验
          }
          this.handlerTemplate(result[0]); // 处理尺码表的数据
        }
      );
      this.isShowAttrMore = true;
      this.visibleAttribute(false);
    },
    // pod选择主料后，匹配规格属性，完全匹配则在规格明细中的主料赋值
    compareSpecificationAndSetValues(ingredientList, ingredientItem) {
      const v = this;
      _specificationToView(ingredientList);

      function _specificationToView(_productGoodsList) {
        let ingredientProductGoodsList = [];
        _productGoodsList.forEach((a) => {
          let productGoodsSpecifications = a.productGoodsSpecifications || [];
          productGoodsSpecifications.forEach((b) => {
            v.specification.forEach((i) => {
              let optionsList = i.attributeValueData || [];
              if (b.name === i.label && optionsList.includes(b.value)) {
                let ingredientIObj = {...ingredientItem, ...a};
                const isIncludes = ingredientProductGoodsList.find((fItem) => fItem.sku === ingredientIObj.sku);
                if (!isIncludes) {
                  ingredientProductGoodsList.push(ingredientIObj);
                }
                let disabledTalg = false;
                let disableText = "";
                let attributeTalg = i.attributeValueDeactivationData.includes(b.value) && b.type !== 0;
                if (attributeTalg) {
                  disableText = alias47916751af154eb5b47cd5de9d34633d.t('key1000726');
                }
                let attributeValueExceptionTalg = !i.attributeValueData.includes(b.value) && !i.isAllowCustomValue;
                if (attributeValueExceptionTalg) {
                  disableText = alias47916751af154eb5b47cd5de9d34633d.t('key1000727');
                }
                if (i.valueList.every((i) => i.value !== b.value)) {
                  i.list.forEach((item) => {
                    if (item.value === b.value) {
                      item._show = false;
                    }
                  });
                  // 审批通过
                  if (v.productInfo.auditStatus === 3) {
                    disabledTalg = !(attributeTalg || attributeValueExceptionTalg);
                  } else {
                    disabledTalg = false;
                  }
                  i.valueList.push({
                    value: b.value,
                    label: i.label,
                    imgList: [],
                    attributeTalg: attributeTalg, // 属性值停用状态
                    disabled: disabledTalg, // 禁用状态
                    disableText: disableText, // 异常提示文本
                    attributeValueExceptionTalg: attributeValueExceptionTalg // 属性值异常状态
                  });
                  i.valueList = v.uniqueFunc(i.valueList, "value");
                  // 属于自定义属性
                  if (!i.attributeValueData.includes(b.value) && i.isAllowCustomValue) {
                    i.list.push({
                      value: b.value,
                      _show: true,
                      attributeTalg: attributeTalg, // 属性值停用状态
                      disabled: disabledTalg,
                      disableText: disableText,
                      attributeValueExceptionTalg: attributeValueExceptionTalg
                    });
                    i.list = v.uniqueFunc(i.list, "value");
                  }
                }
              }
            });
          });
        });
        v.ingredientProductGoodsList = ingredientProductGoodsList;

        let arr = []; // 选中数据
        v.specification.forEach((i) => {
          if (i.valueList.length) {
            arr.push(i.valueList);
          }
        });
        v.handleProductGoodData(arr);
      }
    },
    // 比较主料与类目的的商品属性，根据cnName是否相同，将主料的values赋值到商品属性
    compareAndSetValues(categoryList, ingredientList) {
      return new Promise((resolve) => {
        if (!categoryList || categoryList.length === 0) resolve([]);
        let list = [];
        const map = new Map(
          categoryList.map((cItem) => {
            if (cItem.ymsProductAttributeTemplate) {
              return [
                cItem.ymsProductAttributeTemplate.cnName,
                cItem.ymsProductAttributeTemplate.ymsProductAttributeTemplateId
              ];
            }
          })
        );
        ingredientList.forEach((iItem) => {
          if (map.has(iItem.name)) {
            let obj = {
              ...iItem,
              ymsProductAttributeTemplateId: map.get(iItem.name)
            };
            list.push(obj);
          }
        });
        resolve(list);
      });
    },
    // 修改分类数据重置
    resetDataByModifyCategory() {
      this.productData = [];
      this.productInfo.productGoodsList = [];
      this.sizeChartData = [];
      this.initMultilingualFields("productInfo", this.productInfo, ["name_"]);
    },
    // 处理参数
    handleParamsFun(type) {
      let v = this;

      // 尺码表参数整理
      function _sizeParams() {
        // 过滤尺码表中没有选中的尺码
        let list = v.uniqueFunc(v.sizeChartData, "cnSize");
        if (v.specification && v.specification.length > 0) {
          let newList = v.specification.filter((item) => {
            return item.id === "_size";
          });
          if (newList && newList.length > 0) {
            let valuesList = newList[0].valueList.map((i) => i.value);
            if (valuesList.length > 0) {
              v.sizeChartData = list.filter((item) => {
                return valuesList.includes(item.cnSize);
              });
            }
          }
        }
        let insetProductSizeBoList = [];
        if (!v.sizeJsonData || !v.sizeJsonData.productSizePartsBos) {
          return [];
        }
        let data = v.sizeJsonData.productSizePartsBos;
        let productSizeList = v.productInfo.productSizeList;
        let keyList = [
          "cnSize",
          "ukSize",
          "euSize",
          "usSize",
          "auSize",
          "frSize",
          "deSize",
          "esSize",
          "nlSize",
          "plSize",
          "ptSize",
          "itSize",
          "roSize"
        ];
        if (productSizeList && productSizeList.length > 0 && !v.switchClassificationTalg) {
          v.sizeChartData.forEach((item) => {
            let obj = {};
            keyList.map((key) => {
              obj[key] = "";
            });
            obj["sizeDetailBos"] = [];
            v.sizeJsonData.productSizePartsBos.forEach((k, index) => {
              if (k.cnName && item["unitName_" + k.cnName] && item["unitValue_" + index]) {
                obj.sizeDetailBos.push({
                  partsName: k.cnName,
                  unitName: item["unitName_" + k.cnName],
                  unitValue: item["unitValue_" + index]
                });
              }
            });
            keyList.map((key) => {
              obj[key] = item[key];
            });
            insetProductSizeBoList.push(obj);
          });
        } else {
          v.sizeChartData.forEach((item) => {
            let obj = {};
            keyList.map((key) => {
              obj[key] = "";
            });
            obj["sizeDetailBos"] = [];
            data.forEach((ele) => {
              if (item[ele.ymsProductSizePartsId + "_partName"] && item[ele.ymsProductSizePartsId + "_defaultUnitName"]) {
                obj.sizeDetailBos.push({
                  partsName: item[ele.ymsProductSizePartsId + "_partName"],
                  unitName: item[ele.ymsProductSizePartsId + "_defaultUnitName"],
                  unitValue: item[ele.ymsProductSizePartsId + "_defaultValue"] || null
                });
              }
            });
            keyList.map((key) => {
              obj[key] = item[key];
            });
            insetProductSizeBoList.push(obj);
          });
        }
        return insetProductSizeBoList;
      }

      // 商品图片参数整理
      function _goodsImage() {
        let goodsImgParams = [];
        let mainGoods = v.specification.filter((i) => i.isMainAttribute);
        if (mainGoods && mainGoods.length) {
          mainGoods.map((i) => {
            i.valueList.forEach((k) => {
              k.imgList.forEach((imgPath) => {
                goodsImgParams.push({
                  color: k.value,
                  path: imgPath
                });
              });
            });
          });
        }
        return goodsImgParams;
      }

      // 供货价格
      function _schemeParams() {
        return v.productPriceSchemeBos.map((i, index) => {
          return {
            endSalesVolume: i.endSalesVolume === alias47916751af154eb5b47cd5de9d34633d.t('key1000723') ? null : i.endSalesVolume,
            schemeType: v.discountType,
            schemeValue: i.schemeValue,
            productPriceSchemeId: i.productPriceSchemeId,
            productId: i.productId,
            sortNo: index,
            startSalesVolume: i.startSalesVolume
          };
        });
      }

      let obj = JSON.parse(JSON.stringify(v.productInfo));
      let params = {
        name: v.handleMultilingualSingleParam(v.productInfo, "name_"), // 商品标题
        deliveryInterval: obj.deliveryInterval, // 发货时效
        description: obj.description, // 商品描述
        insetProductSizeBoList: [], // 产品尺码集合
        operationType: type, // 操作类型 0 暂存 1 提交
        imageTypeList: obj.imageTypeList, // 操作类型 0 自拍模特图 1 网红图 2 实物摆拍图
        productAttributes: [], // 基础属性集合
        productColorImages: [], // 商品颜色图片
        productGoodsList: [], // 货品集合
        productImages: obj.productImages, // 商品其他图片
        productService: obj.productService, // 商品服务
        ymsProductCategoryId: obj.ymsProductCategoryId,
        auditIsShelve: v.auditIsShelve, // 审核后是否上架 0否 1是
        productType: obj.productType // 类型 1商品 2原材料 3组装商品
      };
      // pod类型
      if (obj.productType === 4) {
        params.masterName = this.choseIngredientObj.cnName;
      }
      if (v.isIframe) {
        params.source = "tongtool_old";
        params.platformProductId = v.platformProductId;
      }
      if (!v.systemTalg) {
        params.supplierSpu = v.productInfo.spu;
        params.ymsProductId = obj.ymsProductId;
      } else {
        params.spu = v.productInfo.spu;
        params.productId = obj.productId;
      }
      if (v.operationType !== "add") {
        params.productId = obj.productId;
      }
      params.merchantId = obj.merchantId;
      // 基础属性
      if (v.ymsCategoryProductAttributeList.length > 0) {
        // 处理属性值比例的数据
        if (v.proportionList.length > 0) {
          v.ymsCategoryProductAttributeList.map((item) => {
            v.proportionList.map((ele) => {
              if (item.ymsProductAttributeTemplateId === ele.ymsProductAttributeTemplateId) {
                let newList = item.ymsProductAttributeTemplate.productAttributeDictionaryBos || [];
                let isProportion = item.ymsProductAttributeTemplate.isProportion === 1;
                newList.map((talg) => {
                  ele.proportionValueList.map((k) => {
                    if (talg.cnValue === k.name) {
                      if (isProportion) {
                        let length = item.propertyValues.length;
                        talg.proportion = length === 1 ? 100 : Number(k.value) || 0;
                      }
                    }
                  });
                });
              }
            });
          });
        }
        params.productAttributes = v.ymsCategoryProductAttributeList.map((i) => {
          let values = [];
          if (i.propertyValues) {
            // 判断属性值是否自定义的 (0  自定义，  1可选值)
            let list = i.ymsProductAttributeTemplate.productAttributeDictionaryBos || [];
            if (v.typeOf(i.propertyValues) === "string") {
              if (list.length > 0) {
                // 可选值
                if (i.ymsProductAttributeTemplate.type === 1) {
                  list.map((item) => {
                    if (item.ymsProductAttributeDictionaryId === i.propertyValues) {
                      values.push({
                        type: item.type === 0 ? 0 : 1,
                        value: i.propertyValues
                      });
                    }
                  });
                }
                // 自定义--> 单位
                else {
                  values.push({
                    type: 0,
                    value: i.propertyValues,
                    unit: i.unit
                  });
                }
              } else {
                values.push({
                  type: 0,
                  value: i.propertyValues
                });
              }
            } else {
              if (i.propertyValues && i.propertyValues.length > 0) {
                i.propertyValues.map((item) => {
                  list.map((ele) => {
                    if (item === ele.ymsProductAttributeDictionaryId) {
                      values.push({
                        type: ele.type === 0 ? 0 : 1,
                        value: item,
                        proportion: ele.proportion / 100
                      });
                    }
                  });
                });
              }
            }
          }
          return {
            type: i.ymsProductAttributeTemplate.type,
            values: values,
            ymsProductAttributeTemplateId: i.ymsProductAttributeTemplateId
          };
        });
      } else {
        params.productAttributes = [];
      }
      // 商品 (供应商系统和运营系统返回productGoodsList的参数不一样，需要做区分)
      if (obj.productGoodsList.length > 0) {
        if (v.systemTalg) {
          // 供应商系统
          params.productGoodsList = obj.productGoodsList.map((i, index) => {
            let specifications = [];
            let productGoodsAssembles = null;
            let productGoodsPositions = null;
            if (i.specifications && i.specifications.length > 0) {
              let productGoodsSpecifications =
                i.productGoodsSpecifications || [];
              if (productGoodsSpecifications.length > 0) {
                i.specifications.map((ele) => {
                  productGoodsSpecifications.map((item) => {
                    if (item.name === ele.label && item.value === ele.value) {
                      ele.type = item.type;
                    }
                  });
                });
              }
              specifications = i.specifications.map((k) => {
                return {
                  name: k.label,
                  value: k.value,
                  isMainAttribute: k._isMainAttribute ? 1 : 0,
                  type: k.type === 0 ? 0 : 1
                };
              });
            }
            if (v.productInfo.productType === 3) {
              let newList = (i.productGoodsAssembles || []).map((i) => {
                return {
                  materialProductGoodsId: i.productGoodsId,
                  quantity: i.quantity,
                  isMaster: i.isMaster
                };
              });
              productGoodsAssembles = v.uniqueFunc(newList, "materialProductGoodsId");
            }
            if (v.productInfo.productType === 4) {
              let ymsPlatformAccountId = i.ingredientsObj.ymsPlatformAccountId ? i.ingredientsObj.ymsPlatformAccountId : i.ymsPlatformAccountId;
              productGoodsAssembles = [
                {
                  factorySku: i.ingredientsObj ? i.ingredientsObj.sku : null,
                  ymsPlatformAccountId: ymsPlatformAccountId,
                  factoryProductGoodsId: i.ingredientsObj ? i.ingredientsObj.sku : null,
                  materialProductGoodsId: i.ingredientsObj ? i.ingredientsObj.sku : null,
                  workmanshipType: i.materialProcess,
                  quantity: 1,
                  isMaster: "1",
                  remark: ""
                }
              ];
              productGoodsPositions = i.partAndMeatSetting;
            }
            return {
              height: i.height,
              length: i.length,
              sortNo: index,
              productGoodsSpecifications: specifications,
              productId: i.productId || null,
              sku: i.sku,
              status: i.status,
              suggestPrice: i.supplyPrice,
              weight: i.weight,
              width: i.width,
              ymsSku: i.ymsSku,
              productGoodsId: i.productGoodsId,
              merchantId: obj.merchantId,
              availableNumber: i.availableNumber,
              isAssemble: i.isAssemble || 0,
              productGoodsAssembles: productGoodsAssembles,
              productGoodsPositions: productGoodsPositions
            };
          });
        } else {
          params.productGoodsList = obj.productGoodsList.map((i, index) => {
            let specifications = [];
            if (i.specifications && i.specifications.length > 0) {
              let productGoodsSpecifications =
                i.productGoodsSpecifications || [];
              if (productGoodsSpecifications.length > 0) {
                i.specifications.map((ele) => {
                  productGoodsSpecifications.map((item) => {
                    if (item.name === ele.label && item.value === ele.value) {
                      ele.type = item.type;
                    }
                  });
                });
              }
              specifications = i.specifications.map((k) => {
                return {
                  name: k.label,
                  value: k.value,
                  isMainAttribute: k._isMainAttribute ? 1 : 0,
                  type: k.type === 0 ? 0 : 1
                };
              });
            }
            i.sortNo = index;
            i.productGoodsSpecifications = specifications;
            i.productGoodsImages = null;
            let {originalSuggestPrice, ...talg} = i;
            return talg;
          });
        }
      } else {
        params.productGoodsList = [];
      }

      // 商品图片
      params.productColorImages = _goodsImage();
      // 尺码表
      params.insetProductSizeBoList = _sizeParams();
      // 供货价格
      params.productPriceSchemeBos = _schemeParams();
      return params;
    },
    // 保存 提交审批 操作类型 0 暂存 1 提交
    save(type) {
      let v = this;
      const {method, url} = _getRequestMethodAndUrl();
      let promiseList = [
        _formValidate(),
        _formNameValidate(),
        _productAttrValidate(),
        _specificationValidate(),
        _productGoodListValidate(),
        _schemeValidate()
      ];
      if (v.productInfo.productType === 4) {
        promiseList.push(_podProductGoodListValidate());
      }
      Promise.all(promiseList).then((result) => {
        let flag = v.productInfo.productType === 4 ? result[0] && result[1] && result[2] && result[3] && result[4] && result[6]
          : result[0] && result[1] && result[2] && result[3] && result[4];
        if (flag) {
          const params = v.handleParamsFun(type);
          if (v.derifyDeactivatedAttributeValues() && v.verifySpecificationAttributes() && v.attributeValueRatioValidate() && v.salesPriceConfigTalg) {
            v.axios[method](url, params, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then((response) => {
              if (response.data.code === 0) {
                v.productId = response.data.datas;
                v.adoptTalg = true;
                v.$Message.success(method === "post" ? alias47916751af154eb5b47cd5de9d34633d.t('key1000393') : alias47916751af154eb5b47cd5de9d34633d.t('key1000730'));
                if (this.isIframe) {
                  this.postMessage({type: "updateSuccess"});
                  return;
                }
                setTimeout(() => {
                  if (v.systemTalg) {
                    v.$router.push("/productCenter/productGoods");
                  } else {
                    window.location.href =
                      localStorage.getItem("originalPath");
                    window.location.reload();
                  }
                }, 800);
              } else {
                if (this.isIframe) {
                  this.postMessage({
                    type: "updateFail"
                  });
                }
              }
            });
          }
        } else {
          let text = v.nameTalg ? v.nameTalg : alias47916751af154eb5b47cd5de9d34633d.t('key1000731');
          if (v.nameTalg) {
            this.$Message.error(text);
          } else {
            this.$Message.info(text);
          }
          _scrollToTop();
        }
        const cubic = (value) => Math.pow(value, 3);
        const easeInOutCubic = (value) => value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;

        function _scrollToTop() {
          const beginTime = Date.now();
          const beginValue = document.documentElement.scrollTop || document.body.scrollTop;
          let endValue = document.querySelector(".ivu-form-item-error") ?
            document.querySelector(".ivu-form-item-error").getBoundingClientRect().top + beginValue : 0;
          endValue = endValue ? endValue - 50 : 0;
          const rAF = window.requestAnimationFrame || ((func) => setTimeout(func, 16));
          const frameFunc = () => {
            const progress = (Date.now() - beginTime) / 500;
            if (progress < 1) {
              document.documentElement.scrollTop = endValue * easeInOutCubic(progress) + beginValue * (1 - easeInOutCubic(progress));
              rAF(frameFunc);
            } else {
              document.documentElement.scrollTop = endValue;
            }
          };
          rAF(frameFunc);
        }
      });

      // 商品属性验证
      function _productAttrValidate() {
        return new Promise((resolve) => {
          let valid = true;
          if (!v.resetRequiredTalg) {
            v.ymsCategoryProductAttributeList.forEach((i, index) => {
              if (i.ymsProductAttributeTemplate.attributeRequire === 0) {
                if (i.propertyValues && i.propertyValues.length > 0) {
                  v.$set(v.ymsCategoryProductAttributeList[index], "attrRequireValid", true);
                } else {
                  v.$set(v.ymsCategoryProductAttributeList[index], "attrRequireValid", false);
                  valid = false;
                }
              } else {
                v.$set(v.ymsCategoryProductAttributeList[index], "attrRequireValid", true);
              }
              if (i.ymsProductAttributeTemplate.type === 0) {
                if (i.propertyValues) {
                  if (!v.isNumberOrDecimal(i.propertyValues)) {
                    valid = false;
                    v.nameTalg = alias47916751af154eb5b47cd5de9d34633d.t('key1000732');
                  }
                }
              }
            });
          }
          resolve(valid);
        });
      }

      // 名称
      function _formNameValidate() {
        let talg = true;
        v.nameTalg = "";
        if (v.productInfo["name_CN"]) {
          talg = true;
        } else {
          talg = false;
          v.nameTalg = alias47916751af154eb5b47cd5de9d34633d.t('key1000733');
        }
        return talg;
      }

      // 表单验证
      function _formValidate() {
        return new Promise((resolve) => {
          v.$refs.productInfo.validate((valid) => {
            resolve(valid);
          });
        });
      }

      // 阶梯价格
      function _schemeValidate() {
        return new Promise((resolve) => {
          let _noErr = true;
          v.productPriceSchemeBos.forEach((i, index) => {
            if (!i.startSalesVolume && index === 0) {
              _noErr = false;
              v.$set(v.productPriceSchemeBos[index], "_isErrByStart", true);
            }
            if (i.endSalesVolume <= i.startSalesVolume) {
              _noErr = false;
              v.$set(v.productPriceSchemeBos[index], "_isErr", true);
            }
            if (!i.schemeValue) {
              _noErr = false;
              v.$set(v.productPriceSchemeBos[index], "_isErrByPrice", true);
            }
          });
          resolve(_noErr);
        });
      }

      // 商品规格验证
      function _specificationValidate() {
        let valid = true;
        return new Promise((resolve) => {
          v.specification.forEach((i, index) => {
            if (i.specRequire) {
              let values = i.valueList.map((i) => i.value);
              if (values.length === 0) {
                valid = false;
                v.$set(v.specification[index], "specRequireValid", false);
              } else {
                if (i.isMainAttribute && i.valueList.some((k) => !k.imgList || k.imgList.length === 0)) {
                  valid = false;
                  v.$set(v.specification[index], "specRequireValid", false);
                } else {
                  v.$set(v.specification[index], "specRequireValid", true);
                }
              }
            }
          });
          resolve(valid);
        });
      }

      // 商品验证
      function _productGoodListValidate() {
        return new Promise((resolve) => {
          let valid;
          if (v.systemTalg) {
            if (v.resetRequiredTalg) {
              valid = v.productInfo.productGoodsList.every((i) => i.sku && !isValueEmpty(i.availableNumber));
            } else {
              valid = v.productInfo.productGoodsList.every((i) => i.sku && i.length && i.width && i.height && i.weight && i.supplyPrice && !isValueEmpty(i.availableNumber));
            }
          } else {
            if (v.resetRequiredTalg) {
              valid = v.productInfo.productGoodsList.every((i) => i.sku && !isValueEmpty(i.availableNumber) && !isStringValueEmpty(i.floatNum));
            } else {
              valid = v.productInfo.productGoodsList.every((i) => i.sku && i.length && i.width && i.height && i.weight && i.supplyPrice && !isValueEmpty(i.availableNumber) && !isStringValueEmpty(i.floatNum));
            }
          }
          resolve(valid);
        });
      }

      // pod 商品属性验证
      function _podProductGoodListValidate() {
        return new Promise((resolve) => {
          let valid;
          valid = v.productInfo.productGoodsList.every((i) => {
            if (v.systemTalg) {
              return (i?.ingredientsObj && i.partAndMeatSetting && i.partAndMeatSetting.length && i.materialProcess);
            } else {
              return i?.ingredientsObj && i.materialProcess;
            }
          });
          resolve(valid);
        });
      }

      // 判断接口
      function _getRequestMethodAndUrl() {
        return {
          url: v.operationType !== "edit" ? api.post_productInfo : api.put_productInfo,
          method: v.operationType !== "edit" ? "post" : "put"
        };
      }
    },
    // 校验属性值比例
    attributeValueRatioValidate() {
      let valid = true;
      let v = this;
      if (v.proportionList.length > 0) {
        let valueList = [];
        for (let j = 0; j < v.proportionList.length; j++) {
          let item = v.proportionList[j];
          if (item.proportionValueList.length > 0) {
            for (let i = 0; i < item.proportionValueList.length; i++) {
              let ele = item.proportionValueList[i];
              if (item.proportionValueList.length === 1) {
                ele.value = 100;
              }
              if (ele.value) {
                valueList.push(ele.value);
              } else {
                valid = false;
                v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000734'));
                return false;
              }
            }
          }
        }
        if (valueList.length > 0) {
          let reg = v.$regular.positiveInteger;
          for (let i = 0; i < valueList.length; i++) {
            let number = Number(valueList[i]);
            if (!reg.test(number)) {
              valid = false;
              v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000735'));
              return false;
            }
          }
        }
        let newList = v.proportionList.filter((item) => {
          return item.proportionValueList.length > 0;
        });
        if (newList.length > 0) {
          v.proportionList.map((item) => {
            item.totalProportion = item.proportionValueList.reduce(
              (total, ele) => {
                let value = Number(ele.value) || 0;
                return total + value;
              },
              0
            );
          });
          let talg = newList.every((item) => {
            return item.totalProportion === 100;
          });
          if (!talg) {
            valid = false;
            v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000736'));
            return false;
          }
        }
      }
      return valid;
    },
    // 校验规格属性是否有包含已经停用的属性值
    verifySpecificationAttributes() {
      let v = this;
      if (v.specification && v.specification.length > 0) {
        let newList = [];
        let talg = false;
        let abnormalTalg = false;
        v.specification.map((item) => {
          item.valueList.map((ele) => {
            newList.push(ele);
          });
        });
        let list = v.uniqueFunc(newList, "value");
        if (list.length > 0) {
          talg = list.some((ele) => ele.attributeTalg === true);
          abnormalTalg = list.some((ele) => ele.attributeValueExceptionTalg === true);
        } else {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000807'));
          return false;
        }
        if (talg) {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000737'));
          return false;
        } else if (abnormalTalg) {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000738'));
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    // 校验基础是否包含已经停用的属性值
    derifyDeactivatedAttributeValues() {
      let v = this;
      // 商品属性
      if (v.ymsCategoryProductAttributeList && v.ymsCategoryProductAttributeList.length > 0) {
        let list = v.ymsCategoryProductAttributeList.filter((item) => {
          return (
            item.disableText &&
            item.disableText !== "" &&
            item.ymsProductAttributeTemplate.attributeRequire === 0
          );
        });
        if (list.length > 0) {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000739'));
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 审核商品
    approvedByReviewBtn() {
      if (this.showProductSaleCountrySet) {
        this.$refs['sellingCountrySetting'].initSellingCountrySettingData('setting', this.productId);
      } else {
        this.approved();
      }
    },
    // 更新可售卖国
    updateDataFun() {
      this.approved();
    },
    // 审核通过方法
    approved() {
      this.axios.put(api.put_ymsProductInfoTemplate_approved + `${this.ymsProductInfoTemplateId}`, null,
        {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then((response) => {
        if (response.data.code === 0) {
          this.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000740'));
          this.$router.push("/approvalList");
        }
      });
    },
    // 规格属性(attributeType: 1 规格属性)
    specificationHandel(specificationData, _productInfo) {
      let v = this;
      v.specification = [];
      v.specificationValueList = [];
      let productGoodsList = _productInfo?.productGoodsList || [];
      let newList = [];
      let otherSpecification = []; // 其他规格属性
      let sizeSpecification = []; // 尺码规格属性
      if (specificationData && specificationData.ymsCategoryProductAttributeList) {
        if (specificationData.ymsCategoryProductAttributeList.length > 0) {
          newList = specificationData.ymsCategoryProductAttributeList.filter((item) => item.attributeType === 1);
          if (newList.length > 0) {
            // 处理规格属性没有主属性的情况下，默认以第一个规格属性作为主属性 (针对从ERP2.0同步拉取过来的商品处理，因为ERP2.0的商品规格没有主属性)
            let isSizeAttributeTalg = newList.some((ele) => {
              return ele.isMainAttribute === 1
            });
            if (!isSizeAttributeTalg) {
              newList[0].isMainAttribute = 1;
            }
            // 如果当前货品数据中的规格没有主属性，默认拿第一个货品的规格作为主属性 (针对从ERP2.0同步拉取过来的商品处理，因为ERP2.0的商品规格没有主属性)
            let productSpecificationAttributesList = [];
            let specificationAttributeList = [];
            let productGoodsSpecifications = [];
            if (productGoodsList.length > 0) {
              productGoodsSpecifications = productGoodsList[0].productGoodsSpecifications || [];
              productSpecificationAttributesList = productGoodsSpecifications.map((item) => item.name);
              specificationAttributeList = newList.map((item) => {
                let ymsProductAttributeTemplate = item.ymsProductAttributeTemplate;
                if (ymsProductAttributeTemplate) {
                  return ymsProductAttributeTemplate.cnName
                }
              });
            }
            if (productSpecificationAttributesList.length !== specificationAttributeList.length) {
              newList.map((item) => {
                let cnName = item.ymsProductAttributeTemplate ? item.ymsProductAttributeTemplate.cnName : null;
                item.isMainAttribute = 0;
                productGoodsSpecifications.map((talg) => {
                  if (cnName === talg.name) {
                    item.isMainAttribute = 1;
                  }
                });
              });
            }
            newList.map((item) => {
              let name = item.ymsProductAttributeTemplate.cnName;
              let isSizeAttribute = item.ymsProductAttributeTemplate.isSizeAttribute; // 是尺码属性：1
              let isMainAttribute = item.isMainAttribute; // 是主属性：1
              let attributeValueData = JSON.parse(JSON.stringify(item.ymsProductAttributeTemplate.productAttributeDictionaryBos)) || [];
              // 需要过滤掉已经停用的属性值
              let sortList = attributeValueData.filter((talg) => talg.status === 1);
              let newData = sortList.sort(handerDataSorting("sortNo", "asc"));
              let deactivationList = attributeValueData.filter((talg) => talg.status !== 1);
              // 规格属性必填
              let specRequire = item.ymsProductAttributeTemplate.attributeRequire === 0; // 属性填写要求，0：必填，1：非必填，2：推荐填写 默认 0
              // 尺码规格属性
              if (isSizeAttribute) {
                sizeSpecification.push({
                  label: name,
                  id: "_size",
                  isMainAttribute: isMainAttribute === 1, // 主属性
                  valueList: [],
                  specRequire: specRequire, // 必填
                  specRequireValid: specRequire, // 必填验证
                  isSizeAttribute: true, // 是尺码属性
                  isAllowCustomValue: item.ymsProductAttributeTemplate.isAllowCustomValue === "1",
                  list: _initSite(newData),
                  // 属性值已启用数据
                  attributeValueEnabledList: newData.map((ele) => {
                    return ele.cnValue;
                  }),
                  // 属性值已停用数据
                  attributeValueDeactivationData: deactivationList.map((ele) => {
                    return ele.cnValue;
                  }),
                  // 属性值数据
                  attributeValueData: attributeValueData.map((ele) => {
                    return ele.cnValue;
                  })
                });
              } else {
                otherSpecification.push({
                  label: name,
                  valueList: [],
                  isMainAttribute: isMainAttribute === 1, // 主属性
                  specRequire: specRequire, // 必填
                  specRequireValid: specRequire, // 必填验证
                  isAllowCustomValue: item.ymsProductAttributeTemplate.isAllowCustomValue === "1", // 是否可以增加自定义属性
                  list: newData.map((ele) => {
                    ele.value = ele.cnValue;
                    ele._show = true;
                    return ele;
                  }),
                  attributeValueEnabledList: newData.map((ele) => {
                    return ele.cnValue;
                  }),
                  // 属性值已停用数据
                  attributeValueDeactivationData: deactivationList.map((ele) => {
                      return ele.cnValue;
                    }
                  ),
                  attributeValueData: attributeValueData.map((ele) => {
                    return ele.cnValue;
                  })
                });
              }
              if (newData && newData.length > 0) {
                v.specificationValueList.push(...newData);
              }
            });
            // 主属性放前面
            let specificationList = [...otherSpecification, ...sizeSpecification];
            specificationList.map((item) => {
              if (item.isMainAttribute) {
                v.specification.unshift(item);
              } else {
                v.specification.push(item);
              }
            });
            v.specificationValueList = v.uniqueFunc(v.specificationValueList, "ymsProductAttributeDictionaryId");
          }
        }
      }

      function _initSite(data) {
        if (data && data.length > 0) {
          data.forEach((a, index) => {
            a.value = a.cnValue;
            a._show = true;
            a.sortNo = index;
          });
          v.sizeChartDefaultData = data;
          return data;
        } else {
          // 如果没有尺码 默认为均码
          return [
            {
              value: alias47916751af154eb5b47cd5de9d34633d.t('key1000741'),
              _show: true
            }
          ];
        }
      }
    },
    // 商品属性 (attributeType: 0 基础属性) attributeRequire
    attributeHandel(datas, toViewData) {
      let v = this;
      let associationPropertyData = [];
      if (datas && datas.ymsCategoryProductAttributeList) {
        v.classificationName = datas.usName;
        // 需要过滤掉已经停用的属性值选项
        let newList = datas.ymsCategoryProductAttributeList.filter((item) => item.attributeType === 0 && item.ymsProductAttributeTemplate.status === 1);
        newList.map((item) => {
          item.attrRequireValid = true;
          let productAttributeDictionaryBosData = item.ymsProductAttributeTemplate.productAttributeDictionaryBos || [];
          let attributeValueData = JSON.parse(JSON.stringify(productAttributeDictionaryBosData)) || [];
          // 取出所有基础属性中已经关联属性的数据
          productAttributeDictionaryBosData.map((ele) => {
            let ymsProductAttributeTemplates = ele.ymsProductAttributeTemplates || [];
            ymsProductAttributeTemplates.map((talg) => {
              let subProductAttributeDictionaryBos = talg.productAttributeDictionaryBos || [];
              let obj = {
                attrRequireValid: true,
                ymsProductAttributeTemplate: talg,
                ymsProductAttributeTemplateId: talg.ymsProductAttributeTemplateId,
                attributeValueData: subProductAttributeDictionaryBos.filter((j) => j.status === 1),
                associationProperty: subProductAttributeDictionaryBos.some((k) =>
                  k.ymsProductAttributeTemplates && k.ymsProductAttributeTemplates.length > 0)
              }
              if (talg.type === 0) {
                obj.unit = subProductAttributeDictionaryBos.length > 0 ? subProductAttributeDictionaryBos[0].ymsProductAttributeDictionaryId : null;
              }
              associationPropertyData.push(obj);
            });
          });
          item.associationProperty = productAttributeDictionaryBosData.some((ele) =>
            ele.ymsProductAttributeTemplates && ele.ymsProductAttributeTemplates.length > 0);
          item.ymsProductAttributeDictionaryIdList = [];
          if (item.associationProperty) {
            item.ymsProductAttributeDictionaryIdList = productAttributeDictionaryBosData.flatMap((p) =>
              (p.ymsProductAttributeTemplates || []).map((k) => k.ymsProductAttributeDictionaryId));
          }
          let sortList = attributeValueData.filter((talg) => talg.status === 1);
          let productAttributeDictionaryBosList = sortList.sort(handerDataSorting("sortNo", "asc"));
          item.ymsProductAttributeTemplate.productAttributeDictionaryBos = productAttributeDictionaryBosList;
          // 自定义---> 默认选中的单位
          if (item.ymsProductAttributeTemplate.type === 0) {
            item.unit = productAttributeDictionaryBosList[0].ymsProductAttributeDictionaryId;
          }
          item.attributeValueData = attributeValueData;
        });
        v.associationPropertyData = associationPropertyData; // v.uniqueFunc(associationPropertyData, 'ymsProductAttributeTemplateId');
        // 必填项放到最前面
        let list = newList.filter((item) => item.attributeType === 0);
        // 必填
        let requiredList = list.filter((item) => item.ymsProductAttributeTemplate.attributeRequire === 0);
        // 非必填、推荐填写
        let noRequiredList = list.filter((item) => item.ymsProductAttributeTemplate.attributeRequire !== 0);
        v.ymsCategoryProductAttributeList = [...requiredList, ...noRequiredList];
        /*console.log('==========ymsCategoryProductAttributeList=====', this.ymsCategoryProductAttributeList);
        console.log('==========associationPropertyData=====', this.associationPropertyData);*/
        // 默认展示前面15条数据 _hide
        v.ymsCategoryProductAttributeList.map((ele, index) => {
          ele._hide = index > 14;
        });
        // 判断当前多语种的英文标题是否为空，为空时，自动生成
        if (!v.productInfo["name_US"]) {
          v.automaticallyTitleChange(true, "US");
        }
        setTimeout(() => {
          v.automaticallyTitle = true;
        }, 4000);
      } else {
        v.ymsCategoryProductAttributeList = [];
      }
      // 详情赋值
      if (toViewData) {
        v._toViewAttr(toViewData);
      } else {
        this.changeInAttributeValues = true;
      }
    },
    // 商品基础属性的信息填充
    _toViewAttr(toViewData) {
      // 要做区分单选多选值的类型
      let v = this;

      function handleData(newList, toViewData) {
        newList.forEach((i) => {
          i.proportionList = [];
          if (toViewData && toViewData.length > 0) {
            toViewData.forEach((k) => {
              if (i.ymsProductAttributeTemplateId === k.ymsProductAttributeTemplateId) {
                let type = i.ymsProductAttributeTemplate.type; // 0 自定义    1 可选值
                i.disableText = "";
                let productAttributeDictionaryBos = i.ymsProductAttributeTemplate.productAttributeDictionaryBos || [];
                const valueArr = k.values || [];
                const valueList = [];
                valueArr.forEach((kItem) => {
                  const fItem = productAttributeDictionaryBos.find((fItem) => fItem.cnValue === kItem.value);
                  let obj = {...kItem};
                  if (fItem) {
                    obj.value = fItem.ymsProductAttributeDictionaryId;
                  }
                  valueList.push(obj);
                });
                // 自定义
                if (type === 0) {
                  if (valueArr.length > 0) {
                    i.unit = valueArr.length > 0 ? valueArr[0].unit : null;
                    i.propertyValues = valueArr[0].value;
                  }
                }
                // 可选值
                else {
                  if (i.ymsProductAttributeTemplate.chooseType !== 0) {
                    // 0多选   1单选
                    let list = valueList || [];
                    let obj = list.map((item) => {
                      return item.value;
                    });
                    //自定义值
                    /*let customValueList = list.filter((item) => {
                        return item.type === 0
                      });*/
                    i.propertyValues = obj ? obj[0] : null;
                    // 添加自定义属性 (单选的自定义属性值不应该有下拉选项值)
                    /*if (customValueList.length > 0) {
                        customValueList.map((ele) => {
                          productAttributeDictionaryBos.push({
                            ymsProductAttributeDictionaryId: ele.value,
                            cnValue: ele.value,
                            type: ele.type
                          });
                        })
                      }*/

                    // 判断是否有异常的属性值
                    if (i.attributeValueData.length > 0) {
                      let ymsProductAttributeDictionaryIdList = [];
                      i.attributeValueData.map((talg) => {
                        if (talg.ymsProductAttributeDictionaryId && talg.status === 1) {
                          ymsProductAttributeDictionaryIdList.push(talg.ymsProductAttributeDictionaryId);
                        }
                      });
                      if (ymsProductAttributeDictionaryIdList.length > 0) {
                        if (!ymsProductAttributeDictionaryIdList.includes(i.propertyValues) && i.propertyValues) {
                          if (i.ymsProductAttributeTemplate.isAllowCustomValue !== "1") {
                            i.disableText = alias47916751af154eb5b47cd5de9d34633d.t('key1000727');
                          }
                        }
                      }
                    }
                  } else {
                    let list = valueList || [];
                    let obj = list.map((item) => {
                      return item.value;
                    });
                    //自定义值
                    let customValueList = list.filter((item) => {
                      return item.type === 0;
                    });
                    i.propertyValues = obj || null;
                    if (!i.propertyValues) return;
                    // 判断是否有异常的属性值
                    if (i.attributeValueData.length > 0) {
                      let ymsProductAttributeDictionaryIdList = [];
                      i.attributeValueData.map((talg) => {
                        if (talg.ymsProductAttributeDictionaryId && talg.status === 1) {
                          ymsProductAttributeDictionaryIdList.push(
                            talg.ymsProductAttributeDictionaryId
                          );
                        }
                      });
                      if (ymsProductAttributeDictionaryIdList.length > 0) {
                        if (i.propertyValues) {
                          i.propertyValues.map((ele) => {
                            if (!ymsProductAttributeDictionaryIdList.includes(ele)) {
                              if (i.ymsProductAttributeTemplate.isAllowCustomValue !== "1") {
                                i.disableText = alias47916751af154eb5b47cd5de9d34633d.t('key1000727');
                              }
                            }
                          });
                        }
                      }
                    }
                    // 添加自定义属性  数组
                    if (customValueList.length > 0) {
                      customValueList.map((ele) => {
                        productAttributeDictionaryBos.push({
                          ymsProductAttributeDictionaryId: ele.value,
                          cnValue: ele.value,
                          type: ele.type
                        });
                      });
                    }
                    // 属性值比例
                    let proportionValueList = [];
                    productAttributeDictionaryBos.map((talg) => {
                      list.map((k) => {
                        if (k.value === talg.ymsProductAttributeDictionaryId) {
                          let val = (k.proportion * 100).toFixed(2);
                          if (val.endsWith(".00")) {
                            val = val.slice(0, -3);
                          }
                          proportionValueList.push({
                            name: talg.cnValue,
                            value: val
                          });
                        }
                      });
                    });
                    let vals = {
                      ymsProductAttributeTemplateId:
                      i.ymsProductAttributeTemplateId,
                      title: i.ymsProductAttributeTemplate.cnName,
                      proportionValueList: proportionValueList
                    };
                    if (proportionValueList.length > 0) {
                      i.proportionList.push(vals);
                    }
                  }
                }
              }
            });
          }
        });
        return newList;
      }

      let newList = handleData(v.ymsCategoryProductAttributeList, toViewData);
      v.associationPropertyData = handleData(v.associationPropertyData, toViewData);
      // 处理关联属性
      newList.forEach((i, attrIndex) => {
        let list;
        let value = i.propertyValues || null;
        if (Array.isArray(value)) {
          list = v.associationPropertyData.filter((item) => value.includes(item.ymsProductAttributeTemplate.ymsProductAttributeDictionaryId));
        } else {
          list = v.associationPropertyData.filter((item) => item.ymsProductAttributeTemplate.ymsProductAttributeDictionaryId === value);
        }
        if (list.length > 0) {
          newList.splice(attrIndex + 1, 0, ...list);
        }
      });
      v.ymsCategoryProductAttributeList = v.uniqueFunc(newList, 'ymsProductAttributeTemplateId');

      v.changeInAttributeValues = false;
      setTimeout(() => {
        v.changeInAttributeValues = true;
      }, 3000);
    },

    /**
     * 显示隐藏属性
     * @date: 2022/4/19 16:43
     * @param open Bool true展开 false 隐藏
     */
    visibleAttribute(open) {
      this.ymsCategoryProductAttributeList.forEach((a, idx) => {
        if (open) {
          a._hide = !open;
        } else {
          if (idx <= 14) {
            a._hide = open;
          } else {
            a._hide = !open;
          }
        }
      });
      this.isShowAttrMore = !open;
    },
    attrPlaceholder({chooseType, type, isAllowCustomValue}) {
      let isSingle = chooseType === 1 ? alias47916751af154eb5b47cd5de9d34633d.t('key1000329') : alias47916751af154eb5b47cd5de9d34633d.t('key1000330');
      let isCustom =
        type === 0 || isAllowCustomValue === "1" ? alias47916751af154eb5b47cd5de9d34633d.t('key1000742') : "";
      return isSingle + isCustom;
    },

    // 获取基础属性的数据
    getItemAttributesData(ymsProductCategoryId) {
      let v = this;
      let url =
        v.system === "yms" ? api.get_ymsProductCategory_detailForAll : api.get_ymsProductCategory_api_detailForAll;
      return new Promise((resolve, reject) => {
        if (!ymsProductCategoryId) {
          reject();
          return;
        }
        v.axios.get(url + `${ymsProductCategoryId}`).then((response) => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              if (isJSON(data.name)) {
                let obj = JSON.parse(data.name);
                data.usName = obj["US"] || "";
              }
              data.name = v.handleMultilingualFields(data.name);
              resolve(data);
            }
          }
        });
      });
    },
    tableSpanProductGood({row, column}) {
      let text = column.field;
      if (text && text.includes("isMainAttribute_") && row.specifications[0]._isMainAttribute) {
        if (!row.specifications[0].hideRowspan) {
          return {
            rowspan: row.specifications[0].rowspan[row.specifications[0].value] ?
              row.specifications[0].rowspan[row.specifications[0].value].rowspan : 0,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // 获取当前规格明细列表真实的索引值
    getCurrentIndex(key) {
      return this.productDataSpan.findIndex((item) => item.rowKey === key);
    },

    // 设置商品表格头
    setProductGoodColumn() {
      let v = this;
      return new Promise((resolve) => {
        v.productColumns = []; // 先清空
        let productColumn = [
          {
            title: "SKU",
            key: "sku",
            field: "sku",
            align: "center",
            width: 210,
            renderHeaderCell: ({column}, h) => {
              return h("div", {
                  style: {
                    display: "flex",
                    alignItems: "center"
                  }
                },
                [
                  h("span", {class: "required"}, column.title),
                  v.operationType === "look" || h("Checkbox", {
                    style: {
                      marginLeft: "12px",
                      display: (() => {
                        return v.isDisabled || !v.systemTalg ? "none" : "inline-block";
                      })()
                    },
                    props: {
                      value: column.checkIdentical
                    },
                    on: {
                      "on-change": (value) => {
                        if (value) {
                          v.generateSku();
                        }
                      }
                    }
                  }, alias47916751af154eb5b47cd5de9d34633d.t('key1000743')),
                  v.operationType === "look" ||
                  h("Tooltip", {
                      props: {
                        transfer: true,
                        maxWidth: 250,
                        content: alias47916751af154eb5b47cd5de9d34633d.t('key1000744')
                      },
                      style: {
                        marginLeft: "5px"
                      }
                    }, [
                      h("Icon", {
                        props: {
                          type: "md-help-circle",
                          size: 22
                        },
                        style: {
                          cursor: "pointer",
                          color: "#2D8CF0"
                        }
                      })
                    ]
                  )
                ]
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let sku = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].sku : "";
              if (v.productId !== null && currentIndex === 0) {
                return h("div", [
                  h("Input", {
                    class: !sku ? "ivu-form-item-error" : "",
                    props: {
                      placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000292') + "SKU",
                      maxlength: 100,
                      disabled: v.isDisabled,
                      value: sku
                    },
                    on: {
                      input: (val) => {
                        v.productInfo.productGoodsList[currentIndex].sku = v.trim(val);
                      }
                    }
                  }),
                  !sku ? h("div", {
                    style: {
                      color: "red",
                      margin: "5px 0",
                      textAlign: "center"
                    }
                  }, "SKU" + alias47916751af154eb5b47cd5de9d34633d.t('key1000569')) : ""
                ]);
              }
              return h("div", [
                h("Input", {
                  class: !sku ? "ivu-form-item-error" : "",
                  props: {
                    placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000292') + "SKU",
                    maxlength: 100,
                    disabled: v.isDisabled,
                    value: sku || ""
                  },
                  on: {
                    input: (val) => {
                      v.productInfo.productGoodsList[currentIndex].sku = v.trim(val);
                    }
                  }
                }),
                !sku ? h("div", {
                  style: {
                    color: "red",
                    margin: "5px 0",
                    textAlign: "center"
                  }
                }, "SKU" + alias47916751af154eb5b47cd5de9d34633d.t('key1000569')) : ""
              ]);
            }
          },
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000745'),
            key: "packagingSize",
            field: "packagingSize",
            align: "center",
            width: 350,
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000745'),
                v.productInfo.productGoodsList,
                ["length", "width", "height"],
                v.operationType === "look",
                {
                  required: !v.resetRequiredTalg,
                  tipContent:
                    alias47916751af154eb5b47cd5de9d34633d.t('key1000746')
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let ingredientsObj = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj;
              let length = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].length : null;
              let width = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].width : null;
              let height = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].height : null;
              return h("div", [
                ingredientsObj && v.systemTalg ? h("div",
                  {
                    style: {
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: "3px",
                      display: 'flex',
                      alignItems: 'center'
                    }
                  },
                  [
                    h('span', alias47916751af154eb5b47cd5de9d34633d.t('key1000747') + ingredientsObj?.length + "、" + ingredientsObj?.width + "、" + ingredientsObj?.height),
                    h('span', {
                      class: 'themeColor cursor ml10 font-size-12',
                      on: {
                        click() {
                          length = ingredientsObj?.length;
                          width = ingredientsObj?.width;
                          height = ingredientsObj?.height;
                          if (v.productInfo.productGoodsList[currentIndex]) {
                            if (length && width && height) {
                              v.productInfo.productGoodsList[currentIndex].length = length;
                              v.productInfo.productGoodsList[currentIndex].width = width;
                              v.productInfo.productGoodsList[currentIndex].height = height;
                            } else {
                              v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000808'));
                              return false;
                            }
                          }
                        }
                      }
                    }, alias47916751af154eb5b47cd5de9d34633d.t('key1000809'))
                  ]
                ) : "",
                h("div", {
                    style: {
                      display: "flex",
                      justifyContent: "center"
                    }
                  },
                  [
                    h("InputNumber", {
                      class: !length && !v.resetRequiredTalg ? "ivu-form-item-error" : "",
                      style: {
                        width: "100px",
                        display: "flex"
                      },
                      props: {
                        min: 0,
                        max: 9999999,
                        disabled: v.isDisabled,
                        value: length || null,
                        placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000748')
                      },
                      on: {
                        input: (val) => {
                          v.productInfo.productGoodsList[currentIndex].length = val;
                        }
                      }
                    }),
                    h("InputNumber", {
                      style: {
                        width: "100px",
                        marginLeft: "10px",
                        display: "flex"
                      },
                      class: !width && !v.resetRequiredTalg ? "ivu-form-item-error" : "",
                      props: {
                        min: 0,
                        max: 9999999,
                        disabled: v.isDisabled,
                        value: width || null,
                        placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000749')
                      },
                      on: {
                        input: (val) => {
                          v.productInfo.productGoodsList[currentIndex].width = val;
                        }
                      }
                    }),
                    h("InputNumber", {
                      style: {
                        width: "100px",
                        marginLeft: "10px",
                        display: "flex"
                      },
                      class: !height && !v.resetRequiredTalg ? "ivu-form-item-error" : "",
                      props: {
                        min: 0,
                        max: 9999999,
                        disabled: v.isDisabled,
                        value: height || null,
                        placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000750')
                      },
                      on: {
                        input: (val) => {
                          v.productInfo.productGoodsList[currentIndex].height = val;
                        }
                      }
                    })
                  ]
                ),
                (!length || !width || !height) && !v.resetRequiredTalg ? h("div", {
                  style: {
                    color: "red",
                    margin: "5px 0",
                    textAlign: "center"
                  }
                }, alias47916751af154eb5b47cd5de9d34633d.t('key1000751') + alias47916751af154eb5b47cd5de9d34633d.t('key1000569')) : ""
              ]);
            }
          },
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000752'),
            key: "weight",
            align: "center",
            width: 200,
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000753'),
                v.productInfo.productGoodsList,
                "weight",
                v.operationType === "look",
                {
                  required: !v.resetRequiredTalg,
                  tipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1000754')
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let ingredientsObj = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj;
              let weight = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].weight : null;
              return h("div", {
                  class: "flex justify-content-center flex-direction align-items-center"
                },
                [
                  ingredientsObj && v.systemTalg ? h("div",
                    {
                      style: {
                        textAlign: "center",
                        fontSize: "12px",
                        marginBottom: "3px",
                        display: 'flex',
                        alignItems: 'center'
                      }
                    },
                    [
                      h('span', alias47916751af154eb5b47cd5de9d34633d.t('key1000755') + ingredientsObj?.weight),
                      h('span', {
                        class: 'themeColor cursor ml10 font-size-12',
                        on: {
                          click() {
                            weight = ingredientsObj?.weight;
                            if (v.productInfo.productGoodsList[currentIndex]) {
                              if (weight) {
                                v.productInfo.productGoodsList[currentIndex].weight = weight;
                              } else {
                                v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000808'));
                                return false;
                              }
                            }
                          }
                        }
                      }, alias47916751af154eb5b47cd5de9d34633d.t('key1000809'))
                    ]) : "",
                  h("InputNumber", {
                    props: {
                      min: 0,
                      max: 9999999,
                      precision: 0,
                      disabled: v.isDisabled,
                      value: weight || null
                    },
                    class: !weight && !v.resetRequiredTalg ? "ivu-form-item-error" : "",
                    style: {
                      width: "110px",
                      display: "flex"
                    },
                    on: {
                      input: (val) => {
                        v.productInfo.productGoodsList[currentIndex].weight = val;
                      }
                    }
                  }),
                  !weight && !v.resetRequiredTalg ? h("div", {
                    style: {
                      color: "red",
                      margin: "5px 0",
                      textAlign: "center"
                    }
                  }, alias47916751af154eb5b47cd5de9d34633d.t('key1000752') + alias47916751af154eb5b47cd5de9d34633d.t('key1000569')) : ""
                ]
              );
            }
          },
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000756'),
            key: "supplyPrice",
            field: "supplyPrice",
            align: "center",
            width: 250,
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000757') + v.currency + "）",
                v.productInfo.productGoodsList,
                "supplyPrice",
                v.operationType === "look",
                {
                  required: !v.resetRequiredTalg,
                  tipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1000758')
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let value = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].supplyPrice : null;
              return h("div", {
                  class: "flex justify-content-center flex-direction align-items-center"
                },
                [
                  h("InputNumber", {
                    props: {
                      min: 0,
                      max: 9999999,
                      disabled: v.isDisabled,
                      value: value,
                      formatter: function (val) {
                        return formatterNumber(val, 2);
                      }
                    },
                    style: {width: "110px", display: "flex"},
                    class: !value && !v.resetRequiredTalg ? "ivu-form-item-error" : "",
                    on: {
                      input: (val) => {
                        v.productInfo.productGoodsList[currentIndex].supplyPrice = val;
                        v.setDiscountPrice(true);
                        v.$forceUpdate();
                      }
                    }
                  }),
                  !value && !v.resetRequiredTalg ? h("div", {
                    style: {
                      color: "red",
                      margin: "5px 0",
                      textAlign: "center"
                    }
                  }, alias47916751af154eb5b47cd5de9d34633d.t('key1000499') + alias47916751af154eb5b47cd5de9d34633d.t('key1000569')) : ""
                ]
              );
            }
          },
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000759'),
            key: "availableNumber",
            field: "availableNumber",
            width: 180,
            align: "center",
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000759'),
                v.productInfo.productGoodsList,
                "availableNumber",
                v.operationType === "look",
                {
                  required: true,
                  tipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1000760')
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let value = null;
              let talg = false;
              if (v.productInfo.productGoodsList[currentIndex]) {
                if (!isValueEmpty(v.productInfo.productGoodsList[currentIndex].availableNumber)) {
                  value = v.productInfo.productGoodsList[currentIndex].availableNumber;
                } else {
                  value = null;
                  talg = true;
                }
              }
              return h("div", {
                  class: "flex justify-content-center flex-direction align-items-center"
                },
                [
                  h("InputNumber", {
                    props: {
                      value: value,
                      min: 0,
                      max: 99999999,
                      disabled: v.isDisabled,
                      formatter: (value) => `${parseInt(value)}`
                    },
                    style: {
                      width: "100px",
                      textAlign: "left",
                      display: "flex"
                    },
                    class: talg ? "ivu-form-item-error" : "",
                    on: {
                      "on-change": (num) => {
                        v.productInfo.productGoodsList[currentIndex].availableNumber = parseInt(num);
                        v.$forceUpdate();
                      }
                    }
                  }),
                  talg ? h("div", {
                    style: {
                      color: "red",
                      margin: "5px 0",
                      textAlign: "center"
                    }
                  }, alias47916751af154eb5b47cd5de9d34633d.t('key1000759') + alias47916751af154eb5b47cd5de9d34633d.t('key1000569')) : ""
                ]
              );
            }
          }
        ];
        let commonColumns = [
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000761'),
            key: "isAssemble",
            field: "isAssemble",
            width: 140,
            align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let isAssemble = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].isAssemble : 0;
              return h("i-switch", {
                props: {
                  value: isAssemble,
                  disabled: v.isDisabled || v.productInfo.productType !== 3,
                  trueValue: 1,
                  falseValue: 0
                },
                on: {
                  "on-change": (val) => {
                    v.productInfo.productGoodsList[currentIndex].isAssemble = val;
                  }
                }
              });
            }
          },
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000663'),
            width: 140,
            align: "center",
            key: "assembleInfo",
            field: "assembleInfo",
            renderBodyCell: ({row, column, rowIndex}, h) => {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let showTalg = (v.productInfo.productGoodsList[currentIndex] &&
                v.productInfo.productGoodsList[currentIndex].productGoodsAssembles &&
                v.productInfo.productGoodsList[currentIndex].productGoodsAssembles.length > 0) || v.assemblyInfoTalg;
              let talg = v.productInfo.productGoodsList[currentIndex] && v.productInfo.productGoodsList[currentIndex].isAssemble === 1;
              return talg ? h("div", {class: "flex align-items-center"}, [
                showTalg ? h("Icon", {
                    props: {type: "md-checkmark", size: 17}
                  })
                  : h("Icon", {
                    props: {type: "md-close", size: 17}
                  }),
                h("Button", {
                  props: {
                    disabled: v.productInfo.productType !== 3,
                    type: "text"
                  },
                  class: "themeColor ml5 resetBtn font-size-14",
                  on: {
                    click() {
                      v.showAddProductModal(v.productInfo.productGoodsList[currentIndex], currentIndex);
                    }
                  }
                }, alias47916751af154eb5b47cd5de9d34633d.t('key1000685'))
              ]) : h("span", "-");
            }
          }
        ];
        let podColumns = [
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000762'),
            key: "ingredientsSkuInfo",
            field: "ingredientsSkuInfo",
            width: 240,
            align: "center",
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000762'),
                v.productInfo.productGoodsList,
                "ingredientsSkuInfo",
                true,
                {
                  required: true,
                  tipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1000763')
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let path = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.imagePath || null;
              let talg = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj ? false : true;
              let productGoodsSpecifications = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.productGoodsSpecifications || [];
              let dom = h("div", {
                  class: "flex justify-content-center align-items-center"
                },
                [
                  h("img", {
                    attrs: {
                      src: path,
                      onerror: "javascript:this.src='./static/images/placeholder.jpg'"
                    },
                    style: {
                      width: "64px",
                      height: "64px",
                      objectFit: "cover",
                      verticalAlign: "middle",
                      padding: "3px"
                    },
                    on: {}
                  }),
                  h("div",
                    {class: "sku_info_con"},
                    [
                      h("span", v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.cnName),
                      h("div", [
                        h("span", alias47916751af154eb5b47cd5de9d34633d.t('key1000316')),
                        h("span", v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.spu)
                      ]),
                      h("div", [
                        h("span", alias47916751af154eb5b47cd5de9d34633d.t('key1000317')),
                        h("span", v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.sku)
                      ]),
                      h("div", {class: "sku_info_con_edit blueColor"}, [
                        h("span", productGoodsSpecifications.map((item) => {
                          return item.value;
                        }).join(" • ")),
                        v.systemTalg && v.operationType !== "look" ? h("Icon", {
                          props: {
                            type: "md-create"
                          },
                          style: {
                            fontSize: "12px",
                            cursor: "pointer"
                          },
                          on: {
                            click: () => {
                              v.ingredientCurrentIndex =
                                currentIndex;
                              v.openChoseIngredients("sku", true);
                            }
                          }
                        }) : ""
                      ])
                    ])
                ]);
              if (v.systemTalg) {
                if (talg) {
                  return h("div", {class: "flex justify-content-center flex-direction align-items-center ivu-form-item-error"},
                    [
                      h("Button",
                        {
                          props: {
                            size: "small"
                          },
                          style: {
                            padding: "4px 14px",
                            height: "32px"
                          },
                          on: {
                            click: () => {
                              v.ingredientCurrentIndex = currentIndex;
                              v.openChoseIngredients("sku");
                            }
                          }
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000764')),
                      h("div",
                        {
                          style: {
                            color: "red",
                            margin: "5px 0",
                            textAlign: "center"
                          }
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000763'))
                    ])
                } else {
                  return dom;
                }
              } else {
                if (!talg) {
                  return dom;
                }
              }
            }
          },
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000765'),
            key: "materialProcess",
            field: "materialProcess",
            width: 200,
            align: "center",
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000765'),
                v.productInfo.productGoodsList,
                "materialProcess",
                true,
                {
                  required: true
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let talg = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj ? false : true;
              let options = v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.technologyTypeList || [];
              let value = v.productInfo.productGoodsList[currentIndex]?.materialProcess || null;
              if (options.length > 0) {
                if (!value) {
                  value = options[0].technologyType;
                  if (v.productInfo.productGoodsList[currentIndex]) {
                    v.productInfo.productGoodsList[currentIndex].materialProcess = value;
                  }
                }
              }
              let isSelect = v.productInfo.productGoodsList[currentIndex]?.materialProcess ? true : false;
              return h("div",
                {class: "flex justify-content-center flex-direction align-items-center cellNoPre"}, [
                  talg ? !v.systemTalg ? "" : h("div",
                      {class: "flex justify-content-center flex-direction align-items-center"},
                      [
                        h("div", {
                          style: {
                            padding: "0px 14px",
                            color: "#fff",
                            lineHeight: "32px",
                            height: "32px",
                            fontSize: "12px",
                            textAlign: "center",
                            backgroundColor: "#c5c8ce"
                          }
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000766'))
                      ]) :
                    h("div", {class: "flex justify-content-center flex-direction align-items-center"}, [
                        h("Select", {
                            attrs: {
                              disabled: !v.systemTalg,
                              placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000767')
                            },
                            props: {
                              value,
                              transfer: true
                            },
                            style: {
                              width: "180px"
                            },
                            class: !isSelect ? "ivu-select-selection-error ivu-form-item-error" : "",
                            on: {
                              "on-change"(val) {
                                v.$set(v.productInfo.productGoodsList[currentIndex], "materialProcess", val);
                                v.$forceUpdate();
                              }
                            }
                          },
                          options.map((i) => {
                            return h("Option", {
                              props: {
                                value: i.technologyType
                              }
                            }, i.technologyName);
                          })
                        ),
                        !isSelect ? h("div", {
                          style: {
                            color: "red",
                            margin: "5px 0",
                            textAlign: "center"
                          }
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000768')) : ""
                      ]
                    )]
              );
            }
          }
        ];
        let supplierColumns = [
          {
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000769'),
            key: "partAndMeatSetting",
            field: "partAndMeatSetting",
            width: 200,
            align: "center",
            renderHeaderCell: ({column}, h) => {
              return v.checkIdenticalDon(
                h,
                column,
                alias47916751af154eb5b47cd5de9d34633d.t('key1000769'),
                v.productInfo.productGoodsList,
                "partAndMeatSetting",
                v.operationType === "look",
                {
                  required: true,
                  tipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1000770')
                }
              );
            },
            renderBodyCell({row, column, rowIndex}, h) {
              let currentIndex = v.getCurrentIndex(row.rowKey);
              let talg = null;
              let isSetting = null;
              if (v.productInfo.productGoodsList[currentIndex]) {
                talg = !v.productInfo.productGoodsList[currentIndex].ingredientsObj;
                let partAndMeatSetting = v.productInfo.productGoodsList[currentIndex].partAndMeatSetting || [];
                isSetting = partAndMeatSetting.length > 0
              }
              return h("div", {class: "flex justify-content-center flex-direction align-items-center"},
                [
                  talg ? h("div", {class: "flex justify-content-center flex-direction align-items-center"},
                      [
                        h("div",
                          {
                            style: {
                              padding: "0px 14px",
                              color: "#fff",
                              lineHeight: "32px",
                              height: "32px",
                              fontSize: "12px",
                              textAlign: "center",
                              backgroundColor: "#c5c8ce"
                            }
                          }, alias47916751af154eb5b47cd5de9d34633d.t('key1000766'))
                      ])
                    : h("div", {class: "flex justify-content-center flex-direction align-items-center"}, [
                        h("div", {class: "flex justify-content-center align-items-center"},
                          [
                            v.operationType === "look" ? ""
                              : h("Icon", {
                                props: {
                                  type: isSetting ? "md-checkmark-circle" : "md-alert",
                                  color: isSetting ? "#19be6b" : "#e96900",
                                  size: 22
                                }
                              }),
                            h("span", {
                                style: {
                                  color: "#2d8cf0",
                                  fontSize: "16px",
                                  marginLeft: "5px",
                                  display: "line-block",
                                  cursor: "pointer",
                                  textAlign: "center"
                                },
                                on: {
                                  click: () => {
                                    v.partAndMeatSettingIndex = currentIndex;
                                    v.openChosePartAndSource(v.productInfo.productGoodsList[currentIndex]?.ingredientsObj?.productGoodsExtendList,
                                      v.productInfo.productGoodsList[currentIndex]?.partAndMeatSetting);
                                  }
                                }
                              },
                              v.operationType === "look" ? alias47916751af154eb5b47cd5de9d34633d.t('key1000338') : alias47916751af154eb5b47cd5de9d34633d.t('key1000558')
                            )
                          ]
                        ),
                        isSetting ? "" : h("div", {
                          style: {
                            color: "red",
                            margin: "5px 0",
                            textAlign: "center"
                          },
                          class: isSetting ? "" : "ivu-form-item-error"
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000771'))
                      ]
                    )
                ]
              );
            }
          }
        ];
        if (v.productInfo.productType === 4) {
          productColumn = v.systemTalg ? productColumn.concat(...podColumns, ...supplierColumns) : productColumn.concat(...podColumns);
        } else {
          productColumn = productColumn.concat(...commonColumns);
        }
        _setColumnImg(); // 设置预览图
        _setColumnActivityPrice(); // 设置活动价
        _setColumnSalesConfiguration(); // 设置销售价配置
        _setDynamicSpec(); // 设置动态规格

        function _setDynamicSpec() {
          JSON.parse(JSON.stringify(v.specification)).reverse().forEach((i, index) => {
            if (i.valueList && i.valueList.length) {
              let key = i.isMainAttribute ? "specification_isMainAttribute_" + index : "specification_" + index;
              productColumn.unshift({
                title: i.label,
                align: "center",
                width: 110,
                key: key,
                field: key,
                fixed: "left",
                renderBodyCell({row, column, rowIndex}, h) {
                  let specifications = row.specifications || [];
                  let arr = specifications.filter((s) => s.label === i.label);
                  if (arr && arr.length) {
                    let text = arr[0].value;
                    let talgText = "";
                    specifications.map((item) => {
                      if (item.label === i.label) {
                        i.valueList.map((ele) => {
                          if (ele.value === item.value) {
                            if (ele.attributeTalg) {
                              talgText = alias47916751af154eb5b47cd5de9d34633d.t('key1000772');
                            }
                          }
                        });
                      }
                    });
                    if (text) {
                      if (talgText) {
                        return h("div", {class: "flex align-items-center flex-direction justify-content-center"}, [
                          h("p", {class: "redColor"}, talgText),
                          h("p", text)
                        ]);
                      } else {
                        return h("span", text);
                      }
                    }
                  }
                }
              });
            }
          });
        }

        function _setColumnImg() {
          if (v.operationType !== "add") {
            let imgUrl = null;
            productColumn.splice(1, 0, {
              title: alias47916751af154eb5b47cd5de9d34633d.t('key1000773'),
              key: "previewImage",
              field: "previewImage",
              width: 120,
              align: "center",
              renderBodyCell({row, column, rowIndex}, h) {
                let currentIndex = v.getCurrentIndex(row.rowKey);
                let specifications = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].specifications : [];
                let imgPath = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].path || "" : null;
                let list = v.specification.filter((item) => {
                  return item.isMainAttribute;
                });
                if (specifications.length > 0) {
                  specifications.map((item) => {
                    if (item._isMainAttribute) {
                      if (list.length > 0) {
                        let valueList = list[0].valueList;
                        valueList.map((talg) => {
                          if (item.value === talg.value) {
                            imgUrl = talg.imgList.length > 0 ? talg.imgList[0] : null;
                          }
                        });
                      }
                    }
                  });
                }
                let path = imgUrl || imgPath;
                return h("img", {
                  attrs: {
                    src: v.setImgPath(path, null, "70x70", true),
                    onerror: "javascript:this.src='./static/images/placeholder.jpg'"
                  },
                  style: {
                    width: "64px",
                    height: "64px",
                    objectFit: "cover",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    padding: "3px"
                  },
                  on: {}
                });
              }
            });
          }
        }

        function _setColumnActivityPrice() {
          if (v.operationType !== "add" && v.productInfo.productType !== 4) {
            let obj = {
              title: alias47916751af154eb5b47cd5de9d34633d.t('key1000774'),
              key: "activityPriceValue",
              field: "activityPriceValue",
              width: 150,
              align: "center",
              renderHeaderCell: ({column}, h) => {
                return h("span", alias47916751af154eb5b47cd5de9d34633d.t('key1000775') + v.currency + "）");
              },
              renderBodyCell({row, column, rowIndex}, h) {
                let currentIndex = v.getCurrentIndex(row.rowKey);
                let activityPriceValue = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].activityPriceValue : null;
                let text = "-";
                if (!isValueEmpty(activityPriceValue)) {
                  text = activityPriceValue;
                }
                return h("span", text);
              }
            };
            productColumn.splice(4, 0, obj);
          }
        }

        function _setColumnSalesConfiguration() {
          if (v.pageType === "yms") {
            let talg =
              v.operationType === "edit" &&
              v.getPermission("salesPrice_configuration");
            let list = [
              {
                title: alias47916751af154eb5b47cd5de9d34633d.t('key1000776'),
                key: "salesConfiguration",
                field: "salesConfiguration",
                width: 300,
                align: "center",
                renderHeaderCell: ({column}, h) => {
                  return h("div", {}, [
                    h("span", {class: !v.resetRequiredTalg ? "required" : ""}, alias47916751af154eb5b47cd5de9d34633d.t('key1000776')),
                    h("Checkbox", {
                      style: {
                        marginLeft: "12px",
                        display: (() => {
                          return talg ? "inline-block" : "none";
                        })()
                      },
                      props: {
                        value: column.checkIdentical
                      },
                      on: {
                        "on-change": (value) => {
                          v.setSalesConfiguration(value);
                        }
                      }
                    }, alias47916751af154eb5b47cd5de9d34633d.t('key1000160'))
                  ]);
                },
                renderBodyCell({row, column, rowIndex}, h) {
                  let currentIndex = v.getCurrentIndex(row.rowKey);
                  let salePriceType = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].salePriceType : 1;
                  let requiredText = "";
                  handlerRequired();

                  // 校验数值
                  function handlerRequired() {
                    let text = "";
                    let obj = v.productInfo.productGoodsList[currentIndex];
                    if (isStringValueEmpty(obj.floatNum)) {
                      if (!v.resetRequiredTalg) {
                        text = salePriceType === 1 ? alias47916751af154eb5b47cd5de9d34633d.t('key1000777') : alias47916751af154eb5b47cd5de9d34633d.t('key1000778');
                      }
                    } else {
                      let reg = v.$regular.decimal;
                      if (!reg.test(Number(obj.floatNum))) {
                        text = alias47916751af154eb5b47cd5de9d34633d.t('key1000779');
                      } else {
                        let floatNum = Number(obj.floatNum);
                        // 校验只能输入四位小数点
                        const regex = v.$regular.decimalPlaces;
                        if (regex.test(floatNum)) {
                          if (obj.floatType === 4) {
                            let supplyPrice = Number(obj.supplyPrice);
                            if (floatNum >= supplyPrice) {
                              text = alias47916751af154eb5b47cd5de9d34633d.t('key1000780');
                            }
                          }
                          if (obj.floatType === 2) {
                            if (floatNum >= 100) {
                              text = alias47916751af154eb5b47cd5de9d34633d.t('key1000781');
                            }
                          }
                        } else {
                          text = alias47916751af154eb5b47cd5de9d34633d.t('key1000782');
                        }
                      }
                    }
                    if (!text) {
                      handleSalesPrice();
                    } else {
                      v.salesPriceConfigTalg = false;
                    }
                    requiredText = text;
                  }

                  // 根据配置计算销售价
                  function handleSalesPrice() {
                    v.salesPriceConfigTalg = true;
                    let obj = v.productInfo.productGoodsList[currentIndex];
                    let floatNum = Number(obj.floatNum);
                    let suggestPrice = Number(JSON.parse(JSON.stringify(obj.originalSuggestPrice))) || 0;
                    let value = 0;
                    // 百分比
                    let targetVal = 0;
                    if (obj.salePriceType === 1) {
                      if (obj.floatType === 1) {
                        targetVal = floatNum / 100;
                        value = suggestPrice + targetVal * suggestPrice;
                      } else {
                        targetVal = floatNum / 100;
                        let val = suggestPrice - targetVal * suggestPrice;
                        value = Math.ceil(val * 100) / 100;
                      }
                    }
                    // 固定加价
                    else if (obj.salePriceType === 2) {
                      if (obj.floatType === 3) {
                        value = suggestPrice + floatNum;
                      } else {
                        let val = suggestPrice - floatNum;
                        value = Math.ceil(val * 100) / 100;
                      }
                    }
                    v.productInfo.productGoodsList[
                      currentIndex
                      ].suggestPrice = amountHandel(value);
                  }

                  let floatTypeList = [
                    {value: 1, name: alias47916751af154eb5b47cd5de9d34633d.t('key1000783')},
                    {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1000784')},
                    {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1000785')},
                    {value: 4, name: alias47916751af154eb5b47cd5de9d34633d.t('key1000786')}
                  ];
                  let list = [
                    h("RadioGroup", {
                        class: "mt10",
                        props: {
                          value: v.productInfo.productGoodsList[currentIndex].salePriceType
                        },
                        on: {
                          "on-change": (value) => {
                            v.$nextTick(() => {
                              v.productInfo.productGoodsList[currentIndex].floatNum = 0;
                              v.productInfo.productGoodsList[currentIndex].salePriceType = value;
                              v.productInfo.productGoodsList[currentIndex].floatType = value === 1 ? 1 : 3;
                              handlerRequired();
                            });
                            v.$forceUpdate();
                          }
                        }
                      },
                      [
                        h("Radio", {
                          class: "ml1 mr20",
                          props: {
                            label: 1,
                            disabled: !talg
                          }
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000787')),
                        h("Radio", {
                          class: "ml20 mr1",
                          props: {
                            label: 2,
                            disabled: !talg
                          }
                        }, alias47916751af154eb5b47cd5de9d34633d.t('key1000788'))
                      ]
                    ),
                    h("div", {class: "flex justify-content-center mt8 mb8"}, [
                      salePriceType === 1 ? h("Input", {
                            props: {
                              value: v.productInfo.productGoodsList[currentIndex].floatNum,
                              placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000789'),
                              maxlength: 10,
                              disabled: !talg
                            },
                            class: requiredText ? "ivu-form-item-error" : "",
                            style: {
                              width: "200px"
                            },
                            on: {
                              input: (val) => {
                                v.productInfo.productGoodsList[currentIndex].floatNum = val;
                              },
                              "on-blur": () => {
                                handlerRequired();
                              },
                              "on-enter": () => {
                                handlerRequired();
                              }
                            }
                          },
                          [
                            h("div", {slot: "append"}, "%"),
                            h("Select",
                              {
                                slot: "prepend",
                                props: {
                                  value: v.productInfo.productGoodsList[currentIndex].floatType,
                                  transfer: true,
                                  disabled: !talg
                                },
                                style: {
                                  width: "70px"
                                },
                                on: {
                                  "on-change"(e) {
                                    v.productInfo.productGoodsList[currentIndex].floatType = e;
                                    handlerRequired();
                                  }
                                }
                              },
                              floatTypeList.slice(0, 2).map((i) => {
                                return h(
                                  "Option",
                                  {props: {value: i.value}},
                                  i.name
                                );
                              })
                            )
                          ]
                        )
                        : h("Input", {
                            props: {
                              value: v.productInfo.productGoodsList[currentIndex].floatNum,
                              placeholder: alias47916751af154eb5b47cd5de9d34633d.t('key1000790'),
                              maxlength: 10,
                              disabled: !talg
                            },
                            style: {
                              width: "200px"
                            },
                            class: requiredText ? "ivu-form-item-error" : "",
                            on: {
                              input: (val) => {
                                v.productInfo.productGoodsList[currentIndex].floatNum = val;
                              },
                              "on-blur": () => {
                                handlerRequired();
                              },
                              "on-enter": () => {
                                handlerRequired();
                              }
                            }
                          },
                          [
                            h("Select", {
                                slot: "prepend",
                                props: {
                                  value: v.productInfo.productGoodsList[currentIndex].floatType,
                                  transfer: true,
                                  disabled: !talg
                                },
                                style: {
                                  width: "70px"
                                },
                                on: {
                                  "on-change"(e) {
                                    v.productInfo.productGoodsList[currentIndex].floatType = e;
                                    handlerRequired();
                                  }
                                }
                              },
                              floatTypeList.slice(2, 4).map((i) => {
                                return h(
                                  "Option",
                                  {props: {value: i.value}},
                                  i.name
                                );
                              })
                            )
                          ]
                        )
                    ]),
                    requiredText ? h("div", {
                      style: {
                        color: "red",
                        margin: "5px 0",
                        textAlign: "center"
                      }
                    }, requiredText) : ""
                  ];
                  return h("div", {class: salePriceType === 2 ? "reset_append_style" : ""}, list);
                }
              },
              {
                title: alias47916751af154eb5b47cd5de9d34633d.t('key1000791'),
                key: "suggestPrice",
                field: "suggestPrice",
                width: 150,
                align: "center",
                renderHeaderCell: ({column}, h) => {
                  return h("span", alias47916751af154eb5b47cd5de9d34633d.t('key1000792') + v.currency + "）");
                },
                renderBodyCell({row, column, rowIndex}, h) {
                  let currentIndex = v.getCurrentIndex(row.rowKey);
                  let suggestPrice = v.productInfo.productGoodsList[currentIndex] ? v.productInfo.productGoodsList[currentIndex].suggestPrice : null;
                  return h("span", suggestPrice);
                }
              }
            ];
            productColumn.splice(5, 0, ...list);
          }
        }

        v.originalColumns = productColumn;

        if (v.resetRequiredTalg) {
          v.updateColumnsList(productColumn);
          resolve(true);
        } else {
          v.productColumns.push(...productColumn);
          resolve(true);
        }
      });
    },
    // 处理字段key
    handleKeyFun() {
      let v = this;
      return new Promise((resolve) => {
        v.fixedColumnkeyList = [];
        v.defaultSelectKeyList = ["previewImage", "sku", "availableNumber"]; // 默认选中的key
        if (!v.resetRequiredTalg) {
          v.defaultSelectKeyList.push("packagingSize", "weight", "supplyPrice");
          if (v.pageType === "yms") {
            v.defaultSelectKeyList.push("salesConfiguration");
          }
        }
        let isMainAttributeKey = [];
        let specificationKey = [];
        let list = JSON.parse(JSON.stringify(v.specification)).reverse();
        if (list.length > 0) {
          list.forEach((i, index) => {
            if (i.valueList && i.valueList.length) {
              let key = "specification_" + index;
              if (i.isMainAttribute) {
                key = "specification_isMainAttribute_" + index;
                isMainAttributeKey.push(key);
              } else {
                specificationKey.push(key);
              }
            }
          });
          v.fixedColumnkeyList = [...isMainAttributeKey, ...specificationKey];
          v.defaultSelectKeyList = v.uniqueFunc(v.defaultSelectKeyList);
          resolve(true);
        }
      });
    },
    // 更新列表字段
    updateColumnsList(columns) {
      let v = this;
      v.handleKeyFun().then(() => {
        if (v.setColumnsList.length <= 0) {
          v.$refs["customColumns"].getFieldsList().then((data) => {
            if (data.length > 0) {
              v.setColumnsList = [...v.fixedColumnkeyList, ...data];
            }
            handleColumns(v.setColumnsList);
          });
        } else {
          handleColumns(v.setColumnsList);
        }

        function handleColumns(keyList) {
          let setColumnsList = v.uniqueFunc(keyList);
          if (setColumnsList.length > 0) {
            let newColumns = columns.filter((item) => {
              return setColumnsList.includes(item.key);
            });
            // 按照自定义的排序
            newColumns.sort((a, b) => {
              return (
                setColumnsList.indexOf(a.key) - setColumnsList.indexOf(b.key)
              );
            });
            v.productColumns = newColumns;
          } else {
            v.productColumns = columns;
          }
        }
      });
    },
    // 设置组装商品表格数据
    setProductGoodData(cartesian, specIndex, selectValue) {
      let v = this;

      let _copyProduct = JSON.parse(JSON.stringify(v.productData));
      let _originProductGood = JSON.parse(
        JSON.stringify(v.productInfo.productGoodsList)
      );
      if (_copyProduct.length === 0) {
        v.productInfo.productGoodsList = [];
      }
      if (cartesian.length - _copyProduct.length < 0) {
        _copyProduct.splice(cartesian.length, _copyProduct.length - cartesian.length);
        v.productInfo.productGoodsList.splice(cartesian.length, v.productInfo.productGoodsList.length - cartesian.length);
      }
      let colspanObj = {};
      this.tableSpanRowIndexSign = [];
      cartesian.forEach((arr, index) => {
        // _setTableColSpan(arr, index);
        // const obj = this.ingredientProductGoodsList.find(fItem=>{})
        const basicData = {
          specifications: arr,
          productId: null,
          sku: null,
          width: 0,
          height: 0,
          length: 0,
          weight: 0,
          suggestPrice: 0,
          supplyPrice: null,
          path: null,
          productGoodsSpecifications: [],
          availableNumber: null,
          isAssemble: 0
        };
        if (this.ingredientProductGoodsList.length) {
          basicData.ingredientsObj = _findMatchingItems(
            arr,
            this.ingredientProductGoodsList
          );
        }
        if (!_copyProduct[index]) {
          _copyProduct.push(basicData);
          //v.productInfo.productGoodsList.push(basicData);
        } else {
          _copyProduct[index].specifications = arr;
          //v.$set(v.productInfo.productGoodsList[index],'specifications',arr);
        }
        _setProductData(arr, index, basicData, specIndex, selectValue);
      });
      let isMainAttribute = "";
      if (v.specification.length > 0) {
        let _mainAttr = v.specification.filter((i) => i.isMainAttribute);
        if (_mainAttr && _mainAttr.length) {
          isMainAttribute = _mainAttr[0].label;
        }
      }
      _copyProduct.forEach((product) => {
        product.specifications.forEach((sp) => {
          if (sp.rowspan) {
            sp.rowspan = colspanObj;
          }
          if (sp.label === isMainAttribute) {
            sp._isMainAttribute = true;
          }
        });
        _setTableColSpan(product.specifications);
      });
      v.productData = _copyProduct;
      v.$nextTick(() => {
        let hasSpan = {};
        let newList = JSON.parse(JSON.stringify(_copyProduct));
        newList.map((i, index) => {
          if (hasSpan[i.specifications[0].value]) {
            i.specifications[0].hideRowspan = true;
          } else {
            hasSpan[i.specifications[0].value] = true;
            i.specifications[0].hideRowspan = false;
          }
          // if (i.specifications && i.specifications.length > 0) {
          //   let values = i.specifications.map((item) => {
          //     return item.value
          //   });
          //   let vals = values.join('_');
          //   i.rowKey = vals + '_' + index;
          // } else {
          //   i.rowKey = index;
          // }
          i.rowKey = index;
        });
        v.productDataSpan = [...newList];
      });

      function _setTableColSpan(arr) {
        arr.forEach((k) => {
          if (k._isMainAttribute) {
            if (!colspanObj[k.value]) {
              colspanObj[k.value] = {
                rowspan: 1
              };
              k.rowspan = colspanObj;
            } else {
              colspanObj[k.value].rowspan++;
            }
          }
        });
      }

      function _setProductData(
        arr,
        index,
        basicData,
        specIndex,
        selectValue
      ) {
        let setIndex = null;
        let isAdd = false;
        _originProductGood.forEach((a, aIndex) => {
          let valid = true;
          a.specifications.forEach((b) => {
            arr.forEach((c) => {
              if (b.label === c.label) {
                if (b.value === c.value) {
                  setIndex = aIndex;
                } else {
                  valid = false;
                }
              }
            });
          });
          if (valid && setIndex !== null) {
            isAdd = true;
            v.$set(v.productInfo.productGoodsList, index, JSON.parse(JSON.stringify(_originProductGood[setIndex])));
            v.$set(v.productInfo.productGoodsList[index], "specifications", arr);
          }
        });

        if (!isAdd) {
          /*if (v.productInfo && Array.isArray(v.productInfo.productGoodsList)) {
              // 初始化商品对象如果不存在
              if (!v.productInfo.productGoodsList[index]) {
                v.productInfo.productGoodsList[index] = {}; // 直接赋值空对象
              }
              const target = v.productInfo.productGoodsList[index];
              // 遍历 `basicData` 对象并进行赋值
              for (let key in basicData) {
                target[key] = basicData[key];
              }
              // 特定赋值
              target.specifications = arr;
            }*/

          v.$set(v.productInfo.productGoodsList, index, basicData);
          v.$set(v.productInfo.productGoodsList[index], "specifications", arr);
        }
        // 切换规格属性值时，自动填充原始的数据
        if (v.productInfo.productGoodsList.length > 0 && v.operationType !== "add") {
          v.productInfo.productGoodsList.map((item, idx) => {
            let obj = item.specifications[specIndex];
            if (obj) {
              if (obj.value == selectValue) {
                let originalObj = _copyProduct[idx];
                if (originalObj) {
                  let keyList = [
                    "productId",
                    "sku",
                    "width",
                    "height",
                    "length",
                    "weight",
                    "suggestPrice",
                    "path",
                    "availableNumber"
                  ];
                  keyList.map((key) => {
                    item[key] = originalObj[key] || null;
                    item.supplyPrice = originalObj.suggestPrice;
                    v.$set(v.productInfo.productGoodsList, idx, item);
                  });
                }
              }
            }
          });
        }
      }

      // 匹配表格里每一列对应的sku
      function _findMatchingItems(arr, arr2) {
        let obj = null;
        if (arr2.length === 0) {
          return null;
        }
        arr2.forEach((item) => {
          const list = item.productGoodsSpecifications || [];
          let isMatch = arr.every((item) => {
            return list.some((compareItem) => {
              return (item.label === compareItem.name && item.value === compareItem.value);
            });
          });
          if (isMatch) {
            obj = item;
          }

          // 如果找到匹配项，则返回该项；否则返回null
        });
        return obj;
      }

      this.ingredientProductGoodsList = [];
    },
    // 自动生成SKU
    generateSku(type, productGoodsList) {
      let v = this;
      if (v.productInfo.spu === "") {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000793'));
        return false;
      }
      if (v.productInfo.productGoodsList.length > 0) {
        let sizeName = "";
        let sizeValue = "";
        // 获取自定义属性的属性值
        let customPropertiesList = [];
        let customProperties = v.specification.filter((item) => item.isAllowCustomValue);
        if (customProperties.length > 0) {
          customProperties.map((item) => {
            item.list.map((ele) => {
              ele.cnValue = ele.value;
              customPropertiesList.push(ele);
            });
          });
          customPropertiesList = v.uniqueFunc(customPropertiesList, "cnValue");
        }
        let specificationValueList = [...v.specificationValueList, ...customPropertiesList];
        v.productInfo.productGoodsList.forEach((talg, index) => {
          if (type === "assemble" && !talg.productGoodsId) {
            v.$set(v.productInfo.productGoodsList[index], "isAssemble", 0);
            talg.length = null;
            talg.width = null;
            talg.weight = null;
            talg.suggestPrice = null;
            talg.supplyPrice = null;
            talg.availableNumber = null;
            talg.height = null;
            talg.productGoodsAssembles = [];
            let text = "";
            let nameText = "";
            let vals = [];
            let names = [];
            talg.specifications.map((item) => {
              vals.push(item.value);
              names.push(item.label);
              if (vals.length > 0) {
                text = vals.join("_");
              }
              if (names.length > 0) {
                nameText = names.join("_");
              }
            });
            productGoodsList.map((k) => {
              if (k.specificationAttributesValue == text && k.specificationAttributesName == nameText) {
                talg.length = k.length;
                talg.width = k.width;
                talg.weight = k.weight;
                talg.suggestPrice = k.suggestPrice;
                talg.supplyPrice = k.suggestPrice;
                talg.availableNumber = k.availableNumber;
                talg.height = k.height;
                talg.productGoodsAssembles = v.automaticallyFillInAssemblyInfo(talg);
                v.$set(v.productInfo.productGoodsList[index], "isAssemble", 1);
              }
            });
          }
          if (talg.specifications && talg.specifications.length) {
            let valueList = [];
            talg.specifications.forEach((item) => {
              let arr = v.specification.filter((sp) => sp.label === item.label);
              if (arr && arr.length) {
                if (item.value == alias47916751af154eb5b47cd5de9d34633d.t('key1000741')) {
                  sizeValue = "averageCode";
                } else {
                  let key = item._isMainAttribute ? "usValue" : "cnValue";
                  specificationValueList.map((color) => {
                    if (color.cnValue === item.value) {
                      sizeName = color[key] ? color[key] : "";
                    }
                  });
                  if (sizeName) {
                    valueList.push(sizeName);
                  }
                }
              }
            });
            if (valueList.length > 0 || sizeValue) {
              v.productInfo.productGoodsList[index].sku = v.productInfo.spu + (valueList.length > 0 ? '-' +
                valueList.join('-') : '') + (sizeValue ? ('-' + sizeValue) : '');
              talg.sku = v.productInfo.spu + (valueList.length > 0 ? '-' +
                valueList.join('-') : '') + (sizeValue ? ('-' + sizeValue) : '');
            }
          }
        });
        v.$forceUpdate();
      }
    },
    // 自动填充组装信息数据到 productGoodsAssembles
    automaticallyFillInAssemblyInfo(obj) {
      let selectTableData = [];
      let specifications = obj.specifications;
      let text = "";
      let nameText = "";
      if (specifications.length > 0) {
        let vals = [];
        let names = [];
        specifications.map((item) => {
          vals.push(item.value);
          names.push(item.label);
        });
        text = vals.join("");
        nameText = names.join("_");
      }
      if (this.assemblyInfoData.length > 0) {
        let children = this.assemblyInfoData[0].children || [];
        let productGoods = this.assemblyInfoData[0].productGoods || [];
        let list = children.length > 0 ? children : productGoods.length > 0 ? productGoods : [];
        // 辅料
        let accessoriesList = this.assemblyInfoData[0].accessoriesList.map((item) => {
            return {
              productGoodsId: item.productGoodsId,
              quantity: item.quantity,
              isMaster: 0,
              sku: item.sku
            };
          }
        );
        // 主料
        list.map((ele) => {
          ele.isMaster = 1;
          let vals = [];
          let names = [];
          ele.quantity = this.assemblyInfoData[0].quantity;
          ele.productGoodsSpecifications.map((item) => {
            vals.push(item.value);
            names.push(item.name);
          });
          if (vals.length > 0) {
            ele.specificationAttributesValue = vals.join("");
          }
          if (names.length > 0) {
            ele.specificationAttributesName = names.join("_");
          }
        });
        list.map((ele) => {
          let {sku, quantity, isMaster, productGoodsId} = ele;
          if (ele.specificationAttributesValue == text && ele.specificationAttributesName == nameText) {
            selectTableData = [{sku, quantity, isMaster, productGoodsId}, ...accessoriesList];
          }
        });
        return selectTableData;
      }
    },
    // 回车键更换属性规格(只有自定义属性的才能支持回车键添加属性，因为回车键添加属性与添加自定义属性有交互冲突)
    changeHandleEnterKey(specIndex, selectValue, specValueIndex, source, specItem) {
      if (specItem.isAllowCustomValue) {
        this.changeProductSpecAttr(specIndex, selectValue, specValueIndex, source);
      }
    },

    changeProductSpecAttr(specIndex, selectValue, specValueIndex, source) {
      let v = this;
      let arr = []; // 选中数据
      v.specification.forEach((i) => {
        if (i.valueList.length) {
          arr.push(i.valueList);
          i.valueList.map((item) => {
            if (v.assembleImgTalg) {
              v.assemblyProductColorImageList.map((ele) => {
                if (ele.color === item.value) {
                  let imgs = [...item.imgList, ele.path];
                  let imgList = v.uniqueFunc(imgs);
                  item.imgList = imgList.slice(0, 5);
                }
              });
            }
            if (i.attributeValueEnabledList.includes(item.value)) {
              item.attributeTalg = false;
              item.attributeValueExceptionTalg = false;
              item.disableText = "";
            }
          });
        }
      });
      if (specIndex !== undefined) {
        this.hideSpecList(specIndex, this.specification[specIndex].valueList);
      }
      v.handleProductGoodData(arr, specIndex, selectValue);
      v.$nextTick(() => {
        // 尺码处理
        if (this.specification[specIndex].id === "_size") {
          if (source === "replace") {
            v.replaceSize(specValueIndex, selectValue);
          } else {
            this.changeSizeValue(true, selectValue);
          }
        }
      });
      v.assembleImgTalg = false;
    },
    replaceSize(specValueIndex) {
      let list = this.specification.filter((i) => i.id === "_size");
      if (list.length > 0) {
        let valuesList = list[0].valueList.map((i) => i.value);
        this.handlerSizeData({name: alias47916751af154eb5b47cd5de9d34633d.t('key1000794'), values: valuesList}, [], specValueIndex);
      }
    },
    /**
     *
     * @date: 2022/4/20 11:23
     * @param value bool true 新增  false 删除
     * @param siteName
     */
    changeSizeValue(value, siteName) {
      let v = this;
      let name = siteName;
      let valuesList = v.specification.filter((i) => i.id === "_size")[0].valueList.map((i) => i.value);
      if (value) {
        // 过滤尺码表中没有选中的尺码
        let newList = v.uniqueFunc(v.sizeChartData, "cnSize");
        v.sizeChartData = newList.filter((item) => {
          return valuesList.includes(item.cnSize);
        });
        v.handlerSizeData({name: alias47916751af154eb5b47cd5de9d34633d.t('key1000794'), values: valuesList});
      } else {
        v.sizeChartData.map((item, index) => {
          if (item.cnSize === name) {
            v.sizeChartData.splice(index, 1);
          }
        });
      }
      if (v.sizeChartData.length <= 1) {
        setTimeout(() => {
          _setSizeTableScrollLeft();
        }, 600);
      }
    },
    // 处理添加尺码属性的数据
    handlerSizeData(data, newList, replaceIndex) {
      let v = this;
      let productSizeList = v.productInfo.productSizeList;
      let keyObj = {
        cnSize: "",
        ukSize: "",
        euSize: "",
        usSize: "",
        auSize: "",
        frSize: "",
        deSize: "",
        esSize: "",
        nlSize: "",
        plSize: "",
        ptSize: "",
        itSize: "",
        roSize: ""
      };
      if (productSizeList && productSizeList.length > 0) {
        if (v.operationType === "edit") {
          if (!v.switchClassificationTalg) {
            v.handlerSizeDataCommonCombine(data, keyObj, newList);
          } else {
            if (data) {
              if (data.name === alias47916751af154eb5b47cd5de9d34633d.t('key1000794')) {
                let new_arr = [];
                if (v.sizeChartData.length > 0) {
                  v.sizeChartData.map((item) => {
                    new_arr.push(item.cnSize);
                  });
                }
                data.values.map((ele) => {
                  if (!new_arr.includes(ele)) {
                    keyObj["cnSize"] = ele;
                    v.$nextTick(() => {
                      if (replaceIndex !== undefined) {
                        v.$set(v.sizeChartData, replaceIndex, Object.assign({}, keyObj, v.templateObj));
                      } else {
                        v.addSizeData(Object.assign({}, keyObj, v.templateObj));
                      }
                      v.handerSizeDefaultData(v.sizeChartData, keyObj);
                    });
                  }
                });
              }
            }
          }
        } else {
          if (data) {
            v.handlerSizeDataCommonCombine(data, keyObj, newList);
          }
        }
      } else {
        if (data) {
          if (data.name === alias47916751af154eb5b47cd5de9d34633d.t('key1000794')) {
            let new_arr = [];
            if (v.sizeChartData.length > 0) {
              v.sizeChartData.map((item) => {
                new_arr.push(item.cnSize);
              });
            }
            data.values.map((ele) => {
              if (!new_arr.includes(ele)) {
                keyObj["cnSize"] = ele;
                if (replaceIndex !== undefined) {
                  v.$set(v.sizeChartData, replaceIndex, Object.assign({}, keyObj, v.templateObj));
                } else {
                  v.addSizeData(Object.assign({}, keyObj, v.templateObj));
                }
                v.handerSizeDefaultData(v.sizeChartData, keyObj);
              }
            });
          }
        }
      }
    },
    handlerSizeDataCommonCombine(data, keyObj, newList) {
      let v = this;
      if (data) {
        if (data.name === alias47916751af154eb5b47cd5de9d34633d.t('key1000794')) {
          data.values.map((ele) => {
            keyObj["cnSize"] = ele;
            v.addSizeData(Object.assign({}, v.editObj, keyObj));
          });
        }
        // 过滤尺码表中没有选中的尺码
        let list = v.uniqueFunc(v.sizeChartData, "cnSize");
        let valuesList = v.specification.filter((i) => i.id === "_size")[0].valueList.map((i) => i.value);
        v.sizeChartData = list.filter((item) => {
          return valuesList.includes(item.cnSize);
        });
        v.handerSizeDefaultData(v.sizeChartData, keyObj);
        v.handerSizeValue(newList);
        v.$forceUpdate();
      }
    },
    // 添加尺码数据
    addSizeData(data) {
      let v = this;
      if (v.sizeChartData.some((i) => i.cnSize === data.cnSize)) return;
      v.sizeChartData.push(data);
      // 要根据规格属性的尺码进行排序
      if (v.specification.length > 0) {
        let arr = v.specification.filter((item) => {
          return item.id === "_size";
        });
        if (arr.length > 0) {
          let values = arr[0].valueList.map((ele) => {
            return ele.value;
          });
          v.sizeChartData = v.sizeChartData.sort((a, b) => {
            return values.indexOf(a.cnSize) - values.indexOf(b.cnSize);
          });
        }
      }
    },
    // 对尺码表数据异常的数据进行填充数据
    handerSizeValue(data) {
      let v = this;
      if (data) {
        v.sizeChartData.map((item, index) => {
          data.map((ele) => {
            let {...obj} = ele;
            if (item.cnSize === ele.cnSize) {
              v.sizeChartData[index] = obj;
            }
          });
        });
      }
    },
    // 处理默认填充尺码表的数据
    handerSizeDefaultData(data, keyObj) {
      let v = this;
      let newList = [];
      if (v.sizeChartDefaultData.length > 0) {
        newList = v.sizeChartDefaultData.map((item) => {
          return {
            cnSize: item.cnValue,
            ukSize: item.ukValue,
            euSize: item.euValue,
            usSize: item.usValue,
            auSize: item.auValue,
            frSize: item.frValue,
            deSize: item.deValue,
            esSize: item.esValue,
            nlSize: item.nlValue,
            plSize: item.plValue,
            ptSize: item.ptValue,
            itSize: item.itValue,
            roSize: item.roValue
          };
        });
        if (newList.length > 0) {
          data.map((item, index) => {
            newList.map((ele) => {
              for (let key in keyObj) {
                if (item.cnSize === ele.cnSize) {
                  if (item[key] === "") {
                    v.$set(data[index], key, ele[key]);
                  }
                }
              }
            });
          });
          v.sizeChartData = data;
          v.$forceUpdate();
        }
      }
    },
    // 获取规格数据
    calcDescartes(array) {
      return new Promise((resolve) => {
        if (array.length < 2) {
          let arr = [];
          array.forEach((i) => i.forEach((j) => arr.push([j])));
          resolve(arr);
        } else {
          let list = array.reduce((total, currentValue) => {
            let res = [];
            total.forEach((t) => {
              currentValue.forEach((cv) => {
                if (t instanceof Array) {
                  res.push([...t, cv]);
                } else {
                  res.push([t, cv]);
                }
              });
            });
            return res;
          });
          resolve(list);
        }
      });
    },
    /**
     * 处理尺码表的数据
     * @date: 2022/3/9 15:47
     * @param data 尺码表数据
     */
    handlerTemplate(data) {
      /**
       *  productInfo 商品详情数据
       *  sizeJsonData 尺码模板数据
       * */
      let v = this;
      v.sizeChartColumns = [];
      let fixColumn = [];
      let multilingualSizesTitleData = [
        {key: "cnSize", name: "Tag Size"},
        {key: "multilingualSizes", name: ""}
      ];
      let multilingualSizesData = [
        {paramsKey: "usSize", key: "usValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000369')},
        {paramsKey: "ukSize", key: "ukValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000370')},
        {paramsKey: "auSize", key: "auValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000371')},
        {paramsKey: "euSize", key: "euValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000372')},
        {paramsKey: "frSize", key: "frValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000381')},
        {paramsKey: "deSize", key: "deValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000382')},
        {paramsKey: "esSize", key: "esValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000383')},
        {paramsKey: "nlSize", key: "nlValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000384')},
        {paramsKey: "plSize", key: "plValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000385')},
        {paramsKey: "ptSize", key: "ptValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000386')},
        {paramsKey: "itSize", key: "itValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000387')},
        {paramsKey: "roSize", key: "roValue", name: alias47916751af154eb5b47cd5de9d34633d.t('key1000388')}
      ];
      let keyList = [
        "usValue",
        "ukValue",
        "auValue",
        "euValue",
        "frValue",
        "deValue",
        "esValue",
        "nlValue",
        "plValue",
        "ptValue",
        "itValue",
        "roValue"
      ];
      let defaultUnitName = "";
      if (data) {
        // 处理获取属性模板的尺码数据
        let multilingualSizesList = [];
        let ymsCategoryProductAttributeList =
          data.ymsCategoryProductAttributeList || [];
        let sizeTemplateData = ymsCategoryProductAttributeList.filter((item) => {
          return item.ymsProductAttributeTemplate.isSizeAttribute === 1;
        });
        if (sizeTemplateData.length > 0) {
          let productAttributeDictionaryBos = sizeTemplateData[0].ymsProductAttributeTemplate.productAttributeDictionaryBos || [];
          let filteredKeys = productAttributeDictionaryBos.reduce((acc, item) => {
              keyList.forEach((key) => {
                if (item[key] !== null && item[key] !== undefined && item[key] !== "") {
                  acc.add(key);
                }
              });
              return acc;
            },
            new Set()
          );
          let filteredKeysArray = Array.from(filteredKeys);
          multilingualSizesList = multilingualSizesData.filter((item) => {
            return filteredKeysArray.includes(item.key);
          });
        }
        v.sizeChartTalg = true;
        v.sizeJsonData = {
          productSizeUnitBos: data.ymsProductSizeUnits,
          productSizePartsBos: data.ymsProductSizePartses
        };
        // 处理尺码表的表头数据
        let cnSizeObj = {};
        let otherObj = {};
        let new_arr = [];
        multilingualSizesTitleData.map((ele) => {
          if (ele.key === "cnSize") {
            cnSizeObj = {
              title: ele.name,
              key: ele.key,
              minWidth: 200,
              align: "center",
              fixed: "left",
              render(h, params) {
                let textList = params.row.cnSize;
                let talg = false;
                if (v.specification && v.specification.length > 0) {
                  let arr = v.specification.filter((item) => {
                    return item.id === "_size";
                  });
                  if (arr.length > 0) {
                    arr[0].valueList.map((ele) => {
                      if (ele.value === textList) {
                        talg = ele.attributeTalg;
                      }
                    });
                  }
                }
                if (talg) {
                  return h(
                    "div", {class: "flex align-items-center flex-direction justify-content-center"},
                    [
                      h("p", {class: "redColor"}, alias47916751af154eb5b47cd5de9d34633d.t('key1000772')),
                      h("p", textList)
                    ]
                  );
                } else {
                  return h("span", textList);
                }
              }
            };
          } else {
            otherObj = {
              title: ele.name,
              key: ele.key,
              align: "center",
              minWidth: 200,
              fixed: "left",
              renderHeader(h, params) {
                return h("Select", {
                    props: {
                      value: v.multilingualSizeValue,
                      transfer: true
                    },
                    class: "width_150 text-align-left",
                    on: {
                      "on-change"(val) {
                        v.multilingualSizeValue = val;
                      }
                    }
                  },
                  multilingualSizesList.map((i) => {
                    return h("Option", {
                        props: {
                          value: i.paramsKey
                        }
                      },
                      i.name
                    );
                  })
                );
              },
              render: (h, params) => {
                let text = params.row[v.multilingualSizeValue];
                return h("span", text);
              }
            };
          }
          let obj = Object.assign({}, cnSizeObj, otherObj);
          new_arr.push(obj);
        });
        fixColumn = v.uniqueFunc(new_arr, "key");
        // 获取部位默认单位
        let ymsProductSizeUnits = data.ymsProductSizeUnits || [];
        if (ymsProductSizeUnits.length > 0) {
          ymsProductSizeUnits.map((ele) => {
            if (ele.isDefault === 1) {
              defaultUnitName = ele.name;
            }
          });
        }

        if (!v.productInfo.productSizeList || (v.productInfo.productSizeList && v.productInfo.productSizeList.length === 0)) {
          v.switchClassificationTalg = true; // 此变量是为了处理是否要设置默认值，有新增 和 详情尺码表为空
        }
        if (v.operationType === "look" || v.operationType === "edit" || v.operationType === "add") {
          if (!v.switchClassificationTalg) {
            if (v.productInfo.productSizeList && v.productInfo.productSizeList.length > 0) {
              let productSizeList = JSON.parse(JSON.stringify(v.productInfo.productSizeList));
              let sizeJsonData = JSON.parse(JSON.stringify(v.sizeJsonData));
              if (productSizeList && productSizeList.length > 0) {
                // 部位
                // 1： 产品详情会返回部位数据 【productSizeList  sizeDetailBos】
                // 2:  尺码模板板数据 以尺码模板为准
                // 3:  详情部位数据和模板尺码数据名称匹配 cnName partsName
                let cnSizeList = [];
                let newSizeValueData = v.uniqueFunc(v.sizeValueData);
                v.sizeChartColumns = [];
                sizeJsonData.productSizePartsBos.forEach((item, index) => {
                  let title = `${item.cnName}（${defaultUnitName}）`;
                  let key = "unitValue_" + index;
                  let value = "value_" + index;
                  v.sizeChartColumns.push({
                    title: title,
                    align: "center",
                    key: key,
                    minWidth: 200,
                    render: (h, params) => {
                      let obj = [];
                      if (params.row.sizeDetailBos) {
                        obj = params.row.sizeDetailBos.filter(
                          (i) => i.partsName === item.cnName
                        );
                      }
                      let value = null;
                      if (obj.length > 0)
                        value = Number(obj[0].unitValue) || null;
                      return h("InputNumber", {
                        props: {
                          value: params.row[key] ? Number(params.row[key]) : value,
                          min: 0,
                          max: 9999999,
                          disabled: (() => {
                            return v.isDisabled;
                          })()
                        },
                        style: {
                          width: "110px"
                        },
                        on: {
                          "on-change": (val) => {
                            params.row[key] = val;
                            let num = 0;
                            if (defaultUnitName === "cm") {
                              if (val > 0) {
                                num = val * 2.54;
                                Math.floor(num * 100) / 100;
                                params.row[value] = num.toFixed(2);
                              }
                            } else {
                              if (val > 0) {
                                num = val * 0.393701;
                                Math.floor(num * 100) / 100;
                                params.row[value] = num.toFixed(2);
                              }
                            }
                            v.sizeChartData[params.index] = params.row;
                          }
                        }
                      });
                    }
                  });
                });
                productSizeList.forEach((item) => {
                  if (newSizeValueData.length > 0) {
                    if (newSizeValueData.includes(item.cnSize)) {
                      cnSizeList.push(item.cnSize);
                    }
                  }
                  sizeJsonData.productSizePartsBos.forEach((f, index) => {
                    item["partsName_" + index] = f.cnName;
                    item["unitName_" + index] = defaultUnitName;
                    item["unitName_" + f.cnName] = defaultUnitName;
                    item["unitValue_" + index] = null;
                    v.editObj["partsName_" + index] = f.cnName;
                    v.editObj["unitName_" + index] = defaultUnitName;
                    v.editObj["unitName_" + f.cnName] = defaultUnitName;
                    v.editObj["unitValue_" + index] = null;
                    if (item.sizeDetailBos && item.sizeDetailBos.length > 0) {
                      item.sizeDetailBos.forEach((ele) => {
                        if (f.cnName === ele.partsName) {
                          // index 是在表格编辑用到
                          item["partsName_" + index] = ele.partsName;
                          item["unitName_" + index] = ele.unitName;
                          item["unitName_" + ele.partsName] = ele.unitName;
                          item["unitValue_" + index] = ele.unitValue;
                          v.editObj["partsName_" + index] = ele.partsName;
                          v.editObj["unitName_" + index] = ele.unitName;
                          v.editObj["unitName_" + ele.partsName] = ele.unitName;
                          v.editObj["unitValue_" + index] = null;
                        }
                      });
                    }
                  });
                });
                // 处理历史数据缺失的兼容问题 YMS-3593 （对已勾选中尺码值与尺码表的Tag size 没有对应上的数据进行兼容处理）默认填充对应的数据
                cnSizeList = v.uniqueFunc(cnSizeList);
                if (cnSizeList.length === newSizeValueData.length) {
                  v.$nextTick(() => {
                    v.sizeChartColumns.unshift(...fixColumn);
                    //v.sizeChartData = _sortSize(productSizeList); 这里按照默认排序
                    v.sizeChartData = productSizeList;
                    v.changeSizeValue(true);
                  });
                } else {
                  v.$nextTick(() => {
                    v.sizeChartColumns.unshift(...fixColumn);
                    v.handlerSizeData(newSizeValueData, productSizeList);
                  });
                }
                v.$forceUpdate();
              } else {
                v.handlerPlaceData(data, fixColumn, defaultUnitName);
              }
            }
          } else {
            v.handlerPlaceData(data, fixColumn, defaultUnitName);
            let list = v.specification.filter((i) => i.id === "_size");
            if (list.length > 0) {
              let valuesList = list[0].valueList.map((i) => i.value);
              this.handlerSizeData({
                name: alias47916751af154eb5b47cd5de9d34633d.t('key1000794'),
                values: valuesList
              });
            }
          }
        } else {
          v.handlerPlaceData(data, fixColumn, defaultUnitName);
        }
      } else {
        v.sizeChartData = [];
        v.sizeChartTalg = false;
      }
      if (v.sizeValueData.length > 0) {
        let new_arr = [];
        let newList = [];
        if (v.sizeList.length > 0) {
          v.sizeList.map((item) => {
            item.checked = v.sizeValueData.includes(item.name);
          });
          for (let i = 0; i < v.sizeValueData.length; i++) {
            let obj = v.sizeValueData[i];
            let isExist = false;
            for (let j = 0; j < v.sizeList.length; j++) {
              let item = v.sizeList[j].name;
              if (obj === item) {
                isExist = true;
                break;
              }
            }
            if (!isExist) {
              newList.push(obj);
              new_arr.push({
                name: obj
              });
            }
          }
        } else {
          v.sizeValueData.map((ele) => {
            newList.push(ele);
            new_arr.push({
              name: ele
            });
          });
        }
        newList = [...new Set([...newList])];
        if (new_arr.length > 0) {
          new_arr.map((item) => {
            if (newList.includes(item.name)) {
              return item;
            }
          });
          new_arr = v.uniqueFunc(new_arr, "name");
          v.selectMoreSizeList = [];
          v.moreSize = true;
          new_arr.map((ele) => {
            v.selectMoreSizeList.push(ele.name);
          });
        } else {
          v.moreSize = false;
        }
        let list = [...v.moreSizeList, ...new_arr];
        v.moreSizeList = v.uniqueFunc(list, "name");
        v.skuPropertyList[1].values = v.uniqueFunc(v.sizeValueData);
        if (v.skuPropertyList && v.skuPropertyList.length > 0) {
          v.handlerSizeData(v.skuPropertyList[1]);
        }
      }

      function _sortSize(productSizeList) {
        let copyDefaultData = JSON.parse(
          JSON.stringify(v.sizeChartDefaultData)
        );
        copyDefaultData.forEach((i) => {
          productSizeList.forEach((j) => {
            if (j.cnSize === i.value) {
              j.sortNo = i.sortNo;
            }
          });
        });
        return productSizeList.sort(function (a, b) {
          return a.sortNo - b.sortNo;
        });
      }
    },
    /**
     * 处理新增尺码表部位取值
     * @date: 2022/3/9 15:47
     * @param data 尺码表数据
     * @param fixColumn 组装的尺码表column
     */
    handlerPlaceData(data, fixColumn, defaultUnitName) {
      let v = this;
      // 部位
      let ymsProductSizePartses = data.ymsProductSizePartses || [];
      let place = {};
      if (ymsProductSizePartses.length > 0) {
        ymsProductSizePartses.map((item, index) => {
          v.templateObj[item.ymsProductSizePartsId + "_partName"] = item.cnName;
          v.templateObj[item.ymsProductSizePartsId + "_defaultUnitName"] = defaultUnitName;
          v.templateObj[item.ymsProductSizePartsId + "_defaultValue"] = null;
          v.templateObj[item.ymsProductSizePartsId + "_value"] = null;
          let title = `${item.cnName}（${defaultUnitName}）`;
          let key = item.ymsProductSizePartsId + "_defaultValue";
          let value = item.ymsProductSizePartsId + "_value";
          place = {
            title: title,
            align: "center",
            key: key,
            width: 200,
            render: (h, params) => {
              return h("InputNumber", {
                props: {
                  value: params.row[key] ? Number(params.row[key]) : null,
                  min: 0,
                  max: 9999999,
                  disabled: (() => {
                    return v.isDisabled;
                  })()
                },
                style: {
                  width: "110px"
                },
                on: {
                  "on-change": (val) => {
                    params.row[key] = val;
                    let num = 0;
                    if (defaultUnitName === "cm") {
                      if (val > 0) {
                        num = val * 2.54;
                        Math.floor(num * 100) / 100;
                        params.row[value] = num.toFixed(2);
                      }
                    } else {
                      if (val > 0) {
                        num = val * 0.393701;
                        Math.floor(num * 100) / 100;
                        params.row[value] = num.toFixed(2);
                      }
                    }
                    v.sizeChartData[params.index] = params.row;
                  }
                }
              });
            }
          };
          v.sizeChartColumns.push(place);
        });
      }
      v.$nextTick(() => {
        v.sizeChartColumns.unshift(...fixColumn);
      });
      v.$forceUpdate();
    },
    // 图片上传
    uploadSpecImg(specIndex, specValueIndex, response, file) {
      let v = this;
      if (v.specification[specIndex].valueList[specValueIndex].imgList.length < 5) {
        v.specification[specIndex].valueList[specValueIndex].imgList.push(response.datas[0]);
        if (v.specification[specIndex].valueList.every((i) => i.imgList && i.imgList.length)) {
          v.$set(v.specification[specIndex], "specRequireValid", true);
        }
      } else {
        v.$Message.info(alias47916751af154eb5b47cd5de9d34633d.t('key1000795'));
      }
      v.updateProductGoodsImg();
      setTimeout(() => {
        v.beforeHandleUploadImagesList = [];
        v.recordAndUploadImagesList = [];
      }, 400);
    },
    // 更新商品图片
    updateProductGoodsImg() {
      let v = this;
      v.productData.forEach((i, index) => {
        i.specifications.forEach((a) => {
          v.specification.forEach((b) => {
            b.valueList.forEach((c) => {
              if (c.label === a.label && c.value === a.value && b.isMainAttribute) {
                let path = c.imgList && c.imgList.length ? c.imgList[0] : null;
                v.$nextTick(() => {
                  v.$set(v.productInfo.productGoodsList[index], "path", path);
                  v.$set(v.productData[index], "path", path);
                });
              }
            });
          });
        });
      });
      this.$forceUpdate();
    },
    // 删除轮播图
    delProductImages({index}) {
      this.$nextTick(() => {
        this.productInfo.productImages.splice(index, 1);
        this.$forceUpdate();
      });
    },
    // 删除规格图片
    delSpecImage({specIndex, specValueIndex, index}) {
      this.$nextTick(() => {
        this.specification[specIndex].valueList[specValueIndex].imgList.splice(index, 1);
        this.$forceUpdate();
        this.updateProductGoodsImg();
      });
    },
    // 删除规格
    delSpec(specIndex, specValueIndex, selectValue) {
      this.specification[specIndex].valueList.splice(specValueIndex, 1);
      this.changeProductSpecAttr(specIndex);
      // 尺码处理
      if (this.specification[specIndex].id === "_size") {
        this.changeSizeValue(false, selectValue);
      }
    },
    /**
     * 添加规格
     * @date: 2022/4/18 11:23
     * @param specIndex
     * @param value
     * @param isMainAttribute 主属性
     */
    addSpecToList(specIndex, item, isMainAttribute) {
      // 禁用的属性值不能添加
      if (!item.disabled) {
        let obj = {
          value: item.value,
          type: item.type === 0 ? 0 : 1,
          label: this.specification[specIndex].label
        };
        if (isMainAttribute) {
          obj.imgList = [];
          obj._isMainAttribute = true;
        } else {
          this.$set(this.specification[specIndex], "specRequireValid", true);
        }
        this.specification[specIndex].valueList.push(obj);
        this.changeProductSpecAttr(specIndex);
        this.valueIsNull = "";
        // 在页面渲染完成时，手动隐藏select的下拉框
        /*this.$nextTick(() => {
            const selectDropdowns = document.querySelectorAll('.ivu-select-dropdown');
            selectDropdowns.forEach(element => {
              element.style.display = 'none';
            });
          });*/
      }
    },
    hideSpecList(specIndex, valueList) {
      let values = valueList.map((i) => i.value);
      this.specification[specIndex].list.map((i) => {
        i._show = !values.includes(i.value);
      });
      this.$forceUpdate();
    },
    // 设置不同系统上传图片的地址
    setUploadActionUrl(supplierSystem) {
      if (supplierSystem) {
        this.uploadFilesUrl = api.post_uploadFiles + '?basePath=yms-supplier-service&&action="uploadimage"';
      } else {
        this.uploadFilesUrl = api.post_uploadFiles + '?basePath=yms-core-service&&action="uploadimage"';
      }
    },
    // 拖拽结束
    endDragImg({specValueIndex, specIndex}, type) {
      this.drag.isMove = false;
      this.updateProductGoodsImg();
      if (this.specification.length > 0) {
        if (specIndex !== null && specValueIndex !== null && type === "specificationImage") {
          this.$set(this.specification[specIndex].valueList[specValueIndex], "isMove", false);
        }
      }
    },
    // 拖拽开始
    startDragImg(e, {specIndex, specValueIndex}) {
      if (e.originalEvent.path && e.originalEvent.path.length > 0) {
        if (e.originalEvent.path[0].className.includes("del-icon") && e.originalEvent.path[0].dataset.obj) {
          let obj = JSON.parse(e.originalEvent.path[0].dataset.obj);
          if (obj.type === "delSpec") {
            this.delSpecImage(obj);
          } else if (obj.type === "delProduct") {
            this.delProductImages(obj);
          }
        }
      }
      if (this.specification.length > 0) {
        if (specIndex !== null && specValueIndex !== null) {
          this.$set(this.specification[specIndex].valueList[specValueIndex], "isMove", true);
          this.drag.isMove = true;
        }
      }
    },
    // 打开分类
    openChoseCategory(type) {
      this.$refs.productCategory.open(this.choseCategoryObj, type);
    },
    // 选择主料
    openChoseIngredients(type, isEdit = false) {
      this.selectType = type;
      this.$nextTick(() => {
        this.$refs.selectIngredients.open(isEdit);
      });
    },
    // 选择部位和素材
    openChosePartAndSource(data, list) {
      this.$refs.selectPartAndSource.open(data, list);
    },
    // 上传商品图片成功
    uploadFilesProductImgSuccess(response, file, fileList) {
      let v = this;
      startLoading(alias47916751af154eb5b47cd5de9d34633d.t('key1000426'));
      if (response.code === 0) {
        v.recordAndUploadImagesList.push(response.datas[0]);
        let talg = v.beforeHandleUploadImagesList.length > 1;
        if (v.beforeHandleUploadImagesList.length === v.recordAndUploadImagesList.length) {
          if (talg) {
            endLoading();
            v.kjnovaClipperMultiple = true;
            v.$refs["addMultipleImages"].initAddMultipleImagesData(v.recordAndUploadImagesList);
          } else {
            v.kjnovaClipperMultiple = false;
            let link = response.datas[0];
            let imgUrl = v.setImgPath(link, null, null, null, false);
            v.$refs["kjnovaClipper"].initKjnovaClipperData(imgUrl);
          }
          setTimeout(() => {
            v.beforeHandleUploadImagesList = [];
            v.recordAndUploadImagesList = [];
          }, 400);
        }
      }
    },
    // 编辑商品按钮
    editProduct() {
      let url = window.location.href.split("?")[0];
      let href = url + `?type=edit&productId=${this.productId}`;
      if (this.platformId) {
        href = url + `?type=edit&productId=${this.productId}&platformId=${this.platformId}`;
      }
      window.location.href = href;
      window.location.reload();
    },
    // 取消按钮
    pageCancel() {
      if (this.isIframe) {
        this.postMessage({
          type: "clickCancel"
        });
        return;
      }
      if (this.systemTalg) {
        this.$router.push("/productCenter/productGoods");
      } else {
        this.$router.push("/productList");
      }
    },
    setPageType() {
      return window.location.href.includes("yms.html") ? "yms" : "supplier";
    },
    // 添加分层
    addDiscount() {
      this.discountData.push({
        endSalesVolume: "", // 分层结束销量
        schemeType: "", // 方案类型 0.满减 1.折扣
        price: "",
        //"schemeValue": 0, // 方案系数
        //"sortNo": 0, // 排序 自增 1,2,3 ,
        startSalesVolume: "" // 分层开始销量
      });
      this.productPriceSchemeBos.push({
        endSalesVolume: alias47916751af154eb5b47cd5de9d34633d.t('key1000723'), // 分层结束销量
        schemeType: "", // 方案类型 0.满减 1.折扣
        price: "",
        startSalesVolume: "" // 分层开始销量
      });
      if (this.productPriceSchemeBos.length > 1) {
        this.$nextTick(() => {
          this.productPriceSchemeBos[
          this.productPriceSchemeBos.length - 2
            ].endSalesVolume = "";
        });
      }
      this.setDiscountPrice();
    },
    setDiscountPrice(nextTick) {
      let price = "";
      if (this.productInfo.productGoodsList) {
        price = Math.min(...this.productInfo.productGoodsList.map((i) => i.supplyPrice));
      }
      let data = this.productPriceSchemeBos.map((i) => {
        i.price = price;
        let _schemeValue = i.schemeValue || 0;
        if (this.discountType === "0") {
          i.preferentialPrice = i.price - _schemeValue;
        } else if (this.discountType === "1") {
          i.preferentialPrice = i.price * _schemeValue;
        }
        return i;
      });
      this.discountData = [];
      this.productPriceSchemeBos = [];
      if (nextTick) {
        this.$nextTick(() => {
          this.discountData = JSON.parse(JSON.stringify(data));
          this.productPriceSchemeBos = JSON.parse(JSON.stringify(data));
        });
      } else {
        this.discountData = JSON.parse(JSON.stringify(data));
        this.productPriceSchemeBos = JSON.parse(JSON.stringify(data));
      }
    },
    discountChange() {
      let data = this.productPriceSchemeBos.map((i) => {
        i.schemeValue = "";
        i.preferentialPrice = "";
        return i;
      });
      this.discountData = [];
      this.productPriceSchemeBos = [];
      this.$nextTick(() => {
        this.discountData = JSON.parse(JSON.stringify(data));
        this.productPriceSchemeBos = JSON.parse(JSON.stringify(data));
      });
    },
    // 处理新增、编辑商品信息有变更信息时，离开页面（关闭窗口、刷新页面、切换页面）进行拦截提示
    handleIntercept() {
      let v = this;
      let type = v.operationType;
      let path = v.$route.path;
      let data = v.handleParamsFun();
      let talg = true;
      if (path == "/productDetails") {
        let keyList = [
          "insetProductSizeBoList",
          "imageTypeList",
          "productAttributes",
          "productColorImages",
          "productImages",
          "productPriceSchemeBos",
          "productGoodsList"
        ];
        // 新增商品校验参数是否变更
        if (type == "add") {
          let {deliveryInterval, productService, ...obj} = data;
          for (let key in obj) {
            if (keyList.includes(key)) {
              if (obj[key].length > 0) {
                if (key == "productGoodsList") {
                  for (let i = 0; i < obj.productGoodsList.length; i++) {
                    let item = obj.productGoodsList[i];
                    for (let k in item) {
                      if (k == "productGoodsSpecifications") {
                        if (item[k].length > 0) {
                          talg = false;
                          return false;
                        }
                      } else {
                        if (item[k]) {
                          talg = false;
                          return false;
                        }
                      }
                    }
                  }
                } else {
                  talg = false;
                  return false;
                }
              }
            } else {
              if (obj[key]) {
                talg = false;
                return false;
              }
            }
          }
        }
        // 编辑商品校验参数是否变更
        else if (type == "edit") {
          // 编辑商品时，离开页面直接进行拦截提示，暂时先不做信息变更时，在做拦截提示
          talg = false;
        }
      }
      return talg;
    },

    // 监听页面刷新和关闭
    beforeunloadHandler(e) {
      if (!this.handleIntercept() && !this.adoptTalg) {
        e = e || window.event;
        if (e) {
          e.returnValue = alias47916751af154eb5b47cd5de9d34633d.t('key1000796');
        }
        return alias47916751af154eb5b47cd5de9d34633d.t('key1000796');
      }
    },
    // 跳转到指定页面
    updateJumpPage(to, from, next, url) {
      let v = this;
      if (!v.handleIntercept()) {
        v.$Modal.confirm({
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000797'),
          content: alias47916751af154eb5b47cd5de9d34633d.t('key1000798'),
          okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000799'),
          cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
          onOk: () => {
            next();
          },
          onCancel: () => {
            window.location.href = url;
          }
        });
      } else {
        next();
      }
    },
    // 打开商品规格排序
    openAttrSort() {
      this.$refs.attrSortRef.open();
    },
    // 更新规格排序
    attrSortSave(data) {
      let v = this;
      let colspanObj = {};
      let _productData = JSON.parse(JSON.stringify(v.productData));
      let _productGoodsList = JSON.parse(JSON.stringify(v.productInfo.productGoodsList)
      );
      v.specification.splice(0, v.specification.length, ...data);
      /*v.productData = [];
        v.productInfo.productGoodsList = [];
        v.productData.push(..._setTableColSpan(_productData.sort(_sort)));
        v.productInfo.productGoodsList.push(..._productGoodsList.sort(_sort));*/
      if (v.specification.length > 0) {
        let arr = [];
        v.specification.forEach((i) => {
          if (i.valueList.length) {
            arr.push(i.valueList);
          }
        });
        v.handleProductGoodData(arr);
      }

      let sizeSpecList = v.specification.filter((i) => i.isSizeAttribute);
      let valueList = [];
      if (sizeSpecList && sizeSpecList.length) {
        valueList = sizeSpecList[0].valueList;
        v.sizeChartData = v.sizeChartData.sort(_sizeSort);
      }
      v.$forceUpdate();

      // 设置列表span
      function _setTableColSpan(arr) {
        arr.forEach((a) => {
          a.specifications.forEach((b) => {
            if (b.rowspan) {
              delete b.rowspan;
            }
          });
        });
        arr.forEach((i) => {
          i.specifications.forEach((k) => {
            if (k._isMainAttribute) {
              if (!colspanObj[k.value]) {
                colspanObj[k.value] = {
                  rowspan: 1
                };
                k.rowspan = colspanObj;
              } else {
                colspanObj[k.value].rowspan++;
              }
            }
          });
        });
        return arr;
      }

      function _sort(a, b) {
        let _has = true;
        let aIndex;
        let bIndex;
        a.specifications.forEach((i, index) => {
          if (_has && i.value !== b.specifications[index].value) {
            aIndex = v.specification[index].valueList.findIndex((sp) => sp.value === i.value);
            bIndex = v.specification[index].valueList.findIndex((sp) => sp.value === b.specifications[index].value);
            _has = false;
          }
        });
        return aIndex - bIndex;
      }

      function _sizeSort(a, b) {
        let aIndex = valueList.findIndex((sp) => sp.value === a.cnSize);
        let bIndex = valueList.findIndex((sp) => sp.value === b.cnSize);
        return aIndex - bIndex;
      }
    },
    // 校验轮播图图片大小和格式
    beforeHandleUpload(file, type) {
      return new Promise((resolve, reject) => {
        // 校验图片大小不能大于5M
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
          this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000424'));
          return reject();
        }
        /*if (this.productInfo.productType === 2) {
            this.beforeHandleUploadImagesList.push(file);
            resolve();
            return;
          }*/
        // 校验图片格式
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const buffer = Buffer.from(reader.result.split(",")[1], "base64");
          const {mime} = await FileType.fromBuffer(buffer);
          if (!this.imageFormatList.includes(mime)) {
            this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000425'));
            return reject();
          }
          // 规格图片
          /*if (type === 'specificationImage') {
              const img = new Image()
              img.src = reader.result
              img.onload = () => {
                const {naturalWidth, naturalHeight} = img
                console.log('---naturalWidth----', naturalWidth, '-----naturalHeight-----', naturalHeight);
                if ((naturalWidth < 800 || naturalHeight < 800 || naturalWidth > 6000 || naturalHeight > 6000)) {
                  this.$Message.error('图片尺寸至少要≥800且<=6000')
                  return reject()
                }
                resolve()
              }
            } else {
              this.beforeHandleUploadImagesList.push(file);
              resolve()
            }*/
          this.beforeHandleUploadImagesList.push(file);
          resolve();
        };
      });
    },
    // 设置表单必填项校验规则
    getRules(item) {
      let list = [];
      if (item.required) {
        list = [
          {
            required: true,
            message: alias47916751af154eb5b47cd5de9d34633d.t('key1000725') + item.title + alias47916751af154eb5b47cd5de9d34633d.t('key1000800'),
            trigger: "blur"
          }
        ];
      }
      return list;
    },
    // 自动生成多语种的商品标题
    automaticallyTitleChange(value, type) {
      let v = this;
      if (value) {
        let list = [];
        let propertyValuesList = [];
        let productAttributeDictionaryBosList = [];
        if (v.ymsCategoryProductAttributeList.length > 0) {
          // 获取当前选中的所有属性值前10个
          v.ymsCategoryProductAttributeList.map((item) => {
            if (item.propertyValues) {
              if (v.typeOf(item.propertyValues) === "string") {
                propertyValuesList.push(item.propertyValues);
              } else {
                propertyValuesList.push(...item.propertyValues);
              }
            }
            productAttributeDictionaryBosList.push(...item.ymsProductAttributeTemplate.productAttributeDictionaryBos);
          });
          propertyValuesList = v.uniqueFunc(propertyValuesList);
          if (propertyValuesList.length > 0) {
            let newList = propertyValuesList.slice(0, 10);
            if (productAttributeDictionaryBosList.length > 0) {
              newList.map((item) => {
                productAttributeDictionaryBosList.map((ele) => {
                  if (item === ele.ymsProductAttributeDictionaryId) {
                    let val = ele.usValue || "";
                    if (val) {
                      list.push(val);
                    }
                  }
                });
              });
              let customData = productAttributeDictionaryBosList.filter((talg) => {
                return talg.type === 0;
              });
              if (customData.length > 0) {
                customData.map((talg) => {
                  if (newList.includes(talg.cnValue)) {
                    list.push(talg.cnValue);
                  }
                });
              }
            }
          }
        }
        if (list.length > 0) {
          let name = list.join(" ");
          v.productInfo["name_" + type] = name + " " + v.classificationName;
          v.$forceUpdate();
        } else {
          v.productInfo["name_" + type] = v.classificationName;
        }
      }
    },
    // 自动生成多语种商品标题
    changeAutomaticallyTitle(val) {
      if (val) {
        this.automaticallyTitleChange(val, "US");
      }
    },
    // 一键配置相关的销售价
    setSalesConfiguration(value) {
      let v = this;
      if (value) {
        let obj = v.productInfo.productGoodsList.find((item) => {
          return !isStringValueEmpty(item.floatNum);
        });
        if (obj) {
          v.productInfo.productGoodsList.map((item) => {
            item.floatNum = obj.floatNum;
            item.floatType = obj.floatType;
            item.salePriceType = obj.salePriceType;
          });
          v.$forceUpdate();
        }
      }
    },
    // 更新组装商品
    updateData(data) {
      this.assemblyInfoTalg = false;
      let newData = JSON.parse(JSON.stringify(data));
      this.$set(this.productInfo.productGoodsList[this.activeIndex], "productGoodsAssembles", newData);
      this.$forceUpdate();
    },
    // 打开组装商品
    showAddProductModal(obj, index) {
      let selectTableData = [];
      // 批量组装信息
      let specifications = obj.specifications;
      let text = "";
      let nameText = "";
      if (specifications.length > 0) {
        let vals = [];
        let names = [];
        specifications.map((item) => {
          vals.push(item.value);
          names.push(item.label);
        });
        text = vals.join("");
        nameText = names.join("_");
      }
      if (this.assemblyInfoData.length > 0) {
        let children = this.assemblyInfoData[0].children || [];
        let productGoods = this.assemblyInfoData[0].productGoods || [];
        let list = children.length > 0 ? children : productGoods.length > 0 ? productGoods : [];
        // 辅料
        let accessoriesList = this.assemblyInfoData[0].accessoriesList.map((item) => {
            return {
              productGoodsId: item.productGoodsId,
              quantity: item.quantity,
              isMaster: 0,
              sku: item.sku
            };
          }
        );
        // 主料
        list.map((ele) => {
          ele.isMaster = 1;
          let vals = [];
          let names = [];
          ele.quantity = this.assemblyInfoData[0].quantity;
          ele.productGoodsSpecifications.map((item) => {
            vals.push(item.value);
            names.push(item.name);
          });
          if (vals.length > 0) {
            ele.specificationAttributesValue = vals.join("");
          }
          if (names.length > 0) {
            ele.specificationAttributesName = names.join("_");
          }
        });
        if (obj.productGoodsId && obj.productId) {
          selectTableData = obj.productGoodsAssembles || [];
        } else {
          if (obj.productGoodsAssembles && obj.productGoodsAssembles.length > 0) {
            selectTableData = obj.productGoodsAssembles || [];
          } else {
            list.map((ele) => {
              let {sku, quantity, isMaster, productGoodsId} = ele;
              if (ele.specificationAttributesValue == text && ele.specificationAttributesName == nameText) {
                obj.productGoodsAssembles = [
                  {sku, quantity, isMaster, productGoodsId},
                  ...accessoriesList
                ];
                selectTableData = obj.productGoodsAssembles;
              }
            });
          }
        }
      } else {
        selectTableData = obj.productGoodsAssembles || [];
      }
      this.$refs["addCommonProduct"].initFun();
      this.$refs["addCommonProduct"].matchingGoodsType = "manual";
      this.$refs["addCommonProduct"].selectTableData = JSON.parse(JSON.stringify(selectTableData));
      this.activeIndex = index;
    },
    // 更新批量组装信息
    updateBatchEditAssemblyInfo(data) {
      let v = this;
      v.assemblyInfoData = data;
      v.assemblyInfoTalg = true;
      let productId = data[0].productId;
      let newList = [];
      Promise.all([v._getDetails(productId)]).then((result) => {
        let newData = result[0];
        let productSizeList = newData.productSizeList || [];
        let productGoodsList = newData.productGoodsList || [];
        v.assemblyProductColorImageList = newData.productColorImageList || [];
        let productAttributeResultList =
          newData.productAttributeResultList || [];
        if (v.operationType === "add") {
          v.handleProductTitle(newData);
          if (v.productInfo.imageTypeList.length <= 0) {
            v.productInfo.imageTypeList = newData.imageTypeList;
          }
        }
        let imgList = [];
        if (newData.productImageList.length > 0) {
          imgList = newData.productImageList.map((i) => i.path);
        }
        let arr = [...v.productInfo.productImages, ...imgList];
        v.productInfo.productImages = v.uniqueFunc(arr);
        v._toViewAttr(productAttributeResultList);
        let valueList = [];
        if (productGoodsList.length > 0) {
          productGoodsList.map((item) => {
            let vals = [];
            let names = [];
            item.productGoodsSpecifications.map((item) => {
              vals.push(item.value);
              names.push(item.name);
            });
            if (vals.length > 0) {
              item.specificationAttributesValue = vals.join("_");
            }
            if (names.length > 0) {
              item.specificationAttributesName = names.join("_");
            }
          });
          newList = extractSpecificationValues(productGoodsList);

          function extractSpecificationValues(data) {
            const specifications = {};
            // 遍历每个商品
            data.forEach((product) => {
              // 遍历商品的规格
              product.productGoodsSpecifications.forEach((specification) => {
                // 如果规格名称在specifications中不存在，则初始化一个空数组
                if (!specifications[specification.name]) {
                  specifications[specification.name] = new Set();
                }
                // 将规格值存入对应的 Set 中
                specifications[specification.name].add(specification.value);
              });
            });
            // 格式化数据为指定格式
            const formattedSpecifications = [];
            for (const name in specifications) {
              formattedSpecifications.push({
                name: name,
                values: Array.from(specifications[name]) // 将 Set 转换为数组
              });
            }
            return formattedSpecifications;
          }

          if (v.specification.length > 0) {
            v.specification.map((item, index) => {
              newList.map((ele) => {
                if (item.label === ele.name) {
                  ele.values.map((val) => {
                    let talg = item.valueList.every((vals) => {
                      return vals.value !== val;
                    });
                    if (talg) {
                      item.list.map((k) => {
                        if (k.value === val) {
                          valueList.push({
                            name: ele.name,
                            value: val
                          });
                          v.assembleImgTalg = true;
                          v.addSpecToList(index, k, item.isMainAttribute);
                        }
                      });
                    }
                  });
                }
              });
            });
          }
          setTimeout(() => {
            v.generateSku("assemble", productGoodsList);
            if (v.sizeChartData.length > 0) {
              let productSizePartsBos = v.sizeJsonData.productSizePartsBos || [];
              v.sizeChartData.map((item) => {
                if (productSizeList.length > 0) {
                  productSizeList.map((ele) => {
                    if (item.cnSize === ele.cnSize) {
                      ele.sizeDetailBos.map((talg) => {
                        productSizePartsBos.map((k) => {
                          if (talg.partsName == item[k.ymsProductSizePartsId + "_partName"]) {
                            item[k.ymsProductSizePartsId + "_defaultValue"] = talg.unitValue;
                          }
                        });
                      });
                    }
                  });
                }
              });
              v.$forceUpdate();
            }
            v.$forceUpdate();
          }, 800);
        }
      });
    },
    // 处理属性值比例数据
    handleProportionData(data) {
      let v = this;
      let newList = [];
      if (data.length > 0) {
        newList = data.map((item) => {
          let proportionValueList = [];
          let propertyValues = item.propertyValues || [];
          let attributeValueData = item.attributeValueData || [];
          let ymsProductAttributeDictionaryIds = attributeValueData.map((ele) => {
            return ele.ymsProductAttributeDictionaryId;
          });
          let isAllowCustomValue = item.ymsProductAttributeTemplate.isAllowCustomValue === "1"; // 允许自定义属性值
          if (isAllowCustomValue) {
            propertyValues.map((id) => {
              if (ymsProductAttributeDictionaryIds.includes(id)) {
                attributeValueData.map((talg) => {
                  if (id === talg.ymsProductAttributeDictionaryId) {
                    proportionValueList.push({
                      name: talg.cnValue,
                      value: propertyValues.length >= 2 ? null : 100
                    });
                  }
                });
              } else {
                proportionValueList.push({
                  name: id,
                  value: propertyValues.length >= 2 ? null : 100
                });
              }
            });
          } else {
            propertyValues.map((id) => {
              attributeValueData.map((talg) => {
                if (id === talg.ymsProductAttributeDictionaryId) {
                  proportionValueList.push({
                    name: talg.cnValue,
                    value: null
                  });
                }
              });
            });
          }
          return {
            ymsProductAttributeTemplateId: item.ymsProductAttributeTemplateId,
            title: item.ymsProductAttributeTemplate.cnName,
            proportionValueList: v.uniqueFunc(proportionValueList, "name")
          };
        });
        let list = [];
        data.map((item) => {
          let proportionList = item.proportionList || [];
          list.push(...proportionList);
        });
        if (list.length > 0) {
          newList.map((item) => {
            list.map((ele) => {
              if (item.ymsProductAttributeTemplateId === ele.ymsProductAttributeTemplateId) {
                item.proportionValueList.map((k) => {
                  ele.proportionValueList.map((j) => {
                    if (k.name === j.name) {
                      k.value = k.value ? k.value : j.value;
                    }
                  });
                });
              }
            });
          });
        }

        if (v.proportionList.length > 0) {
          let rawData = JSON.parse(JSON.stringify(v.proportionList));
          newList.map((item) => {
            rawData.map((ele) => {
              if (item.ymsProductAttributeTemplateId === ele.ymsProductAttributeTemplateId) {
                item.proportionValueList.map((talg) => {
                  talg.value = null;
                  ele.proportionValueList.map((k) => {
                    if (talg.name === k.name) {
                      talg.value = k.value;
                    }
                  });
                });
              }
            });
          });
        }
        if (newList.length > 0) {
          v.proportionList = newList;
          v.$forceUpdate();
        }
      }
    },
    // 获取当前属性中需要填写的属性值比例数据
    getProportionData(list) {
      let v = this;
      // 获取属性值需要填写比例的属性数据 (多选、需要填写属性值比例)
      let newData = list.filter((item, index) => {
        return (
          item.ymsProductAttributeTemplate.chooseType === 0 &&
          item.ymsProductAttributeTemplate.isProportion === 1 &&
          item.propertyValues &&
          item.propertyValues.length > 0
        );
      });
      v.handleProportionData(newData);
    },
    // 重置表单相关必填项
    resetRequiredFields(val) {
      let v = this;
      if (val === 2) {
        v.rules.imageTypeList[0].required = false;
        v.resetRequiredTalg = true;
        v.$nextTick(() => {
          v.$refs["productInfo"].validate(); // 强制表单重新校验
        });
      } else {
        v.resetRequiredTalg = false;
        v.rules.imageTypeList[0].required = true;
      }
      v.productColumns = [];
      if (v.resetRequiredTalg) {
        if (v.originalColumns.findIndex((fItem) => fItem.key === "partAndMeatSetting") !== -1) {
          v.originalColumns.splice(-3);
        }
        v.updateColumnsList(v.originalColumns);
      } else {
        v.productColumns.push(...v.originalColumns);
      }
    },
    // 自定义列按钮
    customColumnsBtn() {
      let v = this;
      v.handleKeyFun().then(() => {
        setTimeout(() => {
          v.$refs["customColumns"].initCustomColumnsData();
        }, 400);
      });
    },
    // 获取当前列表设置展示的字段
    customColumnsData(data) {
      let v = this;
      v.setColumnsList = [...v.fixedColumnkeyList, ...data];
      v.updateColumnsList(v.originalColumns);
    },
    // 更新裁剪的图片
    updateUploadImg(data) {
      this.productInfo.productImages.push(data);
      this.$refs.productInfo.validateField("productImages");
      this.$forceUpdate();
    },
    updateUploadImgList(imgList) {
      this.productInfo.productImages = [
        ...this.productInfo.productImages,
        ...imgList
      ];
      this.$refs.productInfo.validateField("productImages");
      this.$forceUpdate();
    },
    selectedIngredient(item, isEdit = false) {
      // 选择主料时才需要判断这个
      // 做判断，调接口判断主料的商品类目信息进行校验，是否能匹配上YMS商品类目表中类目信息。
      // 1.匹配上
      // 且供应商当前未选择商品类目信息时，则直接导入相应YMS商品类目、属性、规格信息。
      // 但供应商当前已选择商品类目信息，呈现如下提示，若供应商选择确认，则直接导入覆盖相应YMS商品类目、属性、规格信息。
      // 2.匹配不上 提示该主料不可用
      let url = this.systemTalg ? api.get_ymsProductCategory_api_navigation + `?productCategoryNavigation=${item.productCategoryNavigation}` :
        api.get_ymsProductCategory_navigation + `?productCategoryNavigation=${item.productCategoryNavigation}`
      if (item.selectType === "spu") {
        this.axios.get(url).then((res) => {
          const {code, datas} = res.data;
          if (code === 0) {
            if (datas) {
              if (this.choseCategoryObj?.productCategoryNavigation) {
                // 已经选择了类目
                this.$Modal.confirm({
                  title: alias47916751af154eb5b47cd5de9d34633d.t('key1000801'),
                  content: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000708')} ”<span class="blueColor">${datas.productCategoryNavigation}</span>${alias47916751af154eb5b47cd5de9d34633d.t('key1000709')}`,
                  okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000443'),
                  onOk: () => {
                    this.emitSaveCategory(datas, true, item);
                    this.choseIngredientObj = item;
                  },
                  onCancel: () => {

                  }
                });
              } else {
                this.emitSaveCategory(datas, true, item);
                this.choseIngredientObj = item;
              }
              // 匹配上
            } else {
              // 没匹配上
              this.$Modal.warning({
                title: alias47916751af154eb5b47cd5de9d34633d.t('key1000802'),
                content:
                  alias47916751af154eb5b47cd5de9d34633d.t('key1000803'),
                okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000443'),
                onOk: () => {
                }
              });
            }
          }
        });
      } else {
        // 选择的是sku
        if (isEdit) {
          this.$Modal.confirm({
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000804'),
            content: alias47916751af154eb5b47cd5de9d34633d.t('key1000805'),
            okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000806'),
            cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
            onOk: () => {
              this.$nextTick(() => {
                this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "ingredientsObj", item);
                this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "partAndMeatSetting", []);
                this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "materialProcess", "");
                this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "useless", "");
              });
              this.$forceUpdate();
            },
            onCancel: () => {
            }
          });
        } else {
          this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "ingredientsObj", item);
          this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "partAndMeatSetting", []);
          this.$set(this.productInfo.productGoodsList[this.ingredientCurrentIndex], "materialProcess", "");
          this.$forceUpdate();
        }
      }
      this.ingredientsObj = item;
    },
    // 跳转绑定pod平台
    bindingAuthorization() {
      this.adoptTalg = true;
      this.$router.push('/platformAuthorization');
    },
    selectedPartAndMeat(item) {
      this.$set(this.productInfo.productGoodsList[this.partAndMeatSettingIndex], "partAndMeatSetting", item);
      this.$forceUpdate();
    },
    // 校验数字和小数点
    isNumberOrDecimal(value) {
      const regex = this.$regular.numbersWithDecimalPoints;
      return regex.test(value)
    }
  },
  watch: {
    // 监听商品规格数据
    specification: {
      handler(data) {
        this.showSizeChart = data.some((item) => item.isSizeAttribute);
      },
      deep: true,
      immediate: true
    },
    // 监听商品属性
    ymsCategoryProductAttributeList: {
      handler(list) {
        this.getProportionData(list);
      },
      deep: true,
      immediate: true
    },
    // 监听商品类型
    "productInfo.productType": {
      handler(val) {
        this.resetRequiredFields(val);
        this.setProductGoodColumn();
      },
      deep: true,
      immediate: true
    },
    productDataSpan: {
      handler(data) {
        this.virtualScrollOption.enable = data.length > 50;
      },
      deep: true,
      immediate: true
    }
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  components: {
    sellingCountrySettingModal,
    addCommonProductModal,
    languagesTabs,
    uploadNetworkImagesModal,
    productCategory,
    productDetailUploadStandard,
    attrSort,
    draggable,
    batchEditAssemblyInfoModal,
    reasonForRejectionModal,
    customColumnsDrawer,
    kjnovaClipperModal,
    addMultipleImagesModal,
    selectIngredientsModal,
    selectPartAndSourceModal
  },
  beforeRouteLeave(to, from, next) {
    let v = this;
    let url = window.location.href;
    let type = v.operationType;
    let path = v.$route.path;
    if (path === "/productDetails") {
      if (type === "edit" || type === "add") {
        if (!v.adoptTalg) {
          v.updateJumpPage(to, from, next, url);
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      v.updateJumpPage(to, from, next, url);
    }
  }
};
</script>

<style lang="less">
.sku_table .ve-table .reset_append_style .ivu-input-wrapper {
  .ivu-input-group-append {
    display: none !important;
  }
}

.sku_table .ve-table .ivu-input-number-handler-wrap {
  display: flex;
  flex-direction: column;
}

.image-type .ivu-checkbox-wrapper {
  display: inline;
}

.resetRequiredStyles {
  .ivu-form-item-label:before {
    display: none !important;
  }
}

.container-box {
  padding-bottom: 100px;

  .ivu-card-head {
    padding-left: 35px;
  }

  .ivu-form .ivu-form-item-label {
    color: #222;
  }

  .ivu-form-item {
    margin-bottom: 18px;
  }

  .listingProductAttribute_box {
    background-color: #fbe5e8;
    padding: 10px 20px 0 20px;
    border-radius: 12px;
    border: 2px solid #822834;
  }
}

.upload-box {
  width: 100px;
  height: 100px;
  color: #999999;
  transition: color 0.1s ease-in;
  background: #fff;
  border: 1px dashed #dcdee2;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .upload-icon {
    font-size: 40px;
    display: block;
    margin-bottom: 10px;
  }

  &:hover {
    color: #666666;
  }
}

.upload-url-img {
  padding-left: 10px;
  color: #00a0e9;
  cursor: pointer;
  display: flex;
  flex-direction: column-reverse;
}

.edit-category {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
}

.cursor-primary {
  color: #1890ff;
  cursor: pointer;
}

.resetBtn {
  border: none !important;

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.upload-color-box {
  width: 50px;
  height: 50px;
  color: #999999;
  transition: color 0.1s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #666666;
  }
}

.container-box .footer-btn .ivu-btn {
  font-size: 18px;
}

.product-upload-bigImg,
.color-img-bigImg {
  width: 400px;
}

.container-box .ivu-form-item-error-tip {
  padding-top: 3px;
}

.ivu-tooltip-inner {
  max-width: 450px;
}

.ivu-form-item-error.ivu-input-number {
  border: 1px solid #ed4014;
}

.ivu-select-selection-error {
  .ivu-select-selection {
    border: 1px solid #ed4014;
  }
}

.discount-table .t-ipts-box {
  display: flex;
  align-items: center;
}

.mlr5 {
  margin: 0 5px;
}

.discount-table .t-ipt {
  width: 200px;
}

.discount-table .t-del {
  cursor: pointer;
}

.discount-table .t-del:hover {
  color: #d9001b;
}

.cellNoPre {
  white-space: normal !important;
}

.sku_info_con {
  text-align: left;
  font-size: 12px;
  margin-left: 4px;

  .sku_info_con_edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
<style lang="less" scoped>
.no-move {
  cursor: initial !important;
}

.color-item {
  display: flex;
  align-items: center;

  .select {
    margin-right: 10px;
  }

  .del {
    white-space: nowrap;
    color: #1890ff;
    margin-right: 10px;
    cursor: pointer;
  }
}

.product-upload-img-box {
  display: flex;
  width: 100%;
  flex-flow: wrap;
}

.product-upload-img-list {
  display: flex;
  flex-flow: wrap;

  .product-upload-img-item {
    width: 100px;
    height: 100px;
    margin: 0 10px 10px 0;
    cursor: move;
    position: relative;

    .img-styles {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border: 1px solid #ddd;
    }

    &:hover .del-icon {
      display: block;
      z-index: 1000;
    }

    .del-icon {
      font-size: 26px;
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;
      display: none;

      &:hover {
        color: #ff0000;
      }
    }
  }
}

.product-color-img-list {
  display: flex;
  flex-flow: wrap;

  .product-color-img-item {
    width: 50px;
    margin-right: 10px;
    min-height: 71px;
    cursor: move;

    .img-styles {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border: 1px solid #ddd;
    }

    &:hover .del-icon {
      display: block;
    }

    .del-icon {
      font-size: 14px;
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;
      display: none;

      &:hover {
        color: #ff0000;
      }
    }

    .delete_specifications_img {
      font-size: 12px;
      cursor: pointer;
      color: #2d8cf0;
      text-align: center;
      margin-top: 2px;
      display: none;
      width: 100%;
    }

    &:hover .delete_specifications_img {
      display: inline-block;
    }
  }
}

.color-img {
  width: 40px;
  height: 40px;
}

.tip-p {
  line-height: 18px;
  font-size: 12px;
  color: #777777;
  margin-top: 3px;
}

.container-box {
  .ipt {
    max-width: 400px;
  }

  .footer-btn {
    padding: 10px 15px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    box-shadow: 0 2px 13px #999999;
    z-index: 100;
  }

  .title_styles /deep/ .ivu-card-head p span {
    font-size: 17px;
    font-weight: bold;
  }
}

.attr-box {
  .spec-show-more {
    display: flex;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    align-items: center;

    span:hover {
      color: #666666;
    }
  }

  .proportion_box {
    font-size: 12px;
    margin-left: 20px;

    .proportion_box_item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }
  }
}

.attr-example {
  color: #00a0e9;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.price-inventory-box {
  padding: 0 20px;

  .pi-box {
    margin-bottom: 20px;

    .pi-title {
      font-size: 14px;
      line-height: 30px;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 10px;
    }
  }

  /deep/ .ivu-form-item-content {
    line-height: initial !important;
  }
}

.pi-placeholder {
  background-color: #f2f2f2;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #777777;
}

.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}

.redDot:before {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 12px;
  color: #ed4014;
}

.first-img {
  padding: 2px 6px;
  font-size: 12px;
  color: #ffffff;
  background-color: rgb(24, 144, 255, 0.8);
  position: absolute;
  line-height: 22px;
  top: 0;
  left: 0;
}

.add-discount {
  color: #00a0e9;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.cursor-disabled {
  cursor: no-drop;
  color: #9e9e9e;
}
</style>
