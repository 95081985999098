<template>
  <Modal v-model="modal" width="1400" :title="$t('key1001109')">
    <div class="part_meat_container">
      <Table highlight-row border :columns="columns" :data="showTableData">
        <!--位置-->
        <template #productPartId="{ index }">
          <Select
            transfer
            :disabled="!systemTalg || operationType === 'look'"
            :class="tableData[index].productPartId ? '' : 'errorSelect'"
            v-model="tableData[index].productPartId"
            :placeholder="$t('key1001110')"
            @on-change="(value) => partChange(value, index)">
            <Option v-for="item in partList" :disabled="selectPart.includes(item.value)"
              :key="item.value" :value="item.value">{{ item.label }}</Option>
          </Select>
          <div v-if="!tableData[index].productPartId" class="errorText">
            {{ $t('key1001112') }}
          </div>
        </template>
        <!--部位示意图-->
        <template #sketch="{ index }">
          <div v-if="!tableData[index].productPartId">
            <span>{{ $t('key1001113') }}</span>
          </div>
          <div v-else>
            <!-- <img
              style="width: 60px; height: 60px"
              class="img_styles"
              :src="tableData[index].partsSketchImage"
              @error="setErrorImg($event)"
              alt=""
            /> -->
            <div style="display: flex">
              <Tooltip
                placement="right"
                theme="light"
                :transfer="true"
                v-for="(url, index) in tableData[index].partsSketchImage"
                :key="index"
              >
                <img
                  style="width: 60px; height: 60px; margin: 0 2px"
                  class="img-styles"
                  :src="url"
                  @error="setErrorImg($event)"
                  alt=""
                />
                <template #content>
                  <img
                    style="width: 180px; height: 180px"
                    :src="url"
                    @error="setErrorImg($event)"
                    alt=""
                  />
                </template>
              </Tooltip>
            </div>
          </div>
        </template>
        <!--印刷尺寸（长、宽cm）-->
        <template #size="{ index }">
          <div v-if="!tableData[index].productPartId">
            <span>{{ $t('key1001113') }}</span>
          </div>
          <div v-else>
            <div class="error_input_number_container">
              <div :class="tableData[index].printingLength &&tableData[index].printingLength <=tableData[index].districtLength? '': 'error_border'">
                <InputNumber
                  :active-change="false"
                  :min="0"
                  :max="999999999"
                  :formatter="(value) => formatterNumber(value, 2)"
                  :disabled="operationType === 'look'"
                  @on-change="(value) =>handleLengthChange(value, index, 'printingLength')"
                  v-model="tableData[index].printingLength"/>
              </div>
              <span class="space10">*</span>
              <div :class="tableData[index].printingWidth &&tableData[index].printingWidth <=tableData[index].districtWidth? '': 'error_border'">
                <InputNumber
                  :active-change="false"
                  :min="0"
                  :max="999999999"
                  :formatter="(value) => formatterNumber(value, 2)"
                  :disabled="operationType === 'look'"
                  @on-change="(value) => handleLengthChange(value, index, 'printingWidth')"
                  v-model="tableData[index].printingWidth"/>
              </div>
            </div>
            <div class="errorText flex flex-col align-items-center">
              <span v-if="!tableData[index].printingLength || !tableData[index].printingWidth">{{ $t('key1001114') }}</span>
              <span v-if="tableData[index].printingLength >tableData[index].districtLength">{{ $t('key1001115') }}</span>
              <span v-if="tableData[index].printingWidth >tableData[index].districtWidth">{{ $t('key1001116') }}</span>
            </div>
          </div>
        </template>
        <!--可印区域（长、宽cm）-->
        <template #region="{ index }">
          <div v-if="!tableData[index].productPartId">
            <span>{{ $t('key1001113') }}</span>
          </div>
          <div v-else>
            <div class="error_input_number_container">
              <div :class="tableData[index].districtLength ? '' : 'error_border'">
                <InputNumber :max="100" :min="0" :disabled="true" v-model="tableData[index].districtLength"/>
              </div>
              <span class="space10">*</span>
              <div :class="tableData[index].districtWidth ? '' : 'error_border'">
                <InputNumber :max="100" :min="0" :disabled="true" v-model="tableData[index].districtWidth"/>
              </div>
            </div>
          </div>
        </template>
        <!--素材-->
        <template #source="{ index }">
          <div v-if="!tableData[index].productPartId">
            <span>{{ $t('key1001113') }}</span>
          </div>
          <div v-else>
            <div v-if=" tableData[index].printingLength &&tableData[index].printingWidth">
              <div v-if="tableData[index].positionSketchImage" class="img_container">
                <Icon
                  v-if="operationType !== 'look'"
                  type="md-close"
                  :size="20"
                  class="img_icon"
                  @click="delImg(tableData[index], ['positionSketchImage','positionSketchImageBase64'])"
                />
                <img class="img_styles" :src="setImgPath(tableData[index].positionSketchImage,null,null,null,false)"
                  @error="setErrorImg($event)" alt=""/>
              </div>
              <Upload
                v-else
                class="error_upload"
                name="files"
                :headers="headObj"
                :show-upload-list="false"
                :on-success="(response, file, fileList) =>uploadFilesProductImgSuccess(response,file,fileList,tableData[index],'positionSketchImage')"
                :on-error="uploadFilesError"
                :before-upload="(file) => beforeHandleUpload(file, tableData[index])"
                :action="uploadFilesUrl"
                type="drag"
                style="display: inline-block; width: 60px">
                <div class="upload-box">
                  <i class="iconfont upload-icon">&#xe68d;</i>
                </div>
              </Upload>
            </div>
            <div v-else>{{ $t('key1001117') }}</div>
          </div>
        </template>
        <!--素材编码-->
        <template #sourceCode="{ index }">
          <div v-if="!tableData[index].productPartId">
            <span>{{ $t('key1001113') }}</span>
          </div>
          <div v-else>
            <div v-if="tableData[index].printingLength && tableData[index].printingWidth">
              <Input
                :class="tableData[index].positionSketchImageId ? '' : 'error_input'"
                v-model="tableData[index].positionSketchImageId"
                clearable
                :maxlength="16"
                :placeholder="$t('key1001111')"
                :disabled="operationType === 'look'"
                @on-change="(event) => handleInput(event, index)">
              </Input>
            </div>
            <div v-else>{{ $t('key1001117') }}</div>
          </div>
        </template>
        <!--成品效果图-->
        <template #rendering="{ index }">
          <div v-if="!tableData[index].productPartId">
            <span>{{ $t('key1001113') }}</span>
          </div>
          <div v-else>
            <div v-if="tableData[index].finishProductImage" class="img_container">
              <Icon
                v-if="operationType !== 'look'"
                type="md-close"
                :size="20"
                class="img_icon"
                @click="delImg(tableData[index], ['finishProductImage'])"/>
              <img
                class="img_styles"
                :src="setImgPath(tableData[index].finishProductImage,null,null,null,false)"
                @error="setErrorImg($event)"
                alt=""/>
            </div>
            <Upload
              v-else
              class="error_upload"
              name="files"
              :headers="headObj"
              :show-upload-list="false"
              :on-success="(response, file, fileList) =>uploadFilesProductImgSuccess(response,file,fileList,tableData[index],'finishProductImage')"
              :on-error="uploadFilesError"
              :action="uploadFilesUrl"
              type="drag"
              style="display: inline-block; width: 60px">
              <div class="upload-box">
                <i class="iconfont upload-icon">&#xe68d;</i>
              </div>
            </Upload>
          </div>
        </template>
        <!--操作-->
        <template #operate="{ index }">
          <div class="operateBtn" v-if="tableData.length > 1 && operationType !== 'look'" @click="delItem(index)">{{ $t('key1000092') }}</div>
        </template>
      </Table>
      <Button v-if="operationType !== 'look'" class="mt10" type="text" @click="addItem" :disabled="tableData.length >= partList.length">{{ $t('key1001118') }}</Button>
    </div>
    <template #footer>
      <Button @click="modal = false" v-if="operationType !== 'look'">{{ $t('key1000098') }}</Button>
      <Button @click="modal = false" v-if="operationType === 'look'">{{ $t('key1000205') }}</Button>
      <Button type="primary" @click="confirm" v-if="operationType !== 'look'">{{ $t('key1000097') }}</Button>
    </template>
  </Modal>
</template>
<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import api from "@/api/";
import Mixin from "@/components/mixin/common_mixin";
import FileType from "file-type";
import {compressImage, formatterNumber} from "@/utils/common";

export default {
  name: "partAndMeatSetting",
  mixins: [Mixin],
  props: {
    operationType: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      modal: false,
      uploadFilesUrl: api.post_uploadFiles + '?basePath=yms-supplier-service&&action="uploadimage"', // 上传url
      columns: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001119'),
          key: "productPartId",
          slot: "productPartId",
          width: "150px",
          align: "center",
          renderHeader: (h, params) => {
            return h("div", {class: "flex align-items-center"}, [
              h("span", {class: "required"}, params.column.title)
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001120'),
          key: "sketch",
          slot: "sketch",
          align: "center"
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001121'),
          key: "region",
          slot: "region",
          width: "200px",
          align: "center"
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001122'),
          key: "size",
          slot: "size",
          width: "200px",
          align: "center",
          renderHeader: (h, params) => {
            return h("div", {class: "flex align-items-center"}, [
              h("span", {class: "required"}, params.column.title)
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001123'),
          key: "source",
          slot: "source",
          width: "120px",
          align: "center",
          renderHeader: (h, params) => {
            return h("div", {class: "flex align-items-center"}, [
              h("span", {class: "required"}, params.column.title),
              /*h(
                "Tooltip",
                {
                  props: {
                    offset: 13,
                    transfer: true,
                    maxWidth: 250,
                    content: "素材图片宽高比例要求与印刷尺寸比例相符",
                    placement: "top-end"
                  }
                },
                [
                  h("Icon", {
                    props: {
                      type: "ios-help-circle-outline",
                      size: 16
                    },
                    style: {
                      cursor: "pointer",
                      marginLeft: "5px"
                    }
                  })
                ]
              )*/
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001124'),
          key: "sourceCode",
          slot: "sourceCode",
          width: "140px",
          align: "center",
          renderHeader: (h, params) => {
            return h("div", {class: "flex align-items-center"}, [
              h("span", {class: "required"}, params.column.title),
              h(
                "Tooltip",
                {
                  props: {
                    offset: 13,
                    transfer: true,
                    maxWidth: 250,
                    content: alias47916751af154eb5b47cd5de9d34633d.t('key1001125'),
                    placement: "top-end"
                  }
                },
                [
                  h("Icon", {
                    props: {
                      type: "ios-help-circle-outline",
                      size: 16
                    },
                    style: {
                      cursor: "pointer",
                      marginLeft: "5px"
                    }
                  })
                ]
              )
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001126'),
          key: "rendering",
          slot: "rendering",
          width: "120px",
          align: "center",
          renderHeader: (h, params) => {
            return h("div", {class: "flex align-items-center"}, [
              h("span", {class: "required"}, params.column.title)
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000283'),
          key: "operate",
          slot: "operate",
          align: "center",
          width: "100px"
        }
      ],
      tableData: [],
      showTableData: [],
      partList: [],
      selectPart: [],
      productGoodsExtendList: [],
      isTip: true, // 判断modal关闭时，需不需要二次确认弹框
      settingList: [], // 已设置的部位
      cancelList: [] // 保存没更改的数据，点取消返回
    };
  },
  methods: {
    formatterNumber,
    open(partList, dataList) {
      this.modal = true;
      this.cancelList = dataList;
      this.productGoodsExtendList = partList || [];
      this.settingList = dataList || [];
      this.getPartOptions();
      this.initData();
    },
    initData() {
      if (this.settingList.length) {
        const list = this.settingList.map((item, index) => {
          const fItem = this.partList.find(
            (fItem) => fItem.productPartId == item.productPartId
          );
          let obj = {
            ...item,
            index
          };
          obj.preprintingLength = item.printingLength;
          obj.preprintingWidth = item.printingWidth;
          if (fItem) {
            obj.partsSketchImage = fItem.referenceImagePathList;
          }
          return obj;
        });
        JSON.stringify(list);
        this.tableData = JSON.parse(JSON.stringify(list));
        this.showTableData = JSON.parse(JSON.stringify(list));
        this.selectPart = this.settingList.map((item) => item.productPartId);
      } else {
        this.tableData = [
          {
            printingLength: null,
            printingWidth: null,
            districtLength: null,
            districtWidth: null,
            productPartId: "",
            positionSketchImageId: "",
            positionSketchImage: "",
            finishProductImage: "",
            partsSketchImage: [],
            parts: ""
          }
        ];
        this.showTableData = [
          {
            printingLength: null,
            printingWidth: null,
            districtLength: null,
            districtWidth: null,
            productPartId: "",
            positionSketchImageId: "",
            positionSketchImage: "",
            finishProductImage: "",
            partsSketchImage: [],
            parts: ""
          }
        ];
        this.selectPart = [];
        this.productGoodsExtendList = [];
      }

      this.isTip = true;
    },

    getPartOptions() {
      const datas = this.productGoodsExtendList;
      this.partList = datas.map((mItem) => {
        let obj = {
          ...mItem,
          label: mItem.partName,
          value: mItem.productPartId
        };
        return obj;
      });
    },
    getSelected() {
      let selected = [];
      this.tableData.forEach((item) => {
        if (item.productPartId) {
          selected.push(item.productPartId);
        }
      });
      this.selectPart = selected;
      // return selected;
    },
    partChange(value, index) {
      const {
        printingLength,
        printingWidth,
        positionSketchImage,
        positionSketchImageId,
        finishProductImage
      } = this.tableData[index];
      if (
        printingLength ||
        printingWidth ||
        positionSketchImage ||
        positionSketchImageId ||
        finishProductImage
      ) {
        this.$Modal.confirm({
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001127'),
          content:
            alias47916751af154eb5b47cd5de9d34633d.t('key1001128'),
          okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000806'),
          cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
          onOk: () => {
            this.$set(this.tableData[index], "prePartId", value);
            this.$set(this.tableData[index], "productPartId", value);
            this.$set(this.tableData[index], "partsSketchImage", []);
            this.$set(this.tableData[index], "districtLength", "");
            this.$set(this.tableData[index], "districtWidth", "");
            this.$set(this.tableData[index], "printingLength", "");
            this.$set(this.tableData[index], "printingWidth", "");
            this.$set(this.tableData[index], "positionSketchImage", "");
            this.$set(this.tableData[index], "positionSketchImageId", "");
            this.$set(this.tableData[index], "finishProductImage", "");
            const item = this.partList.find((fItem) => fItem.value === value);
            if (item) {
              console.log(item, "item");
              const imgUrl = item.referenceImagePathList
                ? item.referenceImagePathList
                : [];
              this.$set(this.tableData[index], "partsSketchImage", imgUrl);
              this.$set(this.tableData[index], "parts", item.label);
              this.$set(this.tableData[index], "districtLength", item.length);
              this.$set(this.tableData[index], "districtWidth", item.width);
            }
            this.getSelected();
          },
          onCancel: () => {
            this.$nextTick(() => {
              this.$set(
                this.tableData[index],
                "productPartId",
                this.tableData[index]["prePartId"]
              );
              this.getSelected();
            });
          }
        });
      } else {
        this.$set(this.tableData[index], "prePartId", value);
        const item = this.partList.find((fItem) => fItem.value === value);
        if (item) {
          const imgUrl = item.referenceImagePathList
            ? item.referenceImagePathList
            : [];
          this.$set(this.tableData[index], "partsSketchImage", imgUrl);
          this.$set(this.tableData[index], "districtLength", item.length);
          this.$set(this.tableData[index], "districtWidth", item.width);
          this.$set(this.tableData[index], "parts", item.label);
          this.$set(this.showTableData[index], "parts", item.label);
          this.$set(this.showTableData[index], "partsSketchImage", imgUrl);
          this.$set(this.showTableData[index], "districtLength", item.length);
          this.$set(this.showTableData[index], "districtWidth", item.width);
        }
        this.getSelected();
      }
    },
    addItem() {
      const obj = {
        printingLength: null,
        printingWidth: null,
        districtLength: null,
        districtWidth: null,
        productPartId: "",
        positionSketchImageId: "",
        positionSketchImage: "",
        finishProductImage: "",
        partsSketchImage: [],
        parts: ""
      };
      this.tableData.push(obj);
      this.showTableData.push(obj);
      this.resetIndex();
    },
    delItem(index) {
      const productPartId = this.tableData[index].productPartId;
      const fIndex = this.selectPart.findIndex(
        (item) => item === productPartId
      );
      if (fIndex !== -1) {
        this.selectPart.splice(fIndex, 1);
      }
      this.showTableData.splice(index, 1);
      this.tableData.splice(index, 1);
      this.resetIndex();
    },
    //   重新赋值index，用于操作数据
    resetIndex() {
      this.tableData = this.tableData.map((item, index) => {
        let obj = {...item, index};
        return obj;
      });
    },
    delImg(row, keys) {
      this.tableData = this.tableData.map((item) => {
        let obj = {
          ...item
        };
        if (item.index === row.index) {
          for (let key of keys) {
            obj[key] = "";
          }
        }
        return obj;
      });
    },
    // 上传商品图片成功
    async uploadFilesProductImgSuccess(response, file, fileList, row, key) {
      const {code, datas} = response;
      if (code === 0) {
        const link = datas[0];
        this.tableData = this.tableData.map((item) => {
          let obj = {
            ...item
          };
          if (item.index === row.index) {
            obj[key] = link;
          }
          return obj;
        });
      }
    },
    // 校验轮播图图片大小和格式
    beforeHandleUpload(file, row) {
      return new Promise((resolve, reject) => {
        // 校验图片大小不能大于20M
        const maxSize = 20 * 1024 * 1024; // 20MB
        if (file.size > maxSize) {
          this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1001129'));
          return reject();
        }
        //   const flag = this.compareRatios(row.printingLength ,row.printingWidth )
        /*if (this.productInfo.productType === 2) {
        this.beforeHandleUploadImagesList.push(file);
        resolve();
        return;
      }*/
        // 校验图片格式
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const buffer = Buffer.from(reader.result.split(",")[1], "base64");
          const {mime} = await FileType.fromBuffer(buffer);
          if (!this.imageFormatList.includes(mime)) {
            this.$Message.error(
              alias47916751af154eb5b47cd5de9d34633d.t('key1000425')
            );
            return reject();
          }
          // 规格图片
          const img = new Image();
          img.src = reader.result;
          row['positionSketchImageBase64'] = await compressImage(file);
          resolve();
          /*img.onload = () => {
            const { naturalWidth, naturalHeight } = img;
            const flag = this.compareRatios(
              row.printingLength,
              row.printingWidth,
              naturalWidth,
              naturalHeight
            );
            if (!flag) {
              this.$Message.error("素材图片宽高比例要求与印刷尺寸比例相符");
              return reject();
            }
            resolve();
          };*/
        };
      });
    },
    //   校验图片比例是否相同
    compareRatios(a, b, c, d) {
      // 计算第一组值的比值
      const ratio1 = a / b;

      // 计算第二组值的比值
      const ratio2 = c / d;

      // 比较两个比值是否相等
      // 由于浮点数的精度问题，直接使用 === 可能会有问题，因此使用一个误差范围 epsilon

      // const epsilon = 1e-10; // 可以根据需要调整误差范围
      // return Math.abs(ratio1 - ratio2) < epsilon;
      const epsilon = 1e-10; // 可以根据需要调整误差范围
      return ratio1 === ratio2;
    },
    async confirm() {
      const isValid = await this.handleValid();
      //  校验
      if (!isValid) return this.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1001130'));
      this.isTip = false;
      const list = this.tableData.map((item, index) => {
        let obj = {
          ...item,
          sortNo: index
        };
        obj.partsSketchImage = item.partsSketchImage
          ? item.partsSketchImage[0]
          : "";
        return obj;
      });
      this.$emit("selectedPartAndMeat", list);
      this.modal = false;
      // this.tableData = [];
      // this.showTableData = [];
      // }
    },

    handleValid() {
      return new Promise((resolve) => {
        const requiredFields = [
          "printingLength",
          "printingWidth",
          "positionSketchImageId",
          "positionSketchImage",
          "finishProductImage"
        ];
        let isValid = true;
        this.tableData.forEach((item) => {
          requiredFields.forEach((field) => {
            if (!item[field]) {
              isValid = false;
            }
          });
        });
        resolve(isValid);
      });
    },

    handleInput(event, index) {
      // 使用正则表达式过滤非英文和数字字符'
      const value = event.target.value;
      let inputValue = value.replace(/[^a-zA-Z0-9]/g, "");
      // 限制长度最多为16位
      if (inputValue.length > 16) {
        inputValue = inputValue.substring(0, 16);
      }
      this.$nextTick(() => {
        this.$set(this.tableData[index], "positionSketchImageId", inputValue);
        this.$set(
          this.showTableData[index],
          "positionSketchImageId",
          inputValue
        );
      });
    },
    handleLengthChange(value, index, key) {
      let val = Number(value.toFixed(2)) || 0;
      this.$nextTick(() => {
        this.$set(this.tableData[index], "pre" + key, val);
        this.$set(this.tableData[index], key, val);
      });
      /*if (this.tableData[index].positionSketchImage) {
        this.$Modal.confirm({
          title: "修改确认",
          content:
            "修改印刷尺寸后，已上传的素材图片信息需要重新上传。请确认是否修改？",
          okText: "确认修改",
          cancelText: "取消",
          onOk: () => {
            this.$set(this.tableData[index], "pre" + key, val);
            this.$set(this.tableData[index], key, val);
            this.$set(this.showTableData[index], key, val);
            this.$set(this.tableData[index], "positionSketchImage", "");
            this.$set(this.showTableData[index], "positionSketchImage", "");
          },
          onCancel: () => {
            this.$nextTick(() => {
              this.$set(this.tableData[index], key, this.tableData[index]["pre" + key]);
            });
          }
        });
      } else {
        this.$nextTick(() => {
          this.$set(this.tableData[index], "pre" + key, val);
          this.$set(this.tableData[index], key, val);
        });
      }*/
      this.$forceUpdate();
      console.log(val, "parseInt(value)");
    }
  },
  watch: {
    modal: {
      handler(val) {
        if (!val && this.isTip && this.operationType !== "look") {
          this.$Modal.confirm({
            title: "内容未保存提醒",
            content: "退出后未保存内容将丢失，请问是否确认退出",
            okText: "确认退出",
            cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
            onOk: () => {
              this.$emit("selectedPartAndMeat", this.cancelList);
            },
            onCancel: () => {
              this.modal = true;
            }
          });
        }
      }
    },
    showTableData: {
      handler(val) {
        console.log(val, "showTableData");
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="less" scoped>
.part_meat_container {
  .errorText {
    color: #ed4014;
    font-size: 12px;
    margin-top: 5px;
  }

  .operateBtn {
    display: inline-block;
    color: #2d8cf0;
    cursor: pointer;
  }

  .upload-box {
    width: 60px;
    height: 60px;
    color: #999999;
    transition: color 0.1s ease-in;
    background: #fff;
    border: 1px dashed #dcdee2;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .upload-icon {
      font-size: 40px;
      display: block;
      margin-bottom: 10px;
    }

    &:hover {
      color: #666666;
    }
  }

  .img_container {
    position: relative;

    .img_icon {
      position: absolute;
      display: none;
      top: -10px;
      right: 50%;
      margin-right: -40px;
      cursor: pointer;
    }

    .img_styles {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border: 1px solid #ddd;
    }
  }

  .img_container:hover {
    .img_icon {
      display: block;
    }
  }
}
</style>
<style lang="less">
.part_meat_container {
  .ivu-table-tbody {
    .ivu-table-cell {
      padding: 15px 10px;

      .errorSelect {
        .ivu-select-selection {
          border: 1px solid #ed4014;
        }
      }

      .error_input_number_container {
        display: flex;
        align-items: center;

        .space10 {
          margin: 0 5px;
        }

        .error_border {
          .ivu-input-number {
            border: 1px solid #ed4014;
          }
        }
      }

      .error_input {
        .ivu-input {
          border-color: #ed4014;
        }
      }

      .error_upload {
        .ivu-upload-drag {
          border-color: #ed4014;
        }
      }
    }
  }
}
</style>
