<template>
  <Modal
    v-model="modal"
    :title="$t('key1000697')">
    <p class="desc">{{ $t('key1000918') }}</p>
    <div v-for="(item) in specificationData" class="font-size-14">
       <div>
         <span>{{item.label}}：</span>
         <draggable
           v-model="item.valueList"
           style="display: inline-block"
           :animation="300"
         >
           <Tag v-for="val in item.valueList" class="mr10" style="cursor: move;">{{val.value}}</Tag>
         </draggable>
       </div>
    </div>
    <template #footer>
      <Button @click="modal = false">{{ $t('key1000098') }}</Button>
      <Button type="primary" @click="save">{{ $t('key1000097') }}</Button>
    </template>
  </Modal>
</template>

<script>
import Mixin from '@/components/mixin/common_mixin';
import draggable from 'vuedraggable';
export default {
  name: 'attrSort',
  mixins: [Mixin],
  data () {
    return {
      modal: false,
      isMove: false,
      specificationData: []
    };
  },
  props: {
    specification: {
      type: Array
    }
  },
  components:{
    draggable
  },
  methods: {
    open() {
      this.specificationData = JSON.parse(JSON.stringify(this.specification));
      this.modal = true;
    },
    save () {
      this.$emit('success',this.specificationData);
      this.modal = false;
    }
  }
};
</script>

<style scoped>
.desc {
  font-size: 12px;
  color: #999999;
  margin-bottom: 10px;
}
</style>
